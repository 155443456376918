import { TezosToolkit } from '@taquito/taquito';
import { BeaconWallet } from '@taquito/beacon-wallet';
import axios from 'axios';
const CONFIG = require('./configV2');

export const CheckIfWalletConnected = async (wallet, connectedNetwork) => {
  try {
    const network = {
      type: connectedNetwork,
    };
    const activeAccount = await wallet.client.getActiveAccount();
    if (!activeAccount) {
      await wallet.client.requestPermissions({
        network,
      });
    }
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

const getPriceInUSD = async (
  contractAddress,
  connectedNetwork,
  poolIdentifier
) => {
  try {
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    const dexInstance = await Tezos.contract.at(contractAddress);
    const dexStorage = await dexInstance.storage();
    let tezPool = await dexStorage.storage.tez_pool.toNumber();
    let tokenPool = await dexStorage.storage.token_pool.toNumber();
    let tokenPriceInXtz =
      Math.pow(
        10,
        CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier].TOKEN_DECIMAL
      ) *
      tezPool *
      1000;

    tokenPriceInXtz =
      tokenPriceInXtz /
      (tokenPool -
        Math.pow(
          10,
          CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier].TOKEN_DECIMAL
        ));
    tokenPriceInXtz = tokenPriceInXtz / 997;
    tokenPriceInXtz = tokenPriceInXtz / Math.pow(10, 6);
    let priceFeed = await axios.get(CONFIG.API.url);
    priceFeed = priceFeed.data.market_data.current_price.usd;
    let tokenPriceInUSD = tokenPriceInXtz * priceFeed;
    return {
      success: true,
      tokenPriceInUSD,
    };
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
};

export const getAPROfPool = async (connectedNetwork, poolIdentifier) => {
  try {
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    const plentyContractInstance = await Tezos.contract.at(
      CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier].CONTRACT
    );
    const storage = await plentyContractInstance.storage();
    let totalSupply = await storage.totalSupply;
    totalSupply = (totalSupply.toNumber() / Math.pow(10, 18)).PrecisionMaker(6);
    totalSupply = parseFloat(totalSupply);
    let rewardRate = await storage.rewardRate;
    rewardRate = (rewardRate.toNumber() / Math.pow(10, 18)).PrecisionMaker(5);
    rewardRate = parseFloat(rewardRate);
    let priceOfPlentyInUSD = await getPriceInUSD(
      CONFIG.CONTRACT[connectedNetwork].POOLS['PLENTY'].DEX,
      connectedNetwork,
      'PLENTY'
    );

    let priceOfStakeTokenInUsd = await getPriceInUSD(
      CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier].DEX,
      connectedNetwork,
      poolIdentifier
    );

    let APR =
      (rewardRate * 1051200 * priceOfPlentyInUSD.tokenPriceInUSD) /
      (totalSupply * priceOfStakeTokenInUsd.tokenPriceInUSD);
    APR = APR * 100;

    return {
      success: true,
      APR,
      totalSupply: (
        totalSupply * priceOfStakeTokenInUsd.tokenPriceInUSD
      ).PrecisionMaker(2),
      rewardRate,
      title: poolIdentifier,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

export const poolAPRFetcher = async () => {
  try {
    const connectedNetwork = CONFIG.NETWORK;

    const { POOLS } = CONFIG.CONTRACT[connectedNetwork];
    const POOLS_ARRAY = Object.keys(POOLS).map((elem) =>
      getAPROfPool(connectedNetwork, elem)
    );
    const API_RESPONSE = await Promise.all(POOLS_ARRAY);
    return {
      success: true,
      data: API_RESPONSE,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

// export const getAPROfLPFarm = async (connectedNetwork, farmIdentifier) => {
//     try {
//         const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
//         Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
//         const plentyContractInstance = await Tezos.contract.at(
//             CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].CONTRACT
//         );
//         const dex_contract = await Tezos.contract.at(
//             CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].DEX
//         );
//         const storage = await plentyContractInstance.storage();
//         let totalLiquidity = await storage.totalSupply;
//         totalLiquidity = (
//             totalLiquidity.toNumber() / Math.pow(10, 18)
//         ).PrecisionMaker(18);
//         totalLiquidity = parseFloat(totalLiquidity);

//         const dex_storage = await dex_contract.storage();
//         let tezPool = await dex_storage.storage.tez_pool;
//         let tokenPool = await dex_storage.storage.token_pool;
//         let tokenPriceInXtz =
//             (((Math.pow(10, 18) * tezPool) / (tokenPool - Math.pow(10, 18))) *
//                 1000) /
//             997 /
//             Math.pow(10, 6);
//         let priceFeed = await axios.get(CONFIG.API.url);
//         let plentyTokenPriceinUSD =
//             tokenPriceInXtz * priceFeed.data.market_data.current_price.usd;
//         let totalSupply = await dex_storage.storage.total_supply;
//         totalSupply = (totalSupply.toNumber() / Math.pow(10, 6)).PrecisionMaker(
//             18
//         );
//         totalSupply = parseFloat(totalSupply);

//         let LPTokenPrice =
//             ((tezPool / Math.pow(10, 6)) *
//                 priceFeed.data.market_data.current_price.usd +
//                 (tokenPool / Math.pow(10, 18)) * plentyTokenPriceinUSD) /
//             totalSupply;

//         let rewardRate = await storage.rewardRate;
//         rewardRate =
//             (rewardRate.toNumber() / Math.pow(10, 18)).PrecisionMaker(18) || 0;
//         rewardRate = parseFloat(rewardRate);
//         let APR =
//             (rewardRate * 1051200 * plentyTokenPriceinUSD) /
//             (totalLiquidity * LPTokenPrice);
//         APR = APR * 100;
//         return {
//             success: true,
//             APR,
//             totalSupply: (totalLiquidity * LPTokenPrice).PrecisionMaker(2),
//             rewardRate,
//             farmIdentifier,
//         };
//     } catch (error) {
//         return {
//             success: false,
//             error,
//         };
//     }
// };

export const getAPROfLPFarm = async (connectedNetwork, farmIdentifier) => {
  try {
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    const plentyContractInstance = await Tezos.contract.at(
      CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].CONTRACT
    );
    const dex_contract = await Tezos.contract.at(
      CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].DEX
    );
    const plenty_dex_contract = await Tezos.contract.at(
      CONFIG.CONTRACT[connectedNetwork].FARMS['PLENTY - XTZ'].DEX
    );
    const storage = await plentyContractInstance.storage();
    let totalLiquidity = await storage.totalSupply;
    totalLiquidity = (
      totalLiquidity.toNumber() / Math.pow(10, 18)
    ).PrecisionMaker(18);
    totalLiquidity = parseFloat(totalLiquidity);
    const plenty_dex_storage = await plenty_dex_contract.storage();
    let tezPoolPlt = await plenty_dex_storage.storage.tez_pool;
    let tokenPoolPlt = await plenty_dex_storage.storage.token_pool;
    let plttokenPriceInXtz =
      (((Math.pow(10, 18) * tezPoolPlt) / (tokenPoolPlt - Math.pow(10, 18))) *
        1000) /
      997 /
      Math.pow(10, 6);
    let priceFeed = await axios.get(CONFIG.API.url);
    let plentyTokenPriceinUSD =
      plttokenPriceInXtz * priceFeed.data.market_data.current_price.usd;
    const dex_storage = await dex_contract.storage();
    let tezPool = await dex_storage.storage.tez_pool;
    let tokenPool = await dex_storage.storage.token_pool;
    let tokenPriceInXtz =
      (((Math.pow(10, 18) * tezPool) /
        (tokenPool -
          Math.pow(
            10,
            CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].LP_DECIMAL
          ))) *
        1000) /
      997 /
      Math.pow(10, 6);
    let tokenPriceInUsd =
      priceFeed.data.market_data.current_price.usd * tokenPriceInXtz;
    let totalSupply = await dex_storage.storage.total_supply;
    totalSupply = (totalSupply.toNumber() / Math.pow(10, 6)).PrecisionMaker(18);
    totalSupply = parseFloat(totalSupply);
    let LPTokenPrice =
      ((tezPool / Math.pow(10, 6)) *
        priceFeed.data.market_data.current_price.usd +
        (tokenPool / Math.pow(10, 18)) * tokenPriceInUsd) /
      totalSupply;
    let rewardRate = await storage.rewardRate;
    rewardRate =
      (rewardRate.toNumber() / Math.pow(10, 18)).PrecisionMaker(18) || 0;
    rewardRate = parseFloat(rewardRate);
    let APR =
      (rewardRate * 1051200 * plentyTokenPriceinUSD) /
      (totalLiquidity * LPTokenPrice);
    APR = APR * 100;
    return {
      success: true,
      APR,
      totalSupply: (totalLiquidity * LPTokenPrice).PrecisionMaker(2),
      rewardRate,
      farmIdentifier,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

export const LpFarmAPRFetcher = async () => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const { FARMS } = CONFIG.CONTRACT[connectedNetwork];
    const FARM_ARRAY = Object.keys(FARMS).map((elem) =>
      getAPROfLPFarm(connectedNetwork, elem)
    );
    const API_RESPONSE = await Promise.all(FARM_ARRAY);
    return {
      success: true,
      data: API_RESPONSE,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};
