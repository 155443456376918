/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment, useEffect, useState, useContext } from 'react';
// import circlePercentage from '../../assets/image/icons/circle-percentage.png';
import { getPoolStakes } from '../../apis/pools';
import { stakeLocker } from '../../apis/lockers';
import { stakeFarm, unstakeFarm, getFarmStakes } from '../../apis/farm';
import * as utils from '../../utils/utils';
import * as apis from '../../apis/apis';
import * as pools from '../../apis/pools';
import * as farms from '../../apis/farm';
import * as lockers from '../../apis/lockers';

import PuffLoader from 'react-spinners/PuffLoader';

import loaderDone from '../../assets/image/icons/loading-done.gif';
import loader from '../../assets/image/icons/loader.gif';
import { Menu, Transition } from '@headlessui/react';
import AppContext from '../../store/context';
import { NETWORK, TZKT_NODES } from '../../apis/config';

import ClipLoader from 'react-spinners/ClipLoader';

const CustomModal = (props) => {
  console.log({props});
  const ctx = useContext(AppContext);
  const isActive = props.showActive;
  const precisionMaker = (number) => {
    if (number.toString().split('.').length > 1) {
      const beforeDecimal = number.toString().split('.')[0];
      const afterDecimal = number.toString().split('.')[1].slice(0, 3);
      return beforeDecimal + '.' + afterDecimal;
    } else {
      return number;
    }
  };
  let isPoolComponent = window.location.pathname.includes('pools');
  let isLockerComponent = window.location.pathname.includes('ponds');
  const [userInput, setUserInput] = useState(0);
  const [error, setError] = useState('');
  const [withdrawPercent, setWithdrawPercent] = useState(0);
  const [withdrawlFee, setWithdrawalFee] = useState(0);
  const [stakes, setStakes] = useState([]);
  const [blockBalance, setBlockBalance] = useState('0');
  const [modalSuccess, setModalSuccess] = useState({
    success: false,
    operationId: null,
    message: 'Transaction Successful',
  });
  // const [errorState, setErrorState] = useState(false);
  // // const [errorState, setErrorState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addressCopied, setAddressCopied] = useState(false);

  const modalType = ctx.setModal.type;
  const tezosInstance = JSON.parse(ctx.tezosInstance);
  const [blockStored, setBlockStored] = useState('');
  let addressCopiedText = null;

  const poolStakesFetcher = async (symbol) => {
    const userAddress = utils.getAddressFromLocalStorage();
    const resp = await getPoolStakes(NETWORK, userAddress, symbol, isActive);
    if (resp.success) {
      console.log(resp.stakes);
      setStakes(resp.stakes);
    } else {
      setStakes([]);
    }
  };
  const farmStakesFetcher = async (symbol) => {
    const userAddress = utils.getAddressFromLocalStorage();
    const resp = await getFarmStakes(NETWORK, userAddress, symbol, isActive);
    if (resp.success) {
      setStakes(resp.stakes);
    } else {
      setStakes([]);
    }
  };
  const lockerStakesFetcher = async (symbol) => {
    const userAddress = utils.getAddressFromLocalStorage();
    const resp = await lockers.getLockerStakes(NETWORK, userAddress, symbol);
    if (resp.success) {
      setStakes(resp.stakes);
    } else {
      setStakes([]);
    }
  };

  useEffect(() => {
    if (isPoolComponent) {
      poolStakesFetcher(ctx.setModal.title);
    } else if (isLockerComponent) {
      lockerStakesFetcher(ctx.setModal.title);
    } else {
      farmStakesFetcher(ctx.setModal.title);
    }
  }, [ctx.setModal.title]);

  const confirmStake = async (tezosInstance, network, userInput) => {
    userInput = parseFloat(userInput);
    if (userInput <= availableBal) {
      setError('');
      setLoading(true);
      if (isPoolComponent) {
        const RESP = await pools.stake(network, userInput, ctx.setModal.title);
        if (RESP.success) {
          setModalSuccess({
            ...modalSuccess,
            success: true,
            type: 'Deposit',
            operationId: RESP.operationId,
          });
        }
        setLoading(false);
        let LIST = props.list;
        LIST = await props.PoolBalanceFetcher(LIST);
        await props.PoolAPRFetcher(LIST);
      } else if (isLockerComponent) {
        if (userInput > 0 && userInput % 10 === 0) {
          const RESP = await lockers.stakeLocker(userInput, ctx.setModal.title);
          if (RESP.success) {
            setModalSuccess({
              ...modalSuccess,
              success: true,
              type: 'Deposit',
              operationId: RESP.operationId,
            });
          }
          setLoading(false);
          let LIST = props.lockerList;
          LIST = await props.LockerBalanceFetcher(LIST);
        } else {
          setLoading(false);
          setError('Please enter the amount in multiples of 10');
          return;
        }
      } else {
        const RESP = await farms.stakeFarm(
          network,
          userInput,
          ctx.setModal.title
        );
        if (RESP.success) {
          setModalSuccess({
            ...modalSuccess,
            success: true,
            type: 'Deposit',
            operationId: RESP.operationId,
          });
        }
        await props.FarmBalanceFetcher();
        await props.FarmAprFetcher();
        setLoading(false);
      }
      setLoading(false);
    } else {
      setError(`Stake amount cannot be greater than available balance`);
    }
  };

  const confirmUnstake = async (
    tezosInstance,
    network,
    userInput,
    blockStored,
    isActive
  ) => {
    userInput = parseFloat(userInput);
    if (userInput <= blockBalance) {
      setError('');
      setLoading(true);
      const stakeToSend = blockStored - 1;
      if (isPoolComponent) {
        const RESP = await pools.unstake(
          network,
          userInput,
          stakeToSend,
          ctx.setModal.title,
          isActive
        );
        let LIST = props.list;
        let newLIST = await props.PoolBalanceFetcher(LIST);
        await props.PoolAPRFetcher(newLIST);
        if (RESP.success) {
          setModalSuccess({
            ...modalSuccess,
            success: true,
            type: 'Withdrawal',
            operationId: RESP.operationId,
          });
        }
        setLoading(false);
      } else if (isLockerComponent) {
        if (userInput > 0 && userInput % 10 === 0) {
          const RESP = await lockers.unstakeLocker(
            userInput,
            stakeToSend,
            ctx.setModal.title
          );
          let LIST = props.lockerList;
          let newLIST = await props.PoolBalanceFetcher(LIST);
          await props.PoolAPRFetcher(newLIST);
          if (RESP.success) {
            setModalSuccess({
              ...modalSuccess,
              success: true,
              type: 'Withdrawal',
              operationId: RESP.operationId,
            });
          }
        } else {
          setLoading(false);
          setError('Please enter the amount in multiples of 10');
          return;
        }
      } else {
        const RESP = await farms.unstakeFarm(
          network,
          userInput,
          stakeToSend,
          ctx.setModal.title,
          isActive
        );
        if (RESP.success) {
          setModalSuccess({
            ...modalSuccess,
            success: true,
            type: 'Withdrawal',
            operationId: RESP.operationId,
          });
        }
        setLoading(false);
        await props.FarmBalanceFetcher();
        await props.FarmAprFetcher();
      }
      setLoading(false);
    } else {
      setError(
        `Unstake amount cannot be greater than staked amount in that block`
      );
    }
  };

  const copyAddress = (event) => {
    event.preventDefault();
    const dummy = document.createElement('input');
    document.body.appendChild(dummy);
    dummy.setAttribute('value', ctx.userWalletAddress);
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    setAddressCopied(true);
  };

  const storeBlock = (event) => {
    console.log(ctx.setModal.title)
    event.preventDefault();
    const block = event.target.getAttribute('data-block');
    const userInput = event.target.getAttribute('block-balance');
    const withdrawalFee = ctx.setModal.active
      ? event.target.getAttribute('withdrawl-fee')
      : ctx.setModal.title === 'WRAP' ? event.target.getAttribute('withdrawl-fee') : 0;
    const withDrawPercent = withdrawalFee / userInput;
    const blockBalance = event.target.getAttribute('block-balance');
    setBlockBalance(blockBalance);
    setBlockStored(block);
    setUserInput(userInput);
    setWithdrawalFee(withdrawalFee);
    setWithdrawPercent(withDrawPercent);
  };

  if (addressCopied) {
    addressCopiedText = (
      <p className="address-copied">Wallet address copied to clipboard!</p>
    );
  }

  let modalHeading = '';
  let availableBal = ctx.setModal.balanceToShow
    ? ctx.setModal.balanceToShow
    : 0;
  if (isPoolComponent) {
    const filterDATA = props.list.filter(
      (elem) => elem.title === ctx.setModal.title
    );
    if (filterDATA.length > 0) {
      availableBal = filterDATA[0].userBalance;
    }
  } else {
    const filterDATA = props.farmList.filter(
      (elem) => elem.title === ctx.setModal.title
    );
    if (filterDATA.length > 0) {
      availableBal = filterDATA[0].userBalance;
    }
  }
  let modalContent = (
    <Fragment>
      <div className="available-user-balance-wrapper">
        <p className="available-user-balance-info">Available Balance</p>
        <p className="available-user-balance-info">
          {precisionMaker(availableBal)}{' '}
          {isPoolComponent ? ctx.setModal.title : ctx.setModal.deposit}
        </p>
      </div>

      <div className="available-user-balance-wrapper user-amt-input-wrapper">
        <div>
          <input
            type="text"
            className="plenty-user-amt-input"
            onChange={(e) => setUserInput(e.target.value)}
            placeholder="10"
            value={userInput}
          />
        </div>
        <div className="plenty-user-currency-amt-wrapper">
          <p className="available-user-balance-info">
            {isPoolComponent ? ctx.setModal.title : ctx.setModal.deposit}
          </p>
          <button
            className="max-amt-btn"
            onClick={() => {
              setUserInput(precisionMaker(availableBal));
            }}
          >
            Max
          </button>
        </div>
      </div>
      {/* <p className="available-user-balance-info plenty-fee-info">Deposti Fee : 0.04 USDT</p> */}
      {error ? (
        <div
          className="available-user-balance-wrapper"
          style={{ backgroundColor: '#f4433617' }}
        >
          <p
            className="available-user-balance-info plenty-fee-info"
            style={{ textAlign: 'center' }}
          >
            {error}
          </p>
        </div>
      ) : (
        ''
      )}
      <div className="plenty-modal-footer">
        <div className="plenty-modal-btn-wrapper">
          <button
            className="plenty-modal-btn cancel"
            onClick={props.closeModal}
          >
            Cancel
          </button>
          <button
            className="plenty-modal-btn confirm"
            onClick={() =>
              confirmStake(
                tezosInstance,
                NETWORK,
                userInput,
                ctx.setModal.title
              )
            }
          >
            Confirm
          </button>
        </div>
      </div>
    </Fragment>
  );

  if (modalType === 'logout' && ctx.userWalletAddress) {
    modalHeading = <h3 className="plenty-modal-heading">Your Wallet</h3>;
    modalContent = (
      <Fragment>
        <div className="available-user-balance-wrapper">
          <p className="available-user-balance-info">{ctx.userWalletAddress}</p>
        </div>
        <div className="copy-address-wrap">
          <a
            href={`https://tzkt.io/${ctx.userWalletAddress}`}
            target="_blank"
            rel="noreferrer"
          >
            View on tzkt
            <span className="material-icons material-icons-outlined">
              open_in_new
            </span>
          </a>
          <a href="#" target="_blank" onClick={copyAddress}>
            Copy
            <span className="material-icons material-icons-outlined">
              content_copy
            </span>
          </a>
        </div>
        {addressCopiedText}
        <div className="plenty-modal-footer">
          <div
            className="plenty-modal-btn-wrapper"
            style={{ justifyContent: 'flex-end' }}
          >
            {/* <button
                            className="plenty-modal-btn confirm"
                            onClick={props.closeModal}
                        >
                            Switch Address
                        </button> */}
            <button
              className="plenty-modal-btn cancel"
              onClick={() => {
                // ctx.logout();
                utils.logoutUser();
              }}
            >
              Logout
            </button>
          </div>
        </div>
      </Fragment>
    );
  } else if (
    (modalType === 'stake' && ctx.userWalletAddress) ||
    (modalType === 'harvest' && ctx.userWalletAddress)
  ) {
    modalHeading = (
      <h3 className="plenty-modal-heading">
        Deposit {isPoolComponent ? ctx.setModal.title : ctx.setModal.deposit}{' '}
        Tokens
      </h3>
    );
  } else if (modalType === 'apr') {
    const roiTableDATA = isPoolComponent
      ? props.list.filter((elem) => elem.title === props.cardTitle)
      : props.roiOfFarms.filter((elem) => elem.title === props.cardTitle);
    const roiTable = roiTableDATA.length > 0 ? roiTableDATA[0].roiTable : [];
    modalHeading = <h3 className="plenty-modal-heading">ROI</h3>;
    if (isLockerComponent) {
      modalContent = (
        <Fragment>
          <div className="available-user-balance-wrapper">
            <table className="plenty-modal-table">
              <thead>
                <tr>
                  <th>Timeframe</th>
                  <th>ROI</th>
                  <th>WRAP PER $1000</th>
                </tr>
              </thead>
              <tbody>
                {props.lockerRoi.map((elem, index) => {
                  let days;
                  if (index === 0) {
                    days = 1;
                  }
                  if (index === 1) {
                    days = 7;
                  }
                  if (index === 2) {
                    days = 30;
                  }
                  if (index === 3) {
                    days = 365;
                  }
                  return (
                    <tr key={index}>
                      <td>{days} day</td>
                      <td>{elem.roi.PrecisionMaker(2)} %</td>
                      <td>{elem.rewardTokenPer1000dollar.PrecisionMaker(2)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="roi-table-info">
            Calculated based on current rates. Rates are estimates provided for
            your convenience only, and by no means represent guaranteed returns.
          </div>
        </Fragment>
      );
    } else {
      modalContent = (
        <Fragment>
          <div className="available-user-balance-wrapper">
            <table className="plenty-modal-table">
              <thead>
                <tr>
                  <th>Timeframe</th>
                  <th>ROI</th>
                  <th>PLENTY PER $1000</th>
                </tr>
              </thead>
              <tbody>
                {roiTable.map((elem, index) => {
                  let days;
                  if (index === 0) {
                    days = 1;
                  }
                  if (index === 1) {
                    days = 7;
                  }
                  if (index === 2) {
                    days = 30;
                  }
                  if (index === 3) {
                    days = 365;
                  }
                  return (
                    <tr key={index}>
                      <td>{days} day</td>
                      <td>{elem.roi.PrecisionMaker(2)} %</td>
                      <td>{elem.PlentyPer1000dollar.PrecisionMaker(2)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="roi-table-info">
            Calculated based on current rates. Rates are estimates provided for
            your convenience only, and by no means represent guaranteed returns.
          </div>
        </Fragment>
      );
    }
  } else if (modalType === 'withdrawalFee-farm') {
    modalHeading = (
      <h3 className="plenty-modal-heading">
        Withdrawal fee reduction breakdown
      </h3>
    );
    modalContent = (
      <Fragment>
        <div className="available-user-balance-wrapper">
          <table className="plenty-modal-table">
            <thead>
              <tr>
                <th>Stake duration</th>
                <th>Block count</th>
                <th>Withdrawal fee</th>
              </tr>
            </thead>
            {ctx.setModal.title === 'KALAM - XTZ' ? (
              <tbody>
                <tr>
                  <td>{'> 0 days'}</td>
                  <td>{'> 0 blocks'}</td>
                  <td>0%</td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td>{'< 9 days'}</td>
                  <td>{'< 12288 blocks'}</td>
                  <td>4%</td>
                </tr>
                <tr>
                  <td>{'> 9 days'}</td>
                  <td>{'> 12288 blocks'}</td>
                  <td>0%</td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
        <div className="roi-table-info">
          The withdrawal fee is calculated from the block number in which you
          made your deposit. Each deposit can be withdrawn separately.
        </div>
      </Fragment>
    );
  } else if (modalType === 'withdrawalFee-lockers') {
    modalHeading = (
      <h3 className="plenty-modal-heading">
        Withdrawal fee reduction breakdown
      </h3>
    );
    modalContent = (
      <Fragment>
        <div className="available-user-balance-wrapper">
          <table className="plenty-modal-table">
            <thead>
              <tr>
                <th>Stake duration</th>
                <th>Block count</th>
                <th>Withdrawal fee</th>
              </tr>
            </thead>
            <tbody>
              {ctx.setModal.title === 'KALAM' ? (
                <tr>
                  <td>{'> 0 days'}</td>
                  <td>{'> 0 blocks'}</td>
                  <td>0%</td>
                </tr>
              ) : (
                <>
                  <tr>
                    <td>{'< 9 days'}</td>
                    <td>{'< 12288 blocks'}</td>
                    <td>4%</td>
                  </tr>
                  <tr>
                    <td>{'> 9 days'}</td>
                    <td>{'> 12288 blocks'}</td>
                    <td>0%</td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="roi-table-info">
          The withdrawal fee is calculated from the block number in which you
          made your deposit. Each deposit can be withdrawn separately.
        </div>
      </Fragment>
    );
  } else if (modalType === 'withdrawalFee-pools') {
    modalHeading = (
      <h3 className="plenty-modal-heading">
        Withdrawal fee reduction breakdown
      </h3>
    );
    modalContent = (
      <Fragment>
        <div className="available-user-balance-wrapper">
          <table className="plenty-modal-table">
            <thead>
              <tr>
                <th>Stake duration</th>
                <th>Block count</th>
                <th>Withdrawal fee</th>
              </tr>
            </thead>
            {isPoolComponent ? (
              <tbody>
                <tr>
                  <td>{'< 3 days'}</td>
                  <td>{'< 4096 blocks'}</td>
                  <td>25%</td>
                </tr>
                <tr>
                  <td>{'< 6 days'}</td>
                  <td>{'< 8192 blocks'}</td>
                  <td>12.5%</td>
                </tr>
                <tr>
                  <td>{'< 9 days'}</td>
                  <td>{'< 12288 blocks'}</td>
                  <td>10%</td>
                </tr>
                <tr>
                  <td>{'> 9 days'}</td>
                  <td>{'> 12288 blocks'}</td>
                  <td>{ctx.setModal.title === 'WRAP' ? '2%' : '4%'}</td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td>Before 4096 blocks</td>
                  <td>4%</td>
                </tr>
                <tr>
                  <td>After 4096 blocks</td>
                  <td>0%</td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
        <div className="roi-table-info">
          The withdrawal fee is calculated from the block number in which you
          made your deposit. Each deposit can be withdrawn separately.
        </div>
      </Fragment>
    );
  } else if (modalType == 'withdrawal-stake' && ctx.userWalletAddress) {
    modalHeading = (
      <h3 className="plenty-modal-heading">
        Withdraw {isLockerComponent ? ctx.setModal.deposit : ctx.setModal.title}{' '}
        tokens
      </h3>
    );
    modalContent = (
      <Fragment>
        <div className="available-user-balance-wrapper">
          <p className="available-user-balance-info">Total Staked Balance</p>
          <p className="available-user-balance-info">
            {ctx.setModal.balanceToShow ? ctx.setModal.balanceToShow : 0}{' '}
            {isLockerComponent ? ctx.setModal.deposit : ctx.setModal.title}
          </p>
        </div>

        <div className="available-user-balance-wrapper user-amt-input-wrapper">
          <Menu
            as="div"
            className="relative inline-block text-left header-links my-auto full-width"
          >
            {({ open }) => (
              <Fragment>
                <div>
                  {stakes.length === 0 ? (
                    <Menu.Button className="header-links my-auto available-user-balance-info full-width">
                      Please wait...
                      <span
                        className="material-icons material-icons-outlined"
                        style={{
                          verticalAlign: 'middle',
                        }}
                      >
                        expand_more
                      </span>
                    </Menu.Button>
                  ) : (
                    <Menu.Button className="header-links my-auto available-user-balance-info full-width">
                      {blockStored ? `Stake ${blockStored}` : 'Select Stake'}{' '}
                      <span
                        className="material-icons material-icons-outlined"
                        style={{
                          verticalAlign: 'middle',
                        }}
                      >
                        expand_more
                      </span>
                    </Menu.Button>
                  )}
                </div>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none full-width"
                  >
                    <div className="py-1">
                      {stakes.map((block, index) => {
                        return (
                          <Menu.Item key={index}>
                            <div className="header-links my-auto dropdown-item-link available-user-balance-info">
                              <a
                                style={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                                href="#"
                                data-block={block.number}
                                withdrawl-fee={block.withdrawalFee}
                                block-balance={block.balance}
                                onClick={storeBlock}
                              >
                                {`Stake ${block.number}`}
                                <span
                                  data-block={block.number}
                                  withdrawl-fee={block.withdrawalFee}
                                  block-balance={block.balance}
                                  onClick={storeBlock}
                                >{`${block.balance} ${
                                  isLockerComponent
                                    ? ctx.setModal.deposit
                                    : ctx.setModal.title
                                }`}</span>
                              </a>
                            </div>
                          </Menu.Item>
                        );
                      })}
                    </div>
                  </Menu.Items>
                </Transition>
              </Fragment>
            )}
          </Menu>
        </div>

        <div className="available-user-balance-wrapper user-amt-input-wrapper">
          <div>
            <input
              type="text"
              className="plenty-user-amt-input"
              onChange={(e) => {
                const withdrawalFee = parseFloat(
                  withdrawPercent * e.target.value
                ).toString();
                setWithdrawalFee(withdrawalFee);
                setUserInput(e.target.value);
              }}
              value={userInput}
              placeholder="Enter your amount"
            />
          </div>
          <div className="plenty-user-currency-amt-wrapper">
            <p className="available-user-balance-info">
              {isLockerComponent ? ctx.setModal.deposit : ctx.setModal.title}
            </p>
            {/* <button className="max-amt-btn">Max</button> */}
          </div>
        </div>
        <p className="available-user-balance-info plenty-fee-info">
          Withdrawal fee : {withdrawlFee || 0}{' '}
          {isLockerComponent ? ctx.setModal.deposit : ctx.setModal.title}
        </p>

        {error ? (
          <div
            className="available-user-balance-wrapper"
            style={{ backgroundColor: '#f4433617' }}
          >
            <p
              className="available-user-balance-info plenty-fee-info"
              style={{ textAlign: 'center' }}
            >
              {error}
            </p>
          </div>
        ) : (
          ''
        )}
        <div className="plenty-modal-footer">
          <div className="plenty-modal-btn-wrapper">
            <button
              className="plenty-modal-btn cancel"
              onClick={props.closeModal}
            >
              Cancel
            </button>
            <button
              className="plenty-modal-btn confirm"
              disabled={stakes.length === 0}
              onClick={() =>
                confirmUnstake(
                  tezosInstance,
                  NETWORK,
                  userInput,
                  blockStored,
                  isActive
                )
              }
            >
              Confirm
            </button>
          </div>
        </div>
      </Fragment>
    );
  } else {
    modalHeading = (
      <h3 className="plenty-modal-heading">Wallet not connected</h3>
    );
    modalContent = (
      <Fragment>
        <div className="available-user-balance-wrapper">
          <p className="available-user-balance-info">
            Connect your wallet to continue
          </p>
        </div>
        <div className="plenty-modal-btn-wrapper">
          <button
            className="plenty-modal-btn confirm"
            onClick={utils.initWallet}
          >
            Connect Wallet
          </button>
        </div>
      </Fragment>
    );
  }

  if (!loading) {
    document.getElementById('body').classList.remove('no-scroll');
  }

  if (loading) {
    modalHeading = null;
    const color = '#5C0FAE';
    document.getElementById('body').classList.add('no-scroll');

    modalContent = (
      <Fragment>
        <div
          className="plenty-loading-icon-wrapper"
          style={{
            width: '120px',
            margin: '0 auto',
            height: '100px',
            padding: '24px',
            marginBottom: '12px',
          }}
        >
          <PuffLoader color={color} size={70} />
        </div>

        <div
          style={{
            width: '200px',
            margin: '0 auto',
            textAlign: 'center',
          }}
        >
          <p className="available-user-balance-info">
            Transaction is being added to the blockchain
          </p>
        </div>
      </Fragment>
    );
  }

  if (modalSuccess.success) {
    modalHeading = null;
    document.getElementById('body').classList.add('no-scroll');
    modalContent = (
      <Fragment>
        <div
          className="plenty-loading-icon-wrapper"
          style={{ width: '120px', margin: '0 auto' }}
        >
          <img src={loaderDone} className="plenty-loading-icon" />
        </div>
        <p
          className="available-user-balance-info"
          style={{ textAlign: 'center' }}
        >
          {modalSuccess.type} has been successful
        </p>
        <div
          style={{
            maxWidth: '600px',
            margin: '0 auto',
            textAlign: 'center',
          }}
        >
          <a
            href={`${TZKT_NODES[NETWORK].replace('api.', '')}${
              modalSuccess.operationId
            }`}
            target="_blank"
            className="available-user-balance-info transaction-successful-text"
            rel="noreferrer"
          >
            {modalSuccess.operationId}
            <span
              className="material-icons material-icons-outlined"
              style={{
                fontSize: '12px',
                verticalAlign: 'middle',
                display: 'inline-block',
                marginLeft: '2px',
              }}
            >
              open_in_new
            </span>
          </a>
        </div>
      </Fragment>
    );
  }

  return (
    <div className="plenty-modal-wrapper">
      <div className="plenty-modal-content-wrapper">
        <div className="plenty-modal-header">
          {modalHeading}
          <button
            className="plenty-close-modal"
            disabled={loading}
            onClick={props.closeModal}
          >
            <span className="material-icons">close</span>
          </button>
        </div>

        <div className="plenty-modal-content">{modalContent}</div>
      </div>
    </div>
  );
};

export default CustomModal;
