import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { TezosToolkit } from '@taquito/taquito';
import { BeaconWallet } from '@taquito/beacon-wallet';
import CircularJSON from 'circular-json';
const CONFIG = require('../apis/configV2');

export const errorToast = (message) => {
  /** error alert is fired from here */
  toast.error(message);
};

export const infoToast = (message) => {
  /** info alert is fired from here */
  toast.info(message);
};

export const successToast = (message) => {
  /** success alert is fired from here*/
  toast.success(message);
};

export const getAddressFromLocalStorage = () => {
  const address = localStorage.getItem('address');
  if (address) {
    return address;
  }
};

export const setAddressInLocalStorage = (address) => {
  if (address) {
    localStorage.setItem('address', address);
    window.location.reload();
  }
};

export const logoutUser = async () => {
  const options = {
    name: 'Plenty Farm',
  };
  const wallet = new BeaconWallet(options);
  await wallet.disconnect();
  const address = localStorage.getItem('address');
  if (address) {
    localStorage.removeItem('address');
    window.location.reload();
  }
};

export const openModal = (info) => {
  if (info === 'show') {
    return true;
  } else {
    return false;
  }
};

export const initWallet = async () => {
  try {
    const network = {
      type: CONFIG.WALLET_NETWORK,
    };
    const options = {
      name: 'Plenty Farm',
    };
    const wallet = new BeaconWallet(options);
    let account = await wallet.client.getActiveAccount();
    if (!account) {
      await wallet.client.requestPermissions({
        network,
      });
      account = await wallet.client.getActiveAccount();
    }
    localStorage.setItem('wallet', JSON.stringify(wallet));
    localStorage.setItem('address', account.address);
    return account.address;
  } catch (e) {}
};
