/* eslint-disable react/jsx-no-target-blank */
import React, { useState, Fragment, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';

import truncateMiddle from 'truncate-middle';
import { GoThreeBars } from 'react-icons/go';
import { Menu, Transition } from '@headlessui/react';
import PuffLoader from 'react-spinners/PuffLoader';
import logo from '../../assets/image/logo-plenty.png';

import AppContext from '../../store/context';

function Header(props) {
  const [UserAddress, setUserAddress] = useState(
    props.utils.getAddressFromLocalStorage()
  );
  const fetchWallet = async () => {
    let userAddress = props.utils.getAddressFromLocalStorage();
    if (!userAddress) {
      // userAddress = await props.utils.initWallet();
      // ctx.userWalletAddress = userAddress;
      await ctx.connectWallet();
    }
    setUserAddress(userAddress);
    if (window.location.pathname === '/') {
      props.HomePageDataFetcher();
    } else if (window.location.pathname === '/pools') {
      props.PoolsDataFetcher();
    }
  };

  const ctx = useContext(AppContext);

  let connectButton = (
    <button
      className="header-connect-button"
      onClick={() => {
        fetchWallet();
        // window.location.reload();
      }}
    >
      Connect
    </button>
  );

  if (UserAddress) {
    connectButton = (
      <button
        className="header-connect-button btn-loggedin"
        onClick={() => ctx.showCustomModal('show', 'logout', { ...props })}
      >
        {truncateMiddle(UserAddress, 3, 3, '...')}
      </button>
    );
  }

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div className="page-container header-top-container">
      <div className="flex justify-between header-container">
        <div className="my-auto">
          <a
            href="/"
            className="site-logo-link"
            onClick={() => {
              props.HomePageDataFetcher();
            }}
          >
            <img src={logo} alt="logo" className="site-logo-img" />
          </a>
        </div>
        <div className=" text-center hidden-in-md lg:flex main-menu-container">
          <div className="header-links my-auto">
            <a
              href="https://quipuswap.com/swap?from=tez&to=KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b"
              className="plenty-header-link buy-plenty-btn"
              target="_blank"
            >
              <span>Buy PLENTY</span>
            </a>
          </div>
        </div>
        <div className=" text-center hidden lg:flex main-menu-container">
          <div className="header-links my-auto">
            <a
              href="https://quipuswap.com/swap?from=tez&to=KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b"
              className="plenty-header-link buy-plenty-btn"
              target="_blank"
            >
              <span>Buy PLENTY</span>
            </a>
          </div>
          <div className="header-links my-auto">
            <Link
              to="/farms"
              className="plenty-header-link"
              onClick={() => props.FarmDataFetcher()}
            >
              {' '}
              Farms{' '}
            </Link>
          </div>
          <div className="header-links my-auto">
            <Link
              to="/pools"
              className="plenty-header-link"
              onClick={() => {
                props.PoolsDataFetcher();
              }}
            >
              {' '}
              Pools{' '}
            </Link>
          </div>
          <div className="header-links my-auto">
            <a
              href="/ponds"
              className="plenty-header-link"
              onClick={() => props.LockerDataFetcher()}
            >
              {' '}
              Ponds
            </a>
          </div>

          <Menu
            as="div"
            className="relative inline-block text-left header-links my-auto plenty-header-link"
          >
            {() => (
              <Fragment>
                <div>
                  <Menu.Button
                    className="header-links my-auto plenty-header-link"
                    onMouseEnter={toggle}
                    onMouseLeave={toggle}
                  >
                    More{' '}
                    <span
                      className="material-icons-outlined"
                      style={{ verticalAlign: 'middle' }}
                    >
                      expand_more
                    </span>
                  </Menu.Button>
                </div>

                <Transition
                  show={dropdownOpen}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                  onMouseLeave={toggle}
                  onMouseEnter={toggle}
                >
                  <Menu.Items
                    static
                    className="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div className="py-1">
                      <Menu.Item>
                        <div className="header-links my-auto dropdown-item-link">
                          <a
                            href="https://www.notion.so/Plenty-Docs-082b61c1859e4c4f86d01c3daa0db9ed"
                            target="_blank"
                            className="plenty-header-link"
                          >
                            {' '}
                            Docs{' '}
                          </a>
                        </div>
                      </Menu.Item>
                      <Menu.Item>
                        <div className="header-links my-auto dropdown-item-link">
                          <a
                            href="https://medium.com/plenty-defi"
                            target="_blank"
                            className="plenty-header-link"
                          >
                            {' '}
                            Blog{' '}
                          </a>
                        </div>
                      </Menu.Item>
                      <Menu.Item>
                        <div className="header-links my-auto dropdown-item-link">
                          <a
                            href="https://github.com/Plenty-DeFi"
                            target="_blank"
                            className="plenty-header-link"
                          >
                            {' '}
                            Github{' '}
                          </a>
                        </div>
                      </Menu.Item>
                      <Menu.Item>
                        <div className="header-links my-auto dropdown-item-link">
                          <a
                            href="https://www.notion.so/Roadmap-3d24ab4912c04d48859c332059c665ca"
                            target="_blank"
                            className="plenty-header-link"
                          >
                            {' '}
                            Roadmap{' '}
                          </a>
                        </div>
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Fragment>
            )}
          </Menu>
          <div className="header-links">{connectButton}</div>
        </div>
        <div className=" text-center flex lg:hidden ">
          <div className="dropdown">
            <button className="dropbtn">
              <GoThreeBars />
            </button>
            <div className="dropdown-content">
              <a href="/farms"> Farms </a>
              <a href="/pools"> Pools </a>
              <a href="/ponds"> Ponds </a>
              <a href="https://www.notion.so/Plenty-Docs-082b61c1859e4c4f86d01c3daa0db9ed">
                {' '}
                Docs{' '}
              </a>
              <a href="https://medium.com/plenty-defi"> Medium </a>
              <a href="https://www.notion.so/Roadmap-3d24ab4912c04d48859c332059c665ca">
                {' '}
                Roadmap{' '}
              </a>
              <div className="header-links">{connectButton}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
