module.exports = {
    RPC_NODES: {
        testnet: 'https://tezos-prod.cryptonomic-infra.tech/',
        mainnet: 'https://tezos-prod.cryptonomic-infra.tech/',
    },
    CONTRACT: {
        testnet: {
            PLENTY_FARM_CONTRACT: 'KT1F2m9SyA1pYfRise32iUw9Hvygr1u3W7gz',
            PLENTY_TOKEN_CONTRACT: 'KT1JCq5sWnE8EivqhY7RuNSHgC5injKYLUCT',
            PLENTY_DEX_CONTRACT: 'KT1SVjwMhHjokd6iJY6XZAk6p1T1hfuNYHkr',
        },
        mainnet: {
            PLENTY_FARM_CONTRACT: '',
            PLENTY_TOKEN_CONTRACT: '',
        },
    },
    TZKT_NODES: {
        mainnet: 'https://api.tzkt.io/',
        testnet: 'https://api.florencenet.tzkt.io/',
    },
    POOLS: {
        PLENTY: {
            TOKEN: 'KT1JCzzFikCjTq6SbSPDRt56swUSG9vCCRRT',
            CONTRACT: 'KT19eGoVGhXHkTSQT9Dfrm4z4QHUa4RttabH',
            DEX: 'KT1JCzzFikCjTq6SbSPDRt56swUSG9vCCRRT',
            TYPE: 'FA1.2',
            TOKEN_DECIMAL: 18,
        },
        wBUSD: {
            TOKEN: 'KT1E9sVkhpWTArYktCT2y6HYXMQvttzrpG9t',
            CONTRACT: 'KT1EMet7icBnyfD6WDPKAizF4sFNEmKPJcfq',
            TYPE: 'FA1.2',
        },
    },
    NETWORK: 'mainnet',
    WALLET_NETWORK: 'mainnet',
    PLENTY_CRED: 'plenty@1234',
    ADMIN_ADDRESS: 'KT1MKwzJqCeUSLCbL6GaEKb77mjSufPFGjoN',
    ENCRYPTION_STRING: 'b2b70775aa6e8545ed6d9751ab92273fbc6b1fa9',
};
