/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-has-content */
import Layout from '../../components/layout/layout';
import React, { useState, useEffect, useContext } from 'react';

import * as utils from '../../utils/utils';
import { getPlentyBalanceOfUser } from '../../apis/apis';
import { getSupplyAndBalance } from '../../apis/apis';
import { getPlentyPrice } from '../../apis/apis';
import NumericLabel from 'react-pretty-numbers';
import AppContext from '../../store/context';
import PuffLoader from 'react-spinners/PuffLoader';

function Home(props) {
  const ctx = useContext(AppContext);
  /** staking values  */
  const [staking, setStaking] = useState({
    harvest: '$0.00',
    wallet: '$0.00',
  });

  /** unlock wallet function */
  function unlockWallet() {
    //
  }

  /** states value  */
  const [yourstate, setYourstate] = useState({
    plenty1: 0.0,
    plenty2: 0.0,
  });

  /** plenty states value  */
  const [stats, setStats] = useState({
    price: 85.37,
    market_cap: 25643452,
    total_minted: 342441,
    total_burned: 39964,
    circulating_supply: 302507,
    new_plenty: 1,
  });

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    document.getElementsByClassName('twitter-embed')[0].appendChild(script);
  }, []);

  /** total value locked  value */

  const [locked, setLocked] = useState('243,206,747.22');
  var option = {
    justification: 'C',
    locales: 'en-AU',
    currency: true,
    currencyIndicator: 'AUD',
    percentage: false,
    precision: 0,
    wholenumber: null,
    commafy: true,
    shortFormat: false,
    shortFormatMinValue: 100000,
    shortFormatPrecision: 1,
    title: true,
    cssClass: ['plenty-home-small-card-amount'],
  };
  var option2 = {
    justification: 'L',
    locales: 'en-AU',
    currency: true,
    currencyIndicator: 'AUD',
    percentage: false,
    precision: 0,
    wholenumber: null,
    commafy: true,
    shortFormat: false,
    shortFormatMinValue: 100000,
    shortFormatPrecision: 1,
    title: false,
  };
  var optionMinted = {
    justification: 'C',
    locales: 'en-AU',
    currency: false,
    currencyIndicator: 'AUD',
    percentage: false,
    precision: 0,
    wholenumber: null,
    commafy: true,
    shortFormat: false,
    shortFormatMinValue: 100000,
    shortFormatPrecision: 1,
    title: true,
    cssClass: ['plenty-home-small-card-amount'],
  };
  let loadingInfo = null;

  if (props.loading) {
    const color = '#5C0FAE';
    document.getElementById('body').classList.add('no-scroll');
    loadingInfo = (
      <div className="loading-pools" style={{ zIndex: '999' }}>
        <div className="loader-icon">
          <PuffLoader color={color} size={150} />
        </div>
      </div>
    );
  } else {
    loadingInfo = null;
    document.getElementById('body').classList.remove('no-scroll');
  }

  return (
    <Layout>
      {loadingInfo}
      <section className="home-section">
        <div className="flex home-cards-wrapper">
          <div className="plenty-home-sidebar">
            <div className="general-card plenty-home-card">
              <h3 className="plenty-card-heading">Total Value Locked (TVL)</h3>
              <h2
                className="total-tvl"
                style={{
                  position: 'relative',
                  cursor: 'pointer',
                }}
              >
                <NumericLabel params={option2}>
                  {props.tvl.PrecisionMaker(0)}
                </NumericLabel>
              </h2>
              <h4 className="plenty-card-sub-heading">
                Across all Farms and Pools
              </h4>
            </div>

            <div className="general-card plenty-home-card staking-farming-locked">
              <h3 className="plenty-card-heading">
                Your Stats
                <span style={{ float: 'right' }} className="pools">
                  <img src={props.logoIcon} className="token-img" />
                </span>
              </h3>

              <div>
                <p className="available-user-balance-info mb-10">
                  PLENTY to Harvest
                </p>
                <div className="available-user-balance-wrapper user-amt-input-wrapper plenty-home-balance">
                  <div>
                    {props.plentyToHarvest
                      ? props.plentyToHarvest.PrecisionMaker(3)
                      : '0.0'}
                  </div>
                  {!ctx.userWalletAddress && (
                    <div className="plenty-user-currency-amt-wrapper">
                      <span className="material-icons material-icons-outlined">
                        lock
                      </span>
                      Locked
                    </div>
                  )}
                </div>
              </div>

              <div>
                <p className="available-user-balance-info mb-10">
                  PLENTY in Wallet
                </p>
                <div className="available-user-balance-wrapper user-amt-input-wrapper plenty-home-balance">
                  <div>
                    {props.homePageTokenBalance
                      ? props.homePageTokenBalance.PrecisionMaker(3)
                      : '0.0'}
                  </div>
                  {!ctx.userWalletAddress && (
                    <div className="plenty-user-currency-amt-wrapper">
                      <span className="material-icons material-icons-outlined">
                        lock
                      </span>
                      Locked
                    </div>
                  )}
                </div>
              </div>

              <div>
                <p className="available-user-balance-info mb-10">
                  Value Locked in all Farms &amp; Pools
                </p>
                <div className="available-user-balance-wrapper user-amt-input-wrapper plenty-home-balance">
                  <div>
                    ~$
                    {props.tvlUser}
                  </div>
                  {!ctx.userWalletAddress && (
                    <div className="plenty-user-currency-amt-wrapper">
                      <span className="material-icons material-icons-outlined">
                        lock
                      </span>
                      Locked
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="plenty-home-stats-wrapper">
            <div className="general-card plenty-home-card">
              <h3 className="plenty-card-heading">Plenty Stats</h3>
              <div className="flex plenty-home-small-cards-wrapper">
                <div className="plenty-home-small-card">
                  <div className="plenty-home-small-card-content text-center">
                    <p className="material-icons material-icons-outlined">
                      attach_money
                    </p>
                    <p className="plenty-home-small-card-amount">
                      $
                      {props.homePageTokenPrice
                        ? props.homePageTokenPrice.PrecisionMaker(3)
                        : 0}
                    </p>
                    <p className="plenty-home-small-card-info">Price</p>
                  </div>
                </div>

                <div className="plenty-home-small-card">
                  <div className="plenty-home-small-card-content text-center">
                    <p className="material-icons material-icons-outlined">
                      account_balance
                    </p>
                    <NumericLabel params={option}>
                      {(
                        props.homePageTokenPrice *
                        props.homePageData.circulatingSupply
                      ).PrecisionMaker(0)}
                    </NumericLabel>
                    <p className="plenty-home-small-card-info">Market Cap</p>
                  </div>
                </div>

                <div className="plenty-home-small-card">
                  <div className="plenty-home-small-card-content text-center">
                    <p className="material-icons material-icons-outlined">
                      swap_calls
                    </p>
                    <NumericLabel params={optionMinted}>
                      {props.homePageData.circulatingSupply}
                    </NumericLabel>
                    <p className="plenty-home-small-card-info">
                      Circulating Supply
                    </p>
                  </div>
                </div>

                <div className="plenty-home-small-card">
                  <div className="plenty-home-small-card-content text-center mb-0">
                    <p className="material-icons material-icons-outlined">
                      more_time
                    </p>
                    <p className="plenty-home-small-card-amount">
                      {props.tokensPerBlock}
                    </p>
                    <p className="plenty-home-small-card-info">
                      New PLENTY/Block
                    </p>
                  </div>
                </div>

                <div className="plenty-home-small-card">
                  <div className="plenty-home-small-card-content text-center mb-0">
                    <p className="material-icons material-icons-outlined">
                      agriculture
                    </p>
                    <NumericLabel params={optionMinted}>
                      {(props.totalSupply + props.totalBurned).PrecisionMaker(
                        0
                      )}
                    </NumericLabel>
                    <p className="plenty-home-small-card-info">Total Minted</p>
                  </div>
                </div>

                <div className="plenty-home-small-card">
                  <div className="plenty-home-small-card-content text-center mb-0">
                    <p className="material-icons material-icons-outlined">
                      local_fire_department
                    </p>
                    <p className="plenty-home-small-card-amount">
                      {props.totalBurned}
                    </p>
                    <p className="plenty-home-small-card-info">Total Burned</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="general-card plenty-home-card">
              <h3 className="plenty-card-heading"> Twitter Feed </h3>
              <div className="twitter-embed">
                <a
                  className="twitter-timeline"
                  data-theme="light"
                  data-chrome="noheader nofooter noborders"
                  href="https://twitter.com/plentydefi"
                ></a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Home;
