import React, { useState, useEffect } from 'react';

import Routes from './routes/routeWrapper';
import * as utils from './utils/utils';

import AppContext from './store/context';

import './scss/App.scss';

function App() {
    const [instance, setInstance] = useState({
        tezosInstance: null,
        walletInstance: null,
    });
    const [setModal, setShowModal] = useState({
        show: false,
        type: '',
    });
    const walletAddress = localStorage.getItem('address');
    const [userWalletAddress, setUserWalletAddress] = useState(
        walletAddress || null
    );

    useEffect(() => {
        const tezosInstance = localStorage.getItem('instance');
        const walletInstance = localStorage.getItem('wallet');

        setUserWalletAddress(utils.getAddressFromLocalStorage());

        if (tezosInstance && walletInstance) {
            setInstance({
                tezosInstance: tezosInstance,
                walletInstance: walletInstance,
            });
        }
    }, []);

    const showCustomModal = (
        info,
        modalType,
        arr,
        balance,
        title,
        active,
        deposit
    ) => {
        const showModalInfo = utils.openModal(info, modalType);
        document.getElementById('body').classList.remove('no-scroll');
        if (info) {
            setShowModal({
                show: showModalInfo,
                type: modalType,
                data: arr,
                balanceToShow: balance,
                title: title,
                active: active,
                deposit: deposit,
            });
        }
    };

    const connectWallet = async () => {
        const address = await utils.initWallet();
        setUserWalletAddress(address);
        window.location.reload();
    };

    const logoutUser = () => {
        utils.logoutUser();
    };

    const context = {
        tezosInstance: instance.tezosInstance,
        walletInstance: instance.walletInstance,
        userWalletAddress: userWalletAddress,
        setModal: setModal,
        showCustomModal: showCustomModal,
        connectWallet: connectWallet,
        logout: logoutUser,
    };

    return (
        <div className="App">
            <AppContext.Provider value={context}>
                <Routes />
            </AppContext.Provider>
        </div>
    );
}

export default App;
