import discord from '../../assets/image/social/discord.png';
import linkedin from '../../assets/image/social/linkedin.png';
import twitter from '../../assets/image/social/twitter.png';

const Footer = () => {
    const socialIcons = [
        {
            name: 'Discord',
            url: 'https://discord.gg/9wZ4CuvkuJ',
        },
        {
            name: 'Telegram',
            url: 'https://t.me/PlentyDeFi',
        },
        {
            name: 'Twitter',
            url: 'https://twitter.com/PlentyDeFi',
        },
    ];
    return (
        <footer className="site-footer">
            <div className="page-container footer-container">
                <ul className="social-icons-wrapper">
                    {socialIcons.map((item, index) => {
                        return (
                            <li className="social-icon-item" key={index}>
                                <a
                                    href={item.url}
                                    target="_blank"
                                    className="social-icon-link"
                                    rel="noreferrer"
                                >
                                    {item.name}
                                </a>
                            </li>
                        );
                    })}
                </ul>
                <p style={{ marginTop: '10px' }}>
                    Made with{' '}
                    <span className="material-icons material-icons-outlined material-icon-heart">
                        {' '}
                        favorite
                    </span>{' '}
                    by{' '}
                    <a
                        href="https://tezsure.com/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Tezsure
                    </a>{' '}
                    &amp;{' '}
                    <a
                        href="https://drapergorenholm.com/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        DGH
                    </a>
                </p>
            </div>
        </footer>
    );
};

export default Footer;
