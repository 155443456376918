import { TezosToolkit } from '@taquito/taquito';
import axios from 'axios';
import { BeaconWallet } from '@taquito/beacon-wallet';
const CONFIG = require('./configV2');

export const CheckIfWalletConnected = async (wallet, somenet) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const network = {
      type: connectedNetwork,
    };
    const activeAccount = await wallet.client.getActiveAccount();
    if (!activeAccount) {
      await wallet.client.requestPermissions({
        network,
      });
    }
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

export const fetchFarmBalance = async (
  connectNetwork,
  addressOfUser,
  tokenContractAddress,
  icon
) => {
  try {
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectNetwork]);
    Tezos.setProvider(CONFIG.RPC_NODES[connectNetwork]);
    const contract = await Tezos.contract.at(tokenContractAddress);
    const storage = await contract.storage();
    const userDetails = await storage.balances.get(addressOfUser);
    let userBalance = userDetails.balance;
    userBalance = (userBalance.toNumber() / Math.pow(10, 18)).PrecisionMaker(5);
    userBalance = parseFloat(userBalance);
    return {
      success: true,
      balance: userBalance,
      symbol: icon,
    };
  } catch (error) {
    return {
      success: false,
      balance: 0,
      symbol: icon,
      error,
    };
  }
};

export const fetchWalletBalance = async (
  connectNetwork,
  addressOfUser,
  tokenContractAddress,
  icon
) => {
  try {
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectNetwork]);
    Tezos.setProvider(CONFIG.RPC_NODES[connectNetwork]);
    const contract = await Tezos.contract.at(tokenContractAddress);
    const storage = await contract.storage();
    const userDetails = await storage.storage.ledger.get(addressOfUser);
    let userBalance = userDetails.balance;
    userBalance = userBalance.toNumber() / Math.pow(10, 6);
    userBalance = parseFloat(userBalance);
    return {
      success: true,
      balance: userBalance,
      symbol: icon,
    };
  } catch (error) {
    return {
      success: false,
      balance: 0,
      symbol: icon,
      error,
    };
  }
};

// export const BalancesFarmFetcher = async (addressOfUser) => {
//   try {
//     const promises = [];
//     const balancePromises = [];
//     const connectedNetwork = CONFIG.NETWORK;
//     for (let symbol in CONFIG.CONTRACT[connectedNetwork].FARMS) {
//       promises.push(
//         fetchFarmBalance(
//           connectedNetwork,
//           addressOfUser,
//           CONFIG.CONTRACT[connectedNetwork].FARMS[symbol].CONTRACT,
//           symbol
//         )
//       );
//       balancePromises.push(
//         fetchWalletBalance(
//           connectedNetwork,
//           addressOfUser,
//           CONFIG.CONTRACT[connectedNetwork].FARMS[symbol].LP_TOKEN,
//           symbol
//         )
//       );
//     }
//     const response = await Promise.all(promises);
//     const balanceResponse = await Promise.all(balancePromises);
//     return {
//       success: true,
//       response,
//       balanceResponse,
//     };
//   } catch (e) {
//     return {
//       success: false,
//       error: e,
//     };
//   }
// };

export const BalancesFarmFetcher = async (addressOfUser, active) => {
  try {
    const promises = [];
    const balancePromises = [];
    const connectedNetwork = CONFIG.NETWORK;
    for (let symbol in CONFIG.CONTRACT[connectedNetwork].FARMS) {
      let contractAddress = null;
      if (active === true) {
        contractAddress =
          CONFIG.CONTRACT[connectedNetwork].FARMS[symbol].CONTRACT;
      } else {
        contractAddress =
          CONFIG.CONTRACT[connectedNetwork].FARMS[symbol].INACTIVE;
      }
      promises.push(
        fetchFarmBalance(
          connectedNetwork,
          addressOfUser,
          contractAddress,
          symbol
        )
      );
      balancePromises.push(
        fetchWalletBalance(
          connectedNetwork,
          addressOfUser,
          CONFIG.CONTRACT[connectedNetwork].FARMS[symbol].LP_TOKEN,
          symbol
        )
      );
    }
    const response = await Promise.all(promises);
    const balanceResponse = await Promise.all(balancePromises);
    return {
      success: true,
      response,
      balanceResponse,
    };
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
};

// export const stakeFarm = async (somenet, amount, farmIdentifier) => {
//     try {
//         const connectedNetwork = CONFIG.NETWORK;
//         const network = {
//             type: CONFIG.WALLET_NETWORK,
//         };
//         const options = {
//             name: 'Plenty Farm',
//         };
//         const wallet = new BeaconWallet(options);
//         const WALLET_RESP = await CheckIfWalletConnected(wallet, network.type);
//         if (WALLET_RESP.success) {
//             const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
//             Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
//             Tezos.setWalletProvider(wallet);
//             const farmContractInstance = await Tezos.wallet.at(
//                 CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].CONTRACT
//             );
//             const tokenContractInstance = await Tezos.wallet.at(
//                 CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].LP_TOKEN
//             );
//             let tokenAmount = amount * Math.pow(10, 6);
//             const batch = await Tezos.wallet
//                 .batch()
//                 .withContractCall(
//                     tokenContractInstance.methods.approve(
//                         CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier]
//                             .CONTRACT,
//                         tokenAmount
//                     )
//                 )
//                 .withContractCall(
//                     farmContractInstance.methods.stake(tokenAmount)
//                 );
//             const batchOperation = await batch.send();
//             await batchOperation
//                 .confirmation()
//                 .then(() => batchOperation.opHash);
//             return {
//                 success: true,
//                 operationId: batchOperation.opHash,
//             };
//         } else {
//             return {
//                 success: true,
//                 error: WALLET_RESP.error,
//             };
//         }
//     } catch (error) {
//
//         return {
//             success: false,
//             error,
//         };
//     }
// };

// export const unstakeFarm = async (somenet, amount, mapKey, symbol) => {
//     try {
//         const connectedNetwork = CONFIG.NETWORK;
//         const network = {
//             type: CONFIG.WALLET_NETWORK,
//         };
//         const options = {
//             name: 'Plenty Farm',
//         };
//         const wallet = new BeaconWallet(options);
//         const WALLET_RESP = await CheckIfWalletConnected(wallet, network.type);
//         if (WALLET_RESP.success) {
//             const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
//             Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
//             Tezos.setWalletProvider(wallet);

//             const contractInstance = await Tezos.wallet.at(
//                 CONFIG.CONTRACT[connectedNetwork].FARMS[symbol].CONTRACT
//             );
//             let tokenAmount = amount * Math.pow(10, 6);
//             const operation = await contractInstance.methods
//                 .unstake(tokenAmount, mapKey)
//                 .send();
//             await operation.confirmation().then(() => operation.opHash);
//             return {
//                 success: true,
//                 operationId: operation.opHash,
//             };
//         } else {
//             return {
//                 success: true,
//                 error: WALLET_RESP.error,
//             };
//         }
//     } catch (error) {
//
//         return {
//             success: false,
//             error,
//         };
//     }
// };

export const stakeFarm = async (somenet, amount, farmIdentifier) => {
  try {
    const network = {
      type: CONFIG.WALLET_NETWORK,
    };
    const options = {
      name: 'Plenty Farm',
    };
    const connectedNetwork = CONFIG.NETWORK;
    const wallet = new BeaconWallet(options);
    const WALLET_RESP = await CheckIfWalletConnected(wallet, network.type);
    if (WALLET_RESP.success) {
      const account = await wallet.client.getActiveAccount();
      const userAddress = account.address;
      const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setWalletProvider(wallet);
      const farmContractInstance = await Tezos.wallet.at(
        CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].CONTRACT
      );
      const tokenContractInstance = await Tezos.wallet.at(
        CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].LP_TOKEN
      );
      let tokenAmount =
        amount *
        Math.pow(
          10,
          CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].TOKEN_DECIMAL
        );
      let batch = null;
      if (
        CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].TYPE === 'FA1.2'
      ) {
        batch = await Tezos.wallet
          .batch()
          .withContractCall(
            tokenContractInstance.methods.approve(
              CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].CONTRACT,
              tokenAmount
            )
          )
          .withContractCall(farmContractInstance.methods.stake(tokenAmount));
      } else {
        batch = Tezos.wallet
          .batch()
          .withContractCall(
            tokenContractInstance.methods.update_operators([
              {
                add_operator: {
                  owner: userAddress,
                  operator:
                    CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier]
                      .CONTRACT,
                  token_id:
                    CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier]
                      .TOKEN_ID,
                },
              },
            ])
          )
          .withContractCall(farmContractInstance.methods.stake(tokenAmount))
          .withContractCall(
            tokenContractInstance.methods.update_operators([
              {
                remove_operator: {
                  owner: userAddress,
                  operator:
                    CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier]
                      .CONTRACT,
                  token_id:
                    CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier]
                      .TOKEN_ID,
                },
              },
            ])
          );
      }
      const batchOperation = await batch.send();
      await batchOperation.confirmation().then(() => batchOperation.opHash);
      return {
        success: true,
        operationId: batchOperation.opHash,
      };
    } else {
      return {
        success: true,
        error: WALLET_RESP.error,
      };
    }
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

export const unstakeFarm = async (somenet, amount, mapKey, symbol, active) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const network = {
      type: CONFIG.WALLET_NETWORK,
    };
    const options = {
      name: 'Plenty Farm',
    };
    const wallet = new BeaconWallet(options);
    const WALLET_RESP = await CheckIfWalletConnected(wallet, network.type);
    if (WALLET_RESP.success) {
      const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setWalletProvider(wallet);
      let contractAddress = null;
      if (active === true) {
        contractAddress =
          CONFIG.CONTRACT[connectedNetwork].FARMS[symbol].CONTRACT;
      } else {
        contractAddress =
          CONFIG.CONTRACT[connectedNetwork].FARMS[symbol].INACTIVE;
      }
      const contractInstance = await Tezos.wallet.at(contractAddress);
      let tokenAmount = amount * Math.pow(10, 6);
      const operation = await contractInstance.methods
        .unstake(tokenAmount, mapKey)
        .send();
      await operation.confirmation().then(() => operation.opHash);
      return {
        success: true,
        operationId: operation.opHash,
      };
    } else {
      return {
        success: true,
        error: WALLET_RESP.error,
      };
    }
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

// export const getFarmReward = async (addressOfUser, active) => {
//   try {
//     const identifier = 'PLENTY - XTZ';
//     const connectedNetwork = CONFIG.NETWORK;
//     const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
//     Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);

//     let contractAddress = null;
//     if (active === true) {
//       contractAddress =
//         CONFIG.CONTRACT[connectedNetwork].FARMS[identifier].CONTRACT;
//     } else {
//       contractAddress =
//         CONFIG.CONTRACT[connectedNetwork].FARMS[identifier].INACTIVE;
//     }
//     const plentyContractInstance = await Tezos.contract.at(contractAddress);
//     const storage = await plentyContractInstance.storage();
//     const userDetails = await storage.balances.get(addressOfUser);
//     let balance = userDetails.balance.toNumber();
//     var calculatedRewards = 0;

//     let block = await axios.get(
//       `${CONFIG.RPC_NODES[connectedNetwork]}chains/main/blocks/head`
//     );
//     let presentBlocklevel = block.data.header.level;
//     let periodFinish = await storage.periodFinish.toNumber();
//     var lastUpdate = 0;
//     if (presentBlocklevel > periodFinish) {
//       lastUpdate = periodFinish;
//     } else {
//       lastUpdate = presentBlocklevel;
//     }
//     var totalSupply = await storage.totalSupply.toNumber();
//     var rewardPerTokenStored = await storage.rewardPerTokenStored.toNumber();
//     var rewardRate = await storage.rewardRate.toNumber();
//     if (totalSupply !== 0) {
//       var res = 0;
//       var lut = await storage.lastUpdateTime.toNumber();
//       res += lastUpdate - lut;
//       res = res * Math.pow(10, 18) * rewardRate;
//       res = res / totalSupply;
//       rewardPerTokenStored += res;
//     }
//     var lastUpdateTime = lastUpdate;
//     var rewards = userDetails.rewards.toNumber();
//     var userRewardPerTokenPaid = userDetails.userRewardPerTokenPaid.toNumber();

//     if (
//       addressOfUser !==
//       CONFIG.CONTRACT[connectedNetwork].FARMS[identifier].CONTRACT
//     ) {
//       rewards +=
//         (balance * (rewardPerTokenStored - userRewardPerTokenPaid)) /
//         Math.pow(10, 18);
//       userRewardPerTokenPaid = rewardPerTokenStored;
//     }
//     var lastTimeReward = 0;
//     if (presentBlocklevel > periodFinish) {
//       lastTimeReward = periodFinish;
//     } else {
//       lastTimeReward = presentBlocklevel;
//     }
//     var rewardPerToken = rewardPerTokenStored;
//     if (totalSupply !== 0) {
//       var diff = lastTimeReward - lastUpdateTime;
//       diff =
//         (diff * rewardRate * Math.pow(10, 18)) / Math.pow(10, 18) +
//         rewardPerTokenStored;
//       rewardPerToken = diff;
//       rewardPerToken = diff;
//     }
//     calculatedRewards = balance;
//     calculatedRewards *= rewardPerToken - userRewardPerTokenPaid;
//     calculatedRewards = calculatedRewards / Math.pow(10, 18) + rewards;
//     calculatedRewards = (calculatedRewards / Math.pow(10, 18)).PrecisionMaker(
//       5
//     );
//     calculatedRewards = parseFloat(calculatedRewards);

//     return {
//       success: true,
//       rewards: calculatedRewards,
//     };
//   } catch (error) {
//
//     return {
//       success: false,
//       rewards: 0,
//       error,
//     };
//   }
// };

// export const harvestFarm = async (farmIdentifier) => {
//   try {
//     const connectedNetwork = CONFIG.NETWORK;
//     const network = {
//       type: CONFIG.WALLET_NETWORK,
//     };
//     const options = {
//       name: 'Plenty Farm',
//     };
//     const wallet = new BeaconWallet(options);
//     const WALLET_RESP = await CheckIfWalletConnected(wallet, network.type);
//     if (WALLET_RESP.success) {
//       const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
//       Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
//       Tezos.setWalletProvider(wallet);
//       const contractInstance = await Tezos.wallet.at(
//         CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].CONTRACT
//       );
//       const operation = await contractInstance.methods.GetReward(1).send();
//       await operation.confirmation().then(() => operation.opHash);
//       return {
//         success: true,
//         operationId: operation.opHash,
//       };
//     } else {
//       return {
//         success: true,
//         error: WALLET_RESP.error,
//       };
//     }
//   } catch (error) {
//
//     return {
//       success: false,
//       error,
//     };
//   }
// };

export const getFarmReward = async (
  addressOfUser,
  active,
  contractAddress,
  identifier
) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    const plentyContractInstance = await Tezos.contract.at(contractAddress);
    const storage = await plentyContractInstance.storage();
    const userDetails = await storage.balances.get(addressOfUser);
    let balance = userDetails.balance.toNumber();
    var calculatedRewards = 0;
    let block = await axios.get(
      `${CONFIG.RPC_NODES[connectedNetwork]}chains/main/blocks/head`
    );
    let presentBlocklevel = block.data.header.level;
    let periodFinish = await storage.periodFinish.toNumber();
    var lastUpdate = 0;
    if (presentBlocklevel > periodFinish) {
      lastUpdate = periodFinish;
    } else {
      lastUpdate = presentBlocklevel;
    }
    var totalSupply = await storage.totalSupply.toNumber();
    var rewardPerTokenStored = await storage.rewardPerTokenStored.toNumber();
    var rewardRate = await storage.rewardRate.toNumber();
    if (totalSupply !== 0) {
      var res = 0;
      var lut = await storage.lastUpdateTime.toNumber();
      res += lastUpdate - lut;
      res = res * Math.pow(10, 18) * rewardRate;
      res = res / totalSupply;
      rewardPerTokenStored += res;
    }
    var lastUpdateTime = lastUpdate;
    var rewards = userDetails.rewards.toNumber();
    var userRewardPerTokenPaid = userDetails.userRewardPerTokenPaid.toNumber();
    if (
      addressOfUser !==
      CONFIG.CONTRACT[connectedNetwork].FARMS[identifier].CONTRACT
    ) {
      rewards +=
        (balance * (rewardPerTokenStored - userRewardPerTokenPaid)) /
        Math.pow(10, 18);
      userRewardPerTokenPaid = rewardPerTokenStored;
    }
    var lastTimeReward = 0;
    if (presentBlocklevel > periodFinish) {
      lastTimeReward = periodFinish;
    } else {
      lastTimeReward = presentBlocklevel;
    }
    var rewardPerToken = rewardPerTokenStored;
    if (totalSupply !== 0) {
      var diff = lastTimeReward - lastUpdateTime;
      diff =
        (diff * rewardRate * Math.pow(10, 18)) / Math.pow(10, 18) +
        rewardPerTokenStored;
      rewardPerToken = diff;
      rewardPerToken = diff;
    }
    calculatedRewards = balance;
    calculatedRewards *= rewardPerToken - userRewardPerTokenPaid;
    calculatedRewards = calculatedRewards / Math.pow(10, 18) + rewards;
    calculatedRewards = (calculatedRewards / Math.pow(10, 18)).PrecisionMaker(
      5
    );
    calculatedRewards = parseFloat(calculatedRewards);
    if (calculatedRewards < 0) {
      calculatedRewards = 0;
    }
    return {
      success: true,
      rewards: calculatedRewards,
      symbol: identifier,
    };
  } catch (error) {
    return {
      success: false,
      rewards: 0,
      error,
    };
  }
};
export const getFarmRewardsArray = async (addressOfUser, active) => {
  try {
    const promises = [];
    const connectedNetwork = CONFIG.NETWORK;
    for (let symbol in CONFIG.CONTRACT[connectedNetwork].FARMS) {
      let contractAddress = null;
      if (active === true) {
        contractAddress =
          CONFIG.CONTRACT[connectedNetwork].FARMS[symbol].CONTRACT;
      } else {
        contractAddress =
          CONFIG.CONTRACT[connectedNetwork].FARMS[symbol].INACTIVE;
      }
      promises.push(
        getFarmReward(addressOfUser, active, contractAddress, symbol)
      );
    }
    const response = await Promise.all(promises);
    return {
      success: true,
      response,
    };
  } catch (error) {
    return {
      success: false,
      rewards: 0,
      error,
    };
  }
};

export const harvestFarm = async (farmIdentifier, active) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const network = {
      type: CONFIG.WALLET_NETWORK,
    };
    const options = {
      name: 'Plenty Farm',
    };
    const wallet = new BeaconWallet(options);
    const WALLET_RESP = await CheckIfWalletConnected(wallet, network.type);
    if (WALLET_RESP.success) {
      const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setWalletProvider(wallet);
      let contractAddress = null;
      if (active === true) {
        contractAddress =
          CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].CONTRACT;
      } else {
        contractAddress =
          CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].INACTIVE;
      }
      const contractInstance = await Tezos.wallet.at(contractAddress);
      const operation = await contractInstance.methods.GetReward(1).send();
      await operation.confirmation().then(() => operation.opHash);
      return {
        success: true,
        operationId: operation.opHash,
      };
    } else {
      return {
        success: true,
        error: WALLET_RESP.error,
      };
    }
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

const calculateWithdrwalaFee = (difference, balance, symbol) => {
  let feeStructure = [
    {
      block: 12288,
      rate: 4,
    },
    {
      block: 12288,
      rate: 0,
    },
  ];

  if (symbol === 'KALAM - XTZ') {
    feeStructure = [
      {
        block: 0,
        rate: 0,
      },
    ];
  }
  let fee = -1;
  for (let i = 0; i < feeStructure.length; i++) {
    if (difference < feeStructure[i].block) {
      fee = ((balance * feeStructure[i].rate) / 100).PrecisionMaker(6);
      fee = parseFloat(fee);
      break;
    }
  }
  if (fee === -1) {
    fee = (
      (balance * feeStructure[feeStructure.length - 1].rate) /
      100
    ).toFixed(2);
    fee = parseFloat(fee);
  }
  return fee;
};

// export const getFarmStakes = async (somenet, addressOfUser, symbol) => {
//   try {
//     const connectNetwork = CONFIG.NETWORK;
//     const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectNetwork]);
//     Tezos.setProvider(CONFIG.RPC_NODES[connectNetwork]);
//     const blockData = await axios.get(
//       `${CONFIG.TZKT_NODES[connectNetwork]}/v1/blocks/count`
//     );
//     const contract = await Tezos.contract.at(
//       CONFIG.CONTRACT[connectNetwork].FARMS[symbol].CONTRACT
//     );
//     const storage = await contract.storage();
//     const userDetails = await storage.balances.get(addressOfUser);
//     let blocks = [];
//     userDetails.InvestMap.keyMap.forEach((element) => {
//       let blockDetails = userDetails.InvestMap.get(element);
//       let blockAtStake = blockDetails.level.toNumber();
//       blockAtStake = parseInt(blockAtStake);
//       let difference = blockData.data - blockAtStake;
//       let balance = (
//         blockDetails.amount.toNumber() / Math.pow(10, 6)
//       ).PrecisionMaker(3);
//       balance = parseFloat(balance);
//       let withdrawalFee = calculateWithdrwalaFee(difference, balance);
//       blocks.push({
//         number: parseInt(element) + 1,
//         balance,
//         withdrawalFee,
//       });
//     });
//     return {
//       success: true,
//       stakes: blocks,
//     };
//   } catch (e) {
//      ;
//     return {
//       success: false,
//       error: e,
//     };
//   }
// };

export const getFarmStakes = async (somenet, addressOfUser, symbol, active) => {
  try {
    const connectNetwork = CONFIG.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectNetwork]);
    Tezos.setProvider(CONFIG.RPC_NODES[connectNetwork]);
    const blockData = await axios.get(
      `${CONFIG.TZKT_NODES[connectNetwork]}/v1/blocks/count`
    );
    let contractAddress = null;
    if (active === true) {
      contractAddress = CONFIG.CONTRACT[connectNetwork].FARMS[symbol].CONTRACT;
    } else {
      contractAddress = CONFIG.CONTRACT[connectNetwork].FARMS[symbol].INACTIVE;
    }
    const contract = await Tezos.contract.at(contractAddress);
    const storage = await contract.storage();
    const userDetails = await storage.balances.get(addressOfUser);
    let blocks = [];
    userDetails.InvestMap.keyMap.forEach((element) => {
      let blockDetails = userDetails.InvestMap.get(element);
      let blockAtStake = blockDetails.level.toNumber();
      blockAtStake = parseInt(blockAtStake);
      let difference = blockData.data - blockAtStake;
      let balance = (
        blockDetails.amount.toNumber() / Math.pow(10, 6)
      ).PrecisionMaker(3);
      balance = parseFloat(balance);
      let withdrawalFee = calculateWithdrwalaFee(difference, balance, symbol);
      blocks.push({
        number: parseInt(element) + 1,
        balance,
        withdrawalFee,
      });
    });
    return {
      success: true,
      stakes: blocks,
    };
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
};

const getPriceInUSD = async (contractAddress, somenet) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    const dexInstance = await Tezos.contract.at(contractAddress);
    const dexStorage = await dexInstance.storage();
    let tezPool = await dexStorage.storage.tez_pool.toNumber();
    let tokenPool = await dexStorage.storage.token_pool.toNumber();
    let tokenPriceInXtz = Math.pow(10, 18) * tezPool * 1000;
    tokenPriceInXtz = tokenPriceInXtz / (tokenPool - Math.pow(10, 18));
    tokenPriceInXtz = tokenPriceInXtz / 997;
    tokenPriceInXtz = tokenPriceInXtz / Math.pow(10, 6);
    let priceFeed = await axios.get(CONFIG.API.url);
    priceFeed = priceFeed.data.market_data.current_price.usd;
    let tokenPriceInUSD = tokenPriceInXtz * priceFeed;
    return {
      success: true,
      tokenPriceInUSD,
    };
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
};
// const getLPPriceInUSD = async (dexContract, somenet) => {
//   try {
//     const connectedNetwork = CONFIG.NETWORK;
//     const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
//     Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
//     const dex_contract = await Tezos.contract.at(dexContract);
//     const dex_storage = await dex_contract.storage();
//     let tezPool = await dex_storage.storage.tez_pool;
//     let tokenPool = await dex_storage.storage.token_pool;
//     let tokenPriceInXtz =
//       (((Math.pow(10, 18) * tezPool) / (tokenPool - Math.pow(10, 18))) * 1000) /
//       997 /
//       Math.pow(10, 6);
//     let priceFeed = await axios.get(CONFIG.API.url);
//     let plentyTokenPriceinUSD =
//       tokenPriceInXtz * priceFeed.data.market_data.current_price.usd;
//     let totalSupply = await dex_storage.storage.total_supply;
//     totalSupply = (totalSupply.toNumber() / Math.pow(10, 6)).PrecisionMaker(0);
//     totalSupply = parseInt(totalSupply);
//     let LPTokenPrice =
//       ((tezPool / Math.pow(10, 6)) *
//         priceFeed.data.market_data.current_price.usd +
//         (tokenPool / Math.pow(10, 18)) * plentyTokenPriceinUSD) /
//       totalSupply;
//     return {
//       success: true,
//       value: LPTokenPrice,
//     };
//   } catch (error) {
//
//     return {
//       success: false,
//       error,
//     };
//   }
// };
const getLPPriceInUSD = async (dexContract, somenet, decimal) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    const dex_contract = await Tezos.contract.at(dexContract);
    const dex_storage = await dex_contract.storage();
    let tezPool = await dex_storage.storage.tez_pool;
    let tokenPool = await dex_storage.storage.token_pool;
    let tokenPriceInXtz =
      (((Math.pow(10, 18) * tezPool) / (tokenPool - Math.pow(10, decimal))) *
        1000) /
      997 /
      Math.pow(10, 6);
    let priceFeed = await axios.get(CONFIG.API.url);
    let plentyTokenPriceinUSD =
      tokenPriceInXtz * priceFeed.data.market_data.current_price.usd;
    let totalSupply = await dex_storage.storage.total_supply;
    totalSupply = (totalSupply.toNumber() / Math.pow(10, 6)).PrecisionMaker(0);
    totalSupply = parseInt(totalSupply);
    let LPTokenPrice =
      ((tezPool / Math.pow(10, 6)) *
        priceFeed.data.market_data.current_price.usd +
        (tokenPool / Math.pow(10, 18)) * plentyTokenPriceinUSD) /
      totalSupply;
    return {
      success: true,
      value: LPTokenPrice,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};
// const getRoiTableForFarm = async (farmIdentifier) => {
//   try {
//     const connectedNetwork = CONFIG.NETWORK;
//     const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
//     Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
//     const plentyContractInstance = await Tezos.contract.at(
//       CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].CONTRACT
//     );
//     const storage = await plentyContractInstance.storage();
//     let totalSupply = await storage.totalSupply;
//     totalSupply = (totalSupply.toNumber() / Math.pow(10, 18)).PrecisionMaker(0);
//     totalSupply = parseInt(totalSupply);
//     let rewardRate = await storage.rewardRate;
//     rewardRate = (rewardRate.toNumber() / Math.pow(10, 18)).PrecisionMaker(18);
//     rewardRate = parseFloat(rewardRate);
//     let priceOfPlentyInUSD = await getPriceInUSD(
//       CONFIG.CONTRACT[connectedNetwork].POOLS['PLENTY'].DEX,
//       connectedNetwork
//     );
//     let priceOfStakeTokenInUsd = await getLPPriceInUSD(
//       CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].DEX,
//       connectedNetwork
//     );
//     let DPY =
//       (rewardRate * 2880 * priceOfPlentyInUSD.tokenPriceInUSD) /
//       (totalSupply * priceOfStakeTokenInUsd.value);
//     DPY = DPY * 100;
//     let intervalList = [1, 7, 30, 365];
//     let roiTable = [];
//     for (const interval of intervalList) {
//       roiTable.push({
//         roi: DPY * interval,
//         PlentyPer1000dollar:
//           (10 * DPY * interval) / priceOfPlentyInUSD.tokenPriceInUSD,
//       });
//     }
//     return {
//       success: true,
//       roiTable,
//       totalSupply,
//       rewardRate,
//       title: farmIdentifier,
//     };
//   } catch (error) {
//     console.error(error);
//     return {
//       success: false,
//       error,
//     };
//   }
// };

const getRoiTableForFarm = async (farmIdentifier) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    const plentyContractInstance = await Tezos.contract.at(
      CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].CONTRACT
    );
    const storage = await plentyContractInstance.storage();
    let totalLiquidity = await storage.totalSupply;
    totalLiquidity = (
      totalLiquidity.toNumber() / Math.pow(10, 18)
    ).PrecisionMaker(18);
    totalLiquidity = parseFloat(totalLiquidity);
    let rewardRate = await storage.rewardRate;
    rewardRate = (rewardRate.toNumber() / Math.pow(10, 18)).PrecisionMaker(18);
    rewardRate = parseFloat(rewardRate);
    let priceOfPlentyInUSD = await getPriceInUSD(
      CONFIG.CONTRACT[connectedNetwork].POOLS['PLENTY'].DEX,
      connectedNetwork
    );
    let priceOfStakeTokenInUsd = await getLPPriceInUSD(
      CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].DEX,
      connectedNetwork,
      CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].LP_DECIMAL
    );
    let DPY =
      (rewardRate * 2880 * priceOfPlentyInUSD.tokenPriceInUSD) /
      (totalLiquidity * priceOfStakeTokenInUsd.value);
    DPY = DPY * 100;
    let intervalList = [1, 7, 30, 365];
    let roiTable = [];
    for (const interval of intervalList) {
      roiTable.push({
        roi: DPY * interval,
        PlentyPer1000dollar:
          (10 * DPY * interval) / priceOfPlentyInUSD.tokenPriceInUSD,
      });
    }
    return {
      success: true,
      roiTable,
      totalSupply: totalLiquidity,
      rewardRate,
      title: farmIdentifier,
    };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      error,
    };
  }
};

export const getROIOfAllFarms = async (connectedNetwork) => {
  try {
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    let promises = [];
    for (let identifier in CONFIG.CONTRACT[connectedNetwork].FARMS) {
      promises.push(getRoiTableForFarm(identifier));
    }
    const responses = await Promise.all(promises);
    return {
      success: true,
      roiOfFarms: responses,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};
