import React, {useState, useContext} from 'react';
import AppContext from '../../store/context';

import logo from '../../assets/image/logo-icon.png'

const NewUser = () => {
  const [checked, setChecked] = useState({
    checkbox1: false,
    checkbox2: false
  });

  const submitUser = () => {
    localStorage.setItem('newUser', false);
    window.location.reload();
  }

  let continueBtn = <button className="header-connect-button" error-tooltip="Please check both boxes first">Continue</button>;

  if(checked.checkbox1 && checked.checkbox2) {
    continueBtn = <button className="header-connect-button" onClick={submitUser} >Continue</button>;
  }
  const ctx = useContext(AppContext);
  return(
    <div className="new-user-section-wrapper">
      <div className="new-user-wrapper">
        <div className="plenty-new-user-header flex justify-center flex-wrap">
          <div className="logo-img-wrapper">
            <img src={logo} className="logo-img" />
          </div>
          <div className="new-user-welcome">
            <h2 className="new-user-welcome-heading">Welcome</h2>
            <p className="plenty-new-user-warning-text">This Product is in beta. Please confirm the following:</p>
          </div>
        </div>

        <div className="plenty-new-user-input-wrapper">
          <label className="plenty-new-user-input-label b-contain">
            <input type="checkbox" onChange={() => setChecked({
              ...checked,
              checkbox1: !checked.checkbox1
            })}/>
            <div className="b-input"></div>
            <p className="plenty-new-user-label-text">I understand that I am using this product at my own risk. Any losses incurred due to my actions are my own responsibility.</p>
          </label>

          <label className="plenty-new-user-input-label b-contain">
            <input type="checkbox" onChange={() => setChecked({
              ...checked,
              checkbox2: !checked.checkbox2}
            )}/>
            <div className="b-input"></div>
            <p className="plenty-new-user-label-text">I understand that this product is still in beta. I am participating at my own risk.</p>
          </label>
        </div>

        <div className="plenty-new-user-continue-btn-wrapper">
          {continueBtn}
        </div>
      </div>
    </div>
  )
}

export default NewUser; 