import React from 'react';

const SwitchButton = (props) => {
  const checked = !props.showActive;
  return (
    <div className="toggleWrapper">
      <input
        type="checkbox"
        onChange={() => {
          props.setShow();
          props.fetchPoolDataOnActiveStateChange();
        }}
        checked={checked}
        className="dn"
        id="dn"
      />
      <label htmlFor="dn" className="toggle">
        <span className="toggle__handler"></span>
      </label>
    </div>
  );
};

export default SwitchButton;
