import { TezosToolkit } from '@taquito/taquito';
import { BeaconWallet } from '@taquito/beacon-wallet';
import axios from 'axios';
const CONFIG = require('./config');
const CONFIG_NEW = require('./configV2');

export const CheckIfWalletConnected = async (wallet, connectedNetwork) => {
  try {
    const network = {
      type: connectedNetwork,
    };
    const activeAccount = await wallet.client.getActiveAccount();
    if (!activeAccount) {
      await wallet.client.requestPermissions({
        network,
      });
    }
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

export const harvest = async (tezosInstance, somenet) => {
  try {
    const options = {
      name: 'Plenty Farm',
    };
    const network = {
      type: CONFIG.WALLET_NETWORK,
    };
    const wallet = new BeaconWallet(options);
    await wallet.client.requestPermissions({
      network,
    });
    const connectedNetwork = CONFIG.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setWalletProvider(wallet);
    const contractInstance = await Tezos.wallet.at(
      CONFIG.CONTRACT[connectedNetwork].PLENTY_FARM_CONTRACT
    );
    const operation = await contractInstance.methods.GetReward(1).send();
    await operation.confirmation().then(() => operation.opHash);
    return {
      success: true,
      operationId: operation.opHash,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

export const stake = async (uselessObject, somenet, amount) => {
  try {
    const options = {
      name: 'Plenty Farm',
    };
    const network = {
      type: CONFIG.WALLET_NETWORK,
    };
    const connectedNetwork = CONFIG.NETWORK;
    const wallet = new BeaconWallet(options);
    await wallet.client.requestPermissions({
      network,
    });
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setWalletProvider(wallet);

    const plentyFarmContractInstance = await Tezos.wallet.at(
      CONFIG.CONTRACT[connectedNetwork].PLENTY_FARM_CONTRACT
    );
    const plentyTokenContractInstance = await Tezos.wallet.at(
      CONFIG.CONTRACT[connectedNetwork].PLENTY_TOKEN_CONTRACT
    );
    let tokenAmount = amount * Math.pow(10, 18);
    const batch = await Tezos.wallet
      .batch()
      .withContractCall(
        plentyTokenContractInstance.methods.approve(
          CONFIG.CONTRACT[connectedNetwork].PLENTY_FARM_CONTRACT,
          tokenAmount
        )
      )
      .withContractCall(plentyFarmContractInstance.methods.stake(tokenAmount));
    const batchOperation = await batch.send();
    await batchOperation.confirmation().then(() => batchOperation.opHash);
    return {
      success: true,
      operationId: batchOperation.opHash,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

export const unstake = async (uselessObject, someNet, amount, mapKey) => {
  try {
    const network = {
      type: CONFIG.WALLET_NETWORK,
    };
    const options = {
      name: 'Plenty Farm',
    };
    const wallet = new BeaconWallet(options);
    const connectedNetwork = CONFIG.NETWORK;
    const WALLET_RESP = await CheckIfWalletConnected(wallet, network.type);
    if (WALLET_RESP.success) {
      const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setWalletProvider(wallet);

      const contractInstance = await Tezos.wallet.at(
        CONFIG.CONTRACT[connectedNetwork].PLENTY_FARM_CONTRACT
      );
      let tokenAmount = amount * Math.pow(10, 18);
      const operation = await contractInstance.methods
        .unstake(tokenAmount, mapKey)
        .send();
      await operation.confirmation().then(() => operation.opHash);
      return {
        success: true,
        operationId: operation.opHash,
      };
    } else {
      return {
        success: false,
        error: WALLET_RESP.error,
      };
    }
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

export const getToTalSupply = async (tezosInstance, network) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const contract = await tezosInstance.contract.at(
      CONFIG.CONTRACT[connectedNetwork].PLENTY_TOKEN_CONTRACT
    );
    const storage = await contract.storage();
    const totalSupply = await storage.totalSupply;
    return {
      success: true,
      totalSupply,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

export const getPlentyBalance = async (tezosInstance, network, address) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const contract = await tezosInstance.contract.at(
      CONFIG.CONTRACT[connectedNetwork].PLENTY_TOKEN_CONTRACT
    );
    const storage = await contract.storage();
    const addressDetails = await storage.balances.get(address);
    const balance = await addressDetails.balance;
    return {
      success: false,
      balance,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

export const getSupplyAndBalance = async (someNet) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    const contract = await Tezos.contract.at(
      CONFIG.CONTRACT[connectedNetwork].PLENTY_TOKEN_CONTRACT
    );
    const storage = await contract.storage();
    const totalSupply = parseFloat(
      (storage.totalSupply.toNumber() / Math.pow(10, 18)).PrecisionMaker(0)
    );
    const adminAddress = storage.administrator;
    const adminBalance = await storage.balances.get(adminAddress);
    const finalBalance = parseFloat(
      (adminBalance.balance.toNumber() / Math.pow(10, 18)).PrecisionMaker(0)
    );

    // const addressDetails = await storage.balances.get(address);
    // const balance = await addressDetails.balance;
    return {
      success: true,
      totalSupply,
      adminBalance: finalBalance,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

export const getPoolsPlentyBalanceOfUser = async (someNet, address) => {
  try {
    const options = {
      name: 'Plenty Farm',
    };
    const network = {
      type: CONFIG.WALLET_NETWORK,
    };
    const connectedNetwork = CONFIG.NETWORK;
    const wallet = new BeaconWallet(options);
    await wallet.client.requestPermissions({
      network,
    });
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setWalletProvider(wallet);
    const contract = await Tezos.contract.at(
      CONFIG.CONTRACT[connectedNetwork].PLENTY_TOKEN_CONTRACT
    );
    const storage = await contract.storage();
    const addressDetails = await storage.balances.get(address);
    const balance = await addressDetails.balance;
    const finalBalance = parseFloat(
      (balance.toNumber() / Math.pow(10, 18)).PrecisionMaker(0)
    );
    return {
      success: true,
      balance: finalBalance,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

export const getPoolsPlentyBalanceOfUserNEW = async (
  someNet,
  address,
  poolIdentifier
) => {
  try {
    const options = {
      name: 'Plenty Farm',
    };
    const connectedNetwork = CONFIG.NETWORK;
    const wallet = new BeaconWallet(options);
    const WALLET_RESP = await CheckIfWalletConnected(wallet, connectedNetwork);
    if (WALLET_RESP.success) {
      const Tezos = new TezosToolkit(CONFIG_NEW.RPC_NODES[connectedNetwork]);
      Tezos.setRpcProvider(CONFIG_NEW.RPC_NODES[connectedNetwork]);
      Tezos.setWalletProvider(wallet);
      const contract = await Tezos.contract.at(
        CONFIG_NEW.CONTRACT[connectedNetwork].POOLS[poolIdentifier].CONTRACT
      );
      const storage = await contract.storage();
      const addressDetails = await storage.balances.get(address);
      const balance = await addressDetails.balance;
      const finalBalance = parseFloat(
        (balance.toNumber() / Math.pow(10, 18)).PrecisionMaker(0)
      );
      return {
        success: true,
        balance: finalBalance,
      };
    }
    return {
      success: false,
      balance: 0,
      error: WALLET_RESP.error,
    };
  } catch (error) {
    return {
      success: false,
      balance: 0,
      error,
    };
  }
};

export const userBalancerFetcher = async (address) => {
  try {
    const connectedNetwork = CONFIG_NEW.NETWORK;
    const { POOLS } = CONFIG_NEW.CONTRACT[connectedNetwork];
    const POOLS_ARRAY = Object.keys(POOLS).map((elem) =>
      getPoolsPlentyBalanceOfUserNEW(address, connectedNetwork, elem)
    );
    const API_RESPONSE = await Promise.all(POOLS_ARRAY);
    return {
      success: true,
      data: API_RESPONSE,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

export const getPlentyBalanceOfUser = async (someNet, address) => {
  try {
    const network = {
      type: CONFIG.WALLET_NETWORK,
    };
    const options = {
      name: 'Plenty Farm',
    };
    const connectedNetwork = CONFIG.NETWORK;
    const wallet = new BeaconWallet(options);
    const WALLET_RESP = await CheckIfWalletConnected(wallet, network.type);
    if (WALLET_RESP.success) {
      const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setWalletProvider(wallet);
      const contract = await Tezos.contract.at(
        CONFIG.CONTRACT[connectedNetwork].PLENTY_TOKEN_CONTRACT
      );
      const storage = await contract.storage();
      const addressDetails = await storage.balances.get(address);
      const balance = await addressDetails.balance;
      const finalBalance = parseFloat(
        (balance.toNumber() / Math.pow(10, 18)).PrecisionMaker(0)
      );
      return {
        success: true,
        balance: finalBalance,
      };
    } else {
      return {
        success: false,
      };
    }
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

export const getStakedPlentyBalanceOfUser = async (someNet, address) => {
  try {
    const network = {
      type: CONFIG.WALLET_NETWORK,
    };
    const wallet = new BeaconWallet({
      name: 'Plenty Farm',
      preferredNetwork: network.type,
    });
    const connectedNetwork = CONFIG.NETWORK;
    const WALLET_RESP = await CheckIfWalletConnected(wallet, connectedNetwork);
    if (WALLET_RESP.success) {
      const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setWalletProvider(wallet);
      const contract = await Tezos.contract.at(
        CONFIG.CONTRACT[connectedNetwork].PLENTY_FARM_CONTRACT
      );
      const storage = await contract.storage();
      const addressDetails = await storage.balances.get(address);
      const balance = await addressDetails.balance;
      const finalBalance = parseFloat(
        (balance.toNumber() / Math.pow(10, 18)).PrecisionMaker(0)
      );
      return {
        success: true,
        balance: finalBalance,
      };
    }
    return {
      success: false,
      error: WALLET_RESP.error,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

export const getBlocksOfUsers = async (someNet, address) => {
  try {
    const options = {
      name: 'Plenty Farm',
    };
    const network = {
      type: CONFIG.WALLET_NETWORK,
    };
    const connectedNetwork = CONFIG.NETWORK;
    const wallet = new BeaconWallet(options);
    await wallet.client.requestPermissions({
      network,
    });
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setWalletProvider(wallet);
    const contract = await Tezos.contract.at(
      CONFIG.CONTRACT[connectedNetwork].PLENTY_FARM_CONTRACT
    );
    const storage = await contract.storage();
    const userDetails = await storage.balances.get(address);
    let blocks = [];
    userDetails.InvestMap.keyMap.forEach((element) => {
      // blocks.push(parseInt(element) + 1);
      let blockDetails = userDetails.InvestMap.get(element);
      let balance = (
        blockDetails.amount.toNumber() / Math.pow(10, 18)
      ).PrecisionMaker(0);
      blocks.push({ number: parseInt(element) + 1, balance });
    });
    return {
      success: true,
      blocks,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

export const getTotalSupplyOfPlentyFarm = async (somenet) => {
  try {
    const options = {
      name: 'Plenty Farm',
    };
    const network = {
      type: CONFIG.WALLET_NETWORK,
    };
    const connectedNetwork = CONFIG.NETWORK;
    const wallet = new BeaconWallet(options);
    await wallet.client.requestPermissions({
      network,
    });
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setWalletProvider(wallet);
    const contract = await Tezos.contract.at(
      CONFIG.CONTRACT[connectedNetwork].PLENTY_FARM_CONTRACT
    );
    const storage = await contract.storage();
    let totalSupply = await storage.totalSupply;
    totalSupply = (totalSupply.toNumber() / Math.pow(10, 18)).PrecisionMaker(0);
    return {
      success: true,
      totalSupply,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};
export const getAPROfPlentyFarm = async (somenet) => {
  try {
    const options = {
      name: 'Plenty Farm',
    };
    const network = {
      type: CONFIG.WALLET_NETWORK,
    };
    const connectedNetwork = CONFIG.NETWORK;
    const wallet = new BeaconWallet(options);
    await wallet.client.requestPermissions({
      network,
    });
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setWalletProvider(wallet);
    const contract = await Tezos.contract.at(
      CONFIG.CONTRACT[connectedNetwork].PLENTY_FARM_CONTRACT
    );
    const storage = await contract.storage();
    let totalSupply = await storage.totalSupply;
    totalSupply = (totalSupply.toNumber() / Math.pow(10, 18)).PrecisionMaker(0);
    totalSupply = parseInt(totalSupply);
    let rewardRate = await storage.rewardRate;
    rewardRate = (rewardRate.toNumber() / Math.pow(10, 18)).PrecisionMaker(18);
    rewardRate = parseFloat(rewardRate);
    let APR = (rewardRate * 1051200) / totalSupply;
    return {
      success: true,
      APR,
      totalSupply,
      rewardRate,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

export const getPlentyPrice = async (somenet) => {
  try {
    const network = {
      type: CONFIG.WALLET_NETWORK,
    };
    const options = {
      name: 'Plenty Farm',
    };
    const connectedNetwork = CONFIG.NETWORK;
    const wallet = new BeaconWallet(options);
    const WALLET_RESP = await CheckIfWalletConnected(wallet, network.type);
    if (WALLET_RESP.success) {
      const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setWalletProvider(wallet);
      const contract = await Tezos.contract.at(
        CONFIG.CONTRACT[connectedNetwork].PLENTY_DEX_CONTRACT
      );
      const storage = await contract.storage();
      let tezPool = await storage.storage.tez_pool;
      tezPool = tezPool.toNumber() / Math.pow(10, 6);
      tezPool = parseFloat(tezPool);
      let tokenPool = await storage.storage.token_pool;
      tokenPool = (tokenPool.toNumber() / Math.pow(10, 18)).PrecisionMaker(6);
      tokenPool = parseFloat(tokenPool);
      let tokenPriceInXtz = tezPool / tokenPool;
      let priceFeed = await axios.get(CONFIG_NEW.API.url);
      let tokenPriceInUsd =
        tokenPriceInXtz * priceFeed.data.market_data.current_price.usd;
      return {
        success: true,
        tokenPriceInUsd,
        tokenPriceInXtz,
      };
    } else {
      return {
        success: false,
      };
    }
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};
