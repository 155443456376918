/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useContext, useEffect, useState } from 'react';
import CycleInfo from '../cycleInfo/cycleInfo';
import { IoIosArrowUp, IoIosArrowDown } from 'react-icons/io';

import AppContext from '../../store/context';

import { harvest } from '../../apis/apis';
import { harvestPool } from '../../apis/pools';
import { NETWORK, TZKT_NODES } from '../../apis/config';
import loaderDone from '../../assets/image/icons/loading-done.gif';
import loader from '../../assets/image/icons/loader.gif';
import NumericLabel from 'react-pretty-numbers';
import PuffLoader from 'react-spinners/PuffLoader';

const CONFIG_V1 = require('../../apis/config');
const CONFIG = require('../../apis/configV2');

const Card = (props) => {
  const ctx = useContext(AppContext);

  useEffect(() => {
    props.handleThrottle();
  }, [props]);

  const confirmHarvest = async (symbol) => {
    try {
      props.setOpenModal(true);
      props.setmodalType('loading');
      const isActive = props.showActive;
      const resp = await harvestPool(symbol, isActive);

      if (resp.success) {
        props.setmodalType('success');
        props.setoperationId(resp.operationId);
        let List = props.list;
        List = await props.PoolRewardsFetcher(List);
        List = await props.PoolBalanceFetcher(List);
        await props.PoolAPRFetcher(List);
      } else {
        props.setOpenModal(false);
      }
    } catch (error) {
      props.setOpenModal(false);
    }
  };

  const modalContentLoading = (
    <Fragment>
      <div
        className="plenty-loading-icon-wrapper"
        style={{
          width: '120px',
          margin: '0 auto',
          height: '100px',
          padding: '24px',
          marginBottom: '12px',
        }}
      >
        <PuffLoader color={'#5C0FAE'} size={70} />
      </div>

      <div
        style={{
          width: '300px',
          margin: '0 auto',
          textAlign: 'center',
        }}
      >
        <p className="available-user-balance-info">
          Please wait while we redeem your harvest
        </p>
      </div>
    </Fragment>
  );
  const modalContentSucess = (
    <Fragment>
      <div
        className="plenty-loading-icon-wrapper"
        style={{ width: '120px', margin: '0 auto' }}
      >
        <img src={loaderDone} className="plenty-loading-icon" />
      </div>
      <p
        className="available-user-balance-info"
        style={{ textAlign: 'center' }}
      >
        Harvest successful!
      </p>
      <div
        style={{
          maxWidth: '600px',
          margin: '0 auto',
          textAlign: 'center',
        }}
      >
        <a
          href={`${TZKT_NODES[NETWORK].replace('api.', '')}${
            props.operationId
          }`}
          target="_blank"
          className="available-user-balance-info transaction-successful-text"
          rel="noreferrer"
        >
          {props.operationId}
          <span
            className="material-icons material-icons-outlined"
            style={{
              fontSize: '12px',
              verticalAlign: 'middle',
              display: 'inline-block',
              marginLeft: '2px',
            }}
          >
            open_in_new
          </span>
        </a>
      </div>
    </Fragment>
  );
  let modal = '';
  if (props.modalType === 'loading') {
    modal = modalContentLoading;
  }
  if (props.modalType === 'success') {
    modal = modalContentSucess;
  }
  const apyCalculate = (apr) => {
    const apy = ((Math.pow(1 + apr / 100 / 365, 365) - 1) * 100).PrecisionMaker(
      0
    );
    return apy;
  };
  var optionMinted = {
    justification: 'C',
    locales: 'en-AU',
    currency: false,
    currencyIndicator: 'AUD',
    percentage: false,
    precision: 0,
    wholenumber: null,
    commafy: true,
    shortFormat: false,
    shortFormatMinValue: 100000,
    shortFormatPrecision: 1,
    title: true,
    cssClass: ['plenty-home-small-card-amount'],
  };

  const poolsTorender = props.data.filter((pool) => {
    if (pool.active === props.showActive) {
      return pool;
    }
  });

  let apy = 0;
  let apr = 0;
  let stakeBtns;
  let harvestBtn;

  return (
    <Fragment>
      {poolsTorender.map((item, index) => {
        if (item.active) {
          apy = apyCalculate(parseInt(item.apr, 10) || 0);
          apr = parseInt(item.apr, 10) || 0;

          apr = apr.toString();

          if (apy.length > 6) {
            apy = `${(
              apyCalculate(parseInt(item.apr, 10) || 0) / 1000000
            ).toFixed(1)}M`;
          }
          if (apr.length > 6) {
            apr = `${((parseInt(item.apr, 10) || 0) / 1000000).toFixed(1)}M`;
          }

          if (item.balance) {
            stakeBtns = (
              <div className="withdrawal-btn-wrapper">
                <button
                  className="withdrawal-btns"
                  onClick={() =>
                    ctx.showCustomModal(
                      'show',
                      'stake',
                      null,
                      item.userBalance,
                      item.title,
                      props.showActive,
                      item.deposit,
                      { ...props }
                    )
                  }
                >
                  +
                </button>
                <button
                  className="withdrawal-btns"
                  disabled={item.balance <= 0}
                  onClick={() => {
                    ctx.showCustomModal(
                      'show',
                      'withdrawal-stake',
                      props.blockToDisplay,
                      item.balance,
                      item.title,
                      props.showActive,
                      item.deposit,
                      { ...props }
                    );
                  }}
                >
                  -
                </button>
              </div>
            );
          } else {
            stakeBtns = (
              <button
                className={
                  ctx.userWalletAddress
                    ? 'token-card-harvest-button token-card-approve-button'
                    : 'token-card-harvest-button token-card-approve-button btn-disabled'
                }
                disabled={!ctx.userWalletAddress}
                data-tooltip={
                  !ctx.userWalletAddress ? 'Please connect your wallet' : ''
                }
                onClick={() =>
                  ctx.showCustomModal('show', 'stake', null, null, item.title, {
                    ...props,
                  })
                }
              >
                Stake
              </button>
            );
          }
        } else {
          if (item.balance) {
            stakeBtns = (
              <div className="withdrawal-btn-wrapper">
                <button className="withdrawal-btns" disabled="disabled">
                  +
                </button>
                <button
                  className="withdrawal-btns"
                  disabled={item.balance <= 0}
                  onClick={() => {
                    ctx.showCustomModal(
                      'show',
                      'withdrawal-stake',
                      props.blockToDisplay,
                      item.balance,
                      item.title,
                      props.showActive,
                      { ...props }
                    );
                  }}
                >
                  -
                </button>
              </div>
            );
          } else {
            stakeBtns = (
              <button
                className={
                  ctx.userWalletAddress
                    ? 'token-card-harvest-button token-card-approve-button'
                    : 'token-card-harvest-button token-card-approve-button btn-disabled'
                }
                disabled="disabled"
              >
                Stake
              </button>
            );
          }
        }
        if (item.earned) {
          harvestBtn = (
            <button
              className={
                ctx.userWalletAddress
                  ? 'token-card-harvest-button'
                  : 'token-card-harvest-button '
              }
              disabled={!ctx.userWalletAddress}
              data-tooltip={
                !ctx.userWalletAddress ? 'Please connect your wallet' : ''
              }
              onClick={() => confirmHarvest(item.title)}
            >
              Harvest
            </button>
          );
        } else {
          harvestBtn = (
            <button
              className="token-card-harvest-button btn-disabled"
              data-tooltip="Stake to harvest"
              disabled="disabled"
            >
              Harvest
            </button>
          );
        }
        return (
          <div className="col-span-1" key={index}>
            <div className="general-card">
              <div
                className="flex justify-center card-top-content"
                style={{ alignItems: 'center' }}
              >
                <div className="token-img-wrapper">
                  <img src={item.img} className="token-img" alt="Token Image" />
                </div>
                <p className="pool-type-name"> {item.title} </p>
              </div>

              <div className="deposit-fee-info-wrapper">
                <div className="apr-earn-wrapper">
                  <div className="plenty-farming-money-desc-wrapper mb-10">
                    <p
                      data-tooltip="Annual Percentage Yield"
                      className="plenty-farming-money-desc"
                      style={{ position: 'relative' }}
                    >
                      APY:{' '}
                    </p>
                    <p className="plenty-farming-money-desc">
                      <span>{apy} %</span>
                    </p>
                  </div>

                  <div className="plenty-farming-money-desc-wrapper mb-10">
                    <p
                      data-tooltip="Annual Percentage Rate"
                      className="plenty-farming-money-desc"
                      style={{ position: 'relative' }}
                    >
                      APR:{' '}
                    </p>
                    <p className="plenty-farming-money-desc">
                      <span
                        className="material-icons material-icons-outlined"
                        onClick={() => {
                          props.setTitle(item.title);
                          ctx.showCustomModal('show', 'apr');
                        }}
                        style={{
                          cursor: 'pointer',
                          position: 'relative',
                        }}
                      >
                        calculate
                      </span>
                      <span>{apr} %</span>
                    </p>
                  </div>

                  <div className="plenty-farming-money-desc-wrapper">
                    <p className="plenty-farming-money-desc">Earn:</p>
                    <p className="plenty-farming-money-desc">
                      {/* <span
                                                className="material-icons material-icons-outlined"
                                                style={{ cursor: 'pointer' }}
                                            >
                                                help_outline
                                            </span> */}
                      {item.earn}
                    </p>
                  </div>
                </div>

                <div className="deposit-withdrawal-fee-info flex justify-between">
                  <div>
                    <p className="plenty-farming-money-desc">Deposit fee</p>
                    <p
                      className="plenty-farming-money-desc"
                      data-tooltip="No deposit fee"
                      style={{
                        position: 'relative',
                        cursor: 'pointer',
                      }}
                    >
                      <span className="material-icons material-icons-outlined">
                        help_outline
                      </span>
                      0 %
                    </p>
                  </div>

                  <div>
                    <p className="plenty-farming-money-desc">Withdrawal fee</p>
                    <p
                      className="plenty-farming-money-desc"
                      onClick={() => {
                        props.setmodalType('');
                        ctx.showCustomModal(
                          'show',
                          'withdrawalFee-pools',
                          null,
                          0,
                          item.title
                        );
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <span className="material-icons material-icons-outlined">
                        help_outline
                      </span>
                      Variable
                    </p>
                  </div>
                </div>

                <div
                  className="plenty-farming-wrapper farm-page"
                  style={{ marginBottom: 0 }}
                >
                  <h6 className="plenty-farming-detail"> PLENTY earned </h6>
                  <div className="plenty-farming-detail-wrapper">
                    <div className="plenty-farming-money-desc-wrapper farming-include-btn">
                      <p className="plenty-farming-money-desc">{item.earned}</p>
                      {harvestBtn}
                    </div>
                  </div>

                  <div
                    className="plenty-farming-detail-wrapper stake-amt-btn"
                    style={{ flexWrap: 'wrap' }}
                  >
                    <div className="flex" style={{ flex: '0 0 100%' }}>
                      <h6 className="plenty-farming-detail">
                        {item.title} staked
                      </h6>
                    </div>
                    <div
                      className="flex justify-between"
                      style={{ flex: '0 0 100%' }}
                    >
                      <div className="plenty-farming-money-desc-wrapper farming-include-btn">
                        <p className="plenty-farming-money-desc ooo">
                          {item.balance}
                        </p>
                      </div>

                      {stakeBtns}
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div>
                {item.hide ? (
                  <>
                    <div className="text-center">
                      <h5
                        className="token-card-detail-hide "
                        onClick={() => props.onHide(index)}
                      >
                        Hide
                        <IoIosArrowUp className="inline ml-1" />
                      </h5>
                    </div>
                    <div className="flex justify-between mt-3">
                      <p className="hidden-content-title">Deposit:</p>
                      <a
                        href="#"
                        className="hidden-content-info"
                        onClick={() => {
                          if (
                            item.title === 'ETHtz' ||
                            item.title === 'USDtz'
                          ) {
                            window.open('https://bridge.tezex.io/', '_blank');
                          } else {
                            const POOLS_DATA =
                              CONFIG.CONTRACT[NETWORK].POOLS[item.title];
                            let TOKEN = POOLS_DATA.TOKEN;
                            if (
                              POOLS_DATA.hasOwnProperty('TOKEN_ID') &&
                              POOLS_DATA.TOKEN_ID > 0
                            ) {
                              TOKEN = TOKEN + '_' + POOLS_DATA.TOKEN_ID;
                            }
                            const URL = `https://quipuswap.com/swap?from=tez&to=${TOKEN}`;
                            window.open(URL, '_blank');
                          }
                        }}
                      >
                        {' '}
                        {item.title}
                        <span
                          className="material-icons material-icons-outlined"
                          style={{
                            fontSize: '14px',
                            verticalAlign: 'middle',
                            display: 'inline-block',
                            marginLeft: '2px',
                          }}
                        >
                          open_in_new
                        </span>
                      </a>
                    </div>

                    <div
                      className="plenty-farming-wrapper farm-page"
                      style={{
                        border: 0,
                        margin: 0,
                        padding: '12px 0',
                      }}
                    >
                      <div className="plenty-farming-detail-wrapper">
                        <div className="available-user-balance-wrapper user-amt-input-wrapper plenty-home-balance">
                          <div>Total Liquidity:</div>
                          <div className="plenty-user-currency-amt-wrapper">
                            <span className="material-icons material-icons-outlined">
                              paid
                            </span>
                            &nbsp;{' '}
                            <NumericLabel params={optionMinted}>
                              {item.liquidity ? item.liquidity : 0}
                            </NumericLabel>
                          </div>
                        </div>
                      </div>
                    </div>
                    <button
                      className="token-card-approve-button"
                      onClick={() => {
                        const CONTRACT =
                          CONFIG.CONTRACT[NETWORK].POOLS[item.title].CONTRACT;
                        const URL = `https://better-call.dev/${CONFIG_V1.WALLET_NETWORK}/${CONTRACT}`;
                        window.open(URL, '_blank');
                      }}
                    >
                      {' '}
                      View on Tezos
                    </button>
                  </>
                ) : (
                  <div className="text-center">
                    <h5
                      className="token-card-detail-hide"
                      onClick={() => props.onDisplay(index)}
                    >
                      Details
                      <IoIosArrowDown className="inline ml-1" />
                    </h5>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      })}
      {props.OpenModal ? (
        <div className="plenty-modal-wrapper">
          <div className="plenty-modal-content-wrapper">
            <div className="plenty-modal-header">
              <button
                className="plenty-close-modal"
                onClick={() => props.setOpenModal(false)}
              >
                <span className="material-icons">close</span>
              </button>
            </div>

            <div className="plenty-modal-content">{modal}</div>
          </div>
        </div>
      ) : (
        ''
      )}
    </Fragment>
  );
};

export default Card;
