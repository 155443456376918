/* eslint-disable jsx-a11y/alt-text */
import Layout from '../../components/layout/layout';
import Card from '../../components/card/card';
import React, { useState, useContext } from 'react';
import { AiOutlineClose } from 'react-icons/ai';

import SwitchButton from '../../components/switchButton/switchButton';

import Modal from 'react-modal';
import PuffLoader from 'react-spinners/PuffLoader';
import * as apis from '../../apis/apis';
import { Menu, Transition } from '@headlessui/react';
import AppContext from '../../store/context';

function Pools(props) {
  const ctx = useContext(AppContext);
  const [dataBalance, setData] = useState({
    balance: 0,
  });
  const [loading, setLoading] = useState(props.loading);
  const [liquidity, setLiquidity] = useState(0);
  const [apr, setApr] = useState(0);
  const [showActive, setShowActive] = useState(true);

  let loadingInfo = null;

  const customStyles = {
    /** modal css */
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      padding: '0px',
      maxWidth: '700px',
      transform: 'translate(-50%, -50%)',
    },
  };

  /** token card hidden detail show or hide */

  function onHide(item) {
    let templist = [...props.list];
    templist[item].hide = false;
    return setList(templist);
  }

  function onDisplay(item) {
    let templist = [...props.list];
    templist[item].hide = true;
    return setList(templist);
  }

  /** modal display and hide function */

  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  /** token detail data */

  const [list, setList] = useState(props.list);

  /** modal detail data  */

  const [modaltable, setModaltable] = useState([
    {
      time: '1d',
      roi: '6.37',
      plenty: '0.91',
    },
    {
      time: '7d',
      roi: '53.87',
      plenty: '	7.69',
    },
    {
      time: '30d',
      roi: '	533.69',
      plenty: '79.19',
    },
    {
      time: '365d(APY)',
      roi: '57.98',
      plenty: '81.09',
    },
  ]);

  const [blocks, setBlock] = useState([]);

  if (loading) {
    const color = '#5C0FAE';
    document.getElementById('body').classList.add('no-scroll');
    loadingInfo = (
      <div className="loading-pools" style={{ zIndex: '999' }}>
        <div className="loader-icon">
          <PuffLoader color={color} size={150} />
        </div>
      </div>
    );
  }

  if (!loading) {
    document.getElementById('body').classList.remove('no-scroll');
  }

  const fetchPoolDataOnActiveStateChange = async () => {
    // let List = props.list;
    // List = await props.PoolRewardsFetcher(List);
    // List = await props.PoolBalanceFetcher(List);
    // await props.PoolAPRFetcher(List);
    await props.DataFetcher();
  };
  return (
    <Layout>
      {loadingInfo}
      <div className="text-center">
        <h3 className="token-page-title">Stake tokens and earn PLENTY</h3>
        <h5 className="token-page-subtitle">
          Withdrawal fee is used to buyback and burn PLENTY
        </h5>
      </div>
      <div className="text-center">
        <SwitchButton
          setShow={props.setShow}
          showActive={props.showActive}
          fetchPoolDataOnActiveStateChange={fetchPoolDataOnActiveStateChange}
        />
      </div>
      <div className="flex pools farm-page-flex">
        <Card
          liquidity={liquidity}
          blockToDisplay={blocks}
          data={props.list}
          dataBalance={dataBalance}
          showCustomModal={props.showCustomModal}
          modalIsOpen={modalIsOpen}
          onHide={onHide}
          onDisplay={onDisplay}
          apr={apr}
          type="pools"
          {...props}
        />
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="flex justify-between modal-header">
          <h3 className="modal-header-title">ROI</h3>
          <AiOutlineClose onClick={closeModal} className="modal-header-close" />
        </div>
        <hr />
        <div className="modal-body ">
          <table className="table-auto w-full">
            <thead className="text-left modal-table-title ">
              <tr>
                <th>TIMEFRAME</th>
                <th>ROI</th>
                <th>PLENTY PER $1000</th>
              </tr>
            </thead>
            <tbody>
              {modaltable.map((item, index) => (
                <tr key={index}>
                  <td>{item.time}</td>
                  <td>{item.roi}%</td>
                  <td>{item.plenty}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <p className="modal-table-detail">
            Calculated based on current rates. Compounding once daily. Rates are
            estimates provided for your convenience only, and by no means
            represent guaranteed returns.
          </p>
        </div>
      </Modal>
    </Layout>
  );
}

export default Pools;
