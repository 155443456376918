import { TezosToolkit } from '@taquito/taquito';
import { BeaconWallet } from '@taquito/beacon-wallet';
import axios from 'axios';
const CONFIG = require('./configV2');
const CONFIG_V1 = require('./config');

export const CheckIfWalletConnected = async (wallet, somenet) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;

    const network = {
      type: connectedNetwork,
    };
    const activeAccount = await wallet.client.getActiveAccount();
    if (!activeAccount) {
      await wallet.client.requestPermissions({
        network,
      });
    }
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

const fetchPoolBalance = async (
  somenet,
  addressOfUser,
  poolContractAddress,
  icon
) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setProvider(CONFIG.RPC_NODES[connectedNetwork]);
    const contract = await Tezos.contract.at(poolContractAddress);
    const storage = await contract.storage();
    const userDetails = await storage.balances.get(addressOfUser);
    let userBalance = userDetails.balance;
    userBalance = (userBalance.toNumber() / Math.pow(10, 18)).PrecisionMaker(3);
    userBalance = parseFloat(userBalance);
    return {
      success: true,
      balance: userBalance,
      symbol: icon,
    };
  } catch (e) {
    return {
      success: false,
      balance: 0,
      symbol: icon,
      error: e,
    };
  }
};

const fetchWalletBalance = async (
  somenet,
  addressOfUser,
  tokenContractAddress,
  icon,
  type,
  token_id
) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setProvider(CONFIG.RPC_NODES[connectedNetwork]);
    const contract = await Tezos.contract.at(tokenContractAddress);
    const storage = await contract.storage();
    let userBalance = 0;
    if (type === 'FA1.2') {
      if (icon === 'ETHtz' || icon === 'USDtz') {
        const userDetails = await storage.ledger.get(addressOfUser);
        let userBalance = userDetails.balance;
        userBalance = (
          userBalance.toNumber() /
          Math.pow(
            10,
            CONFIG.CONTRACT[connectedNetwork].POOLS[icon].TOKEN_DECIMAL
          )
        ).PrecisionMaker(3);
        userBalance = parseFloat(userBalance);
        return {
          success: true,
          balance: userBalance,
          symbol: icon,
        };
      } else {
        const userDetails = await storage.balances.get(addressOfUser);
        let userBalance = userDetails.balance;
        userBalance =
          userBalance.toNumber() /
          Math.pow(
            10,
            CONFIG.CONTRACT[connectedNetwork].POOLS[icon].TOKEN_DECIMAL
          ).PrecisionMaker(3);
        userBalance = parseFloat(userBalance);
        return {
          success: true,
          balance: userBalance,
          symbol: icon,
        };
      }
    } else {
      if (icon === 'hDAO') {
        const userDetails = await storage.ledger.get({
          0: addressOfUser,
          1: token_id,
        });
        userBalance = (
          userDetails.toNumber() /
          Math.pow(
            10,
            CONFIG.CONTRACT[connectedNetwork].POOLS[icon].TOKEN_DECIMAL
          )
        ).PrecisionMaker(3);

        userBalance = parseFloat(userBalance);
        return {
          success: true,
          balance: userBalance,
          symbol: icon,
        };
      } else if (icon === 'WRAP') {
        const userDetails = await storage.assets.ledger.get(addressOfUser);
        let userBalance = userDetails;
        userBalance = (
          userBalance.toNumber() /
          Math.pow(
            10,
            CONFIG.CONTRACT[connectedNetwork].POOLS[icon].TOKEN_DECIMAL
          )
        ).PrecisionMaker(3);
        userBalance = parseFloat(userBalance);
        return {
          success: true,
          balance: userBalance,
          symbol: icon,
        };
      } else {
        const userDetails = await storage.assets.ledger.get({
          0: addressOfUser,
          1: token_id,
        });
        userBalance = (
          userDetails.toNumber() /
          Math.pow(
            10,
            CONFIG.CONTRACT[connectedNetwork].POOLS[icon].TOKEN_DECIMAL
          )
        ).PrecisionMaker(3);

        userBalance = parseFloat(userBalance);
        return {
          success: true,
          balance: userBalance,
          symbol: icon,
        };
      }
    }
  } catch (e) {
    return {
      success: false,
      balance: 0,
      symbol: icon,
      error: e,
    };
  }
};

// export const fetchBalances = async (somenet, addressOfUser) => {
//   try {
//     const promises = [];
//     const balancePromises = [];
//     const connectedNetwork = CONFIG.NETWORK;
//     for (let symbol in CONFIG.CONTRACT[connectedNetwork].POOLS) {
//       promises.push(
//         fetchPoolBalance(
//           connectedNetwork,
//           addressOfUser,
//           CONFIG.CONTRACT[connectedNetwork].POOLS[symbol].CONTRACT,
//           symbol
//         )
//       );
//       balancePromises.push(
//         fetchWalletBalance(
//           connectedNetwork,
//           addressOfUser,
//           CONFIG.CONTRACT[connectedNetwork].POOLS[symbol].TOKEN,
//           symbol,
//           CONFIG.CONTRACT[connectedNetwork].POOLS[symbol].TYPE,
//           CONFIG.CONTRACT[connectedNetwork].POOLS[symbol].TOKEN_ID
//         )
//       );
//     }
//     const response = await Promise.all(promises);
//     const balanceResponse = await Promise.all(balancePromises);
//     return {
//       success: true,
//       response,
//       balanceResponse,
//     };
//   } catch (e) {
//     return {
//       success: false,
//       error: e,
//     };
//   }
// };

export const fetchBalances = async (somenet, addressOfUser, active) => {
  try {
    const promises = [];
    const balancePromises = [];
    const connectedNetwork = CONFIG.NETWORK;
    for (let symbol in CONFIG.CONTRACT[connectedNetwork].POOLS) {
      let contractAddress = null;
      active === true
        ? (contractAddress =
            CONFIG.CONTRACT[connectedNetwork].POOLS[symbol].CONTRACT)
        : (contractAddress =
            CONFIG.CONTRACT[connectedNetwork].POOLS[symbol].INACTIVE);
      promises.push(
        fetchPoolBalance(
          connectedNetwork,
          addressOfUser,
          contractAddress,
          symbol
        )
      );
      balancePromises.push(
        fetchWalletBalance(
          connectedNetwork,
          addressOfUser,
          CONFIG.CONTRACT[connectedNetwork].POOLS[symbol].TOKEN,
          symbol,
          CONFIG.CONTRACT[connectedNetwork].POOLS[symbol].TYPE,
          CONFIG.CONTRACT[connectedNetwork].POOLS[symbol].TOKEN_ID
        )
      );
    }
    const response = await Promise.all(promises);
    const balanceResponse = await Promise.all(balancePromises);
    return {
      success: true,
      response,
      balanceResponse,
    };
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
};

const calculateWithdrwalaFee = (difference, balance, symbol) => {
  let feeStructure = null;
  if (symbol === 'WRAP') {
    feeStructure = [
      {
        block: 4096,
        rate: 25,
      },
      {
        block: 8192,
        rate: 12.5,
      },
      {
        block: 12288,
        rate: 10,
      },
      {
        block: 16384,
        rate: 2,
      },
    ];
  } else {
    feeStructure = [
      {
        block: 4096,
        rate: 25,
      },
      {
        block: 8192,
        rate: 12.5,
      },
      {
        block: 12288,
        rate: 10,
      },
      {
        block: 16384,
        rate: 4,
      },
    ];
  }
  // const feeStructure = [
  //   {
  //     block: 4096,
  //     rate: 25,
  //   },
  //   {
  //     block: 8192,
  //     rate: 12.5,
  //   },
  //   {
  //     block: 12288,
  //     rate: 10,
  //   },
  //   {
  //     block: 16384,
  //     rate: 4,
  //   },
  // ];
  let fee = -1;
  for (let i = 0; i < feeStructure.length; i++) {
    if (difference < feeStructure[i].block) {
      fee = ((balance * feeStructure[i].rate) / 100).PrecisionMaker(2);
      fee = parseFloat(fee);
      break;
    }
  }
  if (fee === -1) {
    fee = (
      (balance * feeStructure[feeStructure.length - 1].rate) /
      100
    ).PrecisionMaker(2);
    fee = parseFloat(fee);
  }
  return fee;
};

// export const getPoolStakes = async (soment, addressOfUser, symbol) => {
//   try {
//     const connectedNetwork = CONFIG.NETWORK;
//     const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
//     Tezos.setProvider(CONFIG.RPC_NODES[connectedNetwork]);
//     const blockData = await axios.get(
//       `${CONFIG.TZKT_NODES[connectedNetwork]}/v1/blocks/count`
//     );

//     const contract = await Tezos.contract.at(
//       CONFIG.CONTRACT[connectedNetwork].POOLS[symbol].CONTRACT
//     );
//     const storage = await contract.storage();
//     const userDetails = await storage.balances.get(addressOfUser);
//     let blocks = [];
//     userDetails.InvestMap.keyMap.forEach((element) => {
//       let blockDetails = userDetails.InvestMap.get(element);
//       let blockAtStake = blockDetails.level.toNumber();
//       blockAtStake = parseInt(blockAtStake);
//       let difference = blockData.data - blockAtStake;
//       let balance = (
//         blockDetails.amount.toNumber() /
//         Math.pow(
//           10,
//           CONFIG.CONTRACT[connectedNetwork].POOLS[symbol].TOKEN_DECIMAL
//         )
//       ).PrecisionMaker(3);
//       balance = parseFloat(balance);
//       let withdrawalFee = calculateWithdrwalaFee(difference, balance);
//       blocks.push({
//         number: parseInt(element) + 1,
//         balance,
//         withdrawalFee,
//       });
//     });
//     return {
//       success: true,
//       stakes: blocks,
//     };
//   } catch (e) {
//     return {
//       success: false,
//       error: e,
//     };
//   }
// };

export const getPoolStakes = async (soment, addressOfUser, symbol, active) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setProvider(CONFIG.RPC_NODES[connectedNetwork]);
    const blockData = await axios.get(
      `${CONFIG.TZKT_NODES[connectedNetwork]}/v1/blocks/count`
    );
    let contractAddress = null;
    if (active === true) {
      contractAddress =
        CONFIG.CONTRACT[connectedNetwork].POOLS[symbol].CONTRACT;
    } else {
      contractAddress =
        CONFIG.CONTRACT[connectedNetwork].POOLS[symbol].INACTIVE;
    }
    const contract = await Tezos.contract.at(contractAddress);
    const storage = await contract.storage();
    const userDetails = await storage.balances.get(addressOfUser);
    let blocks = [];
    userDetails.InvestMap.keyMap.forEach((element) => {
      let blockDetails = userDetails.InvestMap.get(element);
      let blockAtStake = blockDetails.level.toNumber();
      blockAtStake = parseInt(blockAtStake);
      let difference = blockData.data - blockAtStake;
      let balance = (
        blockDetails.amount.toNumber() /
        Math.pow(
          10,
          CONFIG.CONTRACT[connectedNetwork].POOLS[symbol].TOKEN_DECIMAL
        )
      ).PrecisionMaker(3);
      balance = parseFloat(balance);
      let withdrawalFee = calculateWithdrwalaFee(difference, balance, symbol);
      blocks.push({
        number: parseInt(element) + 1,
        balance,
        withdrawalFee,
      });
    });
    return {
      success: true,
      stakes: blocks,
    };
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
};

export const poolStakesFetcher = async (soment, addressOfUser) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const API_RESPONSE = await getPoolStakes(
      connectedNetwork,
      addressOfUser,
      'PLENTY'
    );
    return {
      success: true,
      data: API_RESPONSE,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

const fetchEarnedTokens = async (
  somenent,
  addressOfUser,
  poolContractAddress,
  identifier,
  currentBlock,
  active
) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    let contractAddress = null;
    if (active === true) {
      contractAddress =
        CONFIG.CONTRACT[connectedNetwork].POOLS[identifier].CONTRACT;
    } else {
      contractAddress =
        CONFIG.CONTRACT[connectedNetwork].POOLS[identifier].INACTIVE;
    }
    const plentyContractInstance = await Tezos.contract.at(contractAddress);
    const storage = await plentyContractInstance.storage();
    const userDetails = await storage.balances.get(addressOfUser);
    let balance = userDetails ? userDetails.balance.toNumber() : 0;
    var calculatedRewards = 0;
    // get block level
    let block = await axios.get(
      `${CONFIG.RPC_NODES[connectedNetwork]}chains/main/blocks/head`
    );
    let presentBlocklevel = block.data.header.level;
    let periodFinish = await storage.periodFinish.toNumber();
    var lastUpdate = 0;
    if (presentBlocklevel > periodFinish) {
      lastUpdate = periodFinish;
    } else {
      lastUpdate = presentBlocklevel;
    }
    var rewardPerTokenStored = await storage.rewardPerTokenStored.toNumber();
    var rewardRate = await storage.rewardRate.toNumber();
    var totalSupply = await storage.totalSupply.toNumber();
    if (totalSupply !== 0) {
      var res = 0;
      var lut = await storage.lastUpdateTime.toNumber();
      res += lastUpdate - lut;
      res = res * Math.pow(10, 18) * rewardRate;
      res = res / totalSupply;
      rewardPerTokenStored += res;
    }
    var lastUpdateTime = lastUpdate;
    var rewards = userDetails.rewards.toNumber();
    var userRewardPerTokenPaid = userDetails.userRewardPerTokenPaid.toNumber();
    if (
      addressOfUser !==
      CONFIG.CONTRACT[connectedNetwork].POOLS[identifier].CONTRACT
    ) {
      rewards +=
        (balance * (rewardPerTokenStored - userRewardPerTokenPaid)) /
        Math.pow(10, 18);
      userRewardPerTokenPaid = rewardPerTokenStored;
    }
    var lastTimeReward = 0;
    if (presentBlocklevel > periodFinish) {
      lastTimeReward = periodFinish;
    } else {
      lastTimeReward = presentBlocklevel;
    }
    var rewardPerToken = rewardPerTokenStored;
    if (totalSupply !== 0) {
      var diff = lastTimeReward - lastUpdateTime;
      diff =
        (diff * rewardRate * Math.pow(10, 18)) / Math.pow(10, 18) +
        rewardPerTokenStored;
      rewardPerToken = diff;
      rewardPerToken = diff;
    }
    calculatedRewards = balance;
    calculatedRewards *= rewardPerToken - userRewardPerTokenPaid;
    calculatedRewards = calculatedRewards / Math.pow(10, 18) + rewards;
    calculatedRewards = (calculatedRewards / Math.pow(10, 18)).PrecisionMaker(
      5
    );
    calculatedRewards = parseFloat(calculatedRewards);
    if (calculatedRewards < 0) {
      calculatedRewards = 0;
    }
    return {
      success: true,
      symbol: identifier,
      reward: calculatedRewards,
    };
  } catch (error) {
    return {
      success: false,
      symbol: identifier,
      reward: 0,
    };
  }
};

export const poolsRewards = async (somenet, addressOfUser, active) => {
  try {
    const promises = [];
    const connectedNetwork = CONFIG.NETWORK;
    const blockData = await axios.get(
      `${CONFIG.TZKT_NODES[connectedNetwork]}/v1/blocks/count`
    );

    for (let symbol in CONFIG.CONTRACT[connectedNetwork].POOLS) {
      let contractAddress = null;
      if (active === true) {
        contractAddress =
          CONFIG.CONTRACT[connectedNetwork].POOLS[symbol].CONTRACT;
      } else {
        contractAddress =
          CONFIG.CONTRACT[connectedNetwork].POOLS[symbol].INACTIVE;
      }

      promises.push(
        fetchEarnedTokens(
          connectedNetwork,
          addressOfUser,
          contractAddress,
          symbol,
          blockData.data,
          active
        )
      );
    }
    const response = await Promise.all(promises);
    return {
      success: true,
      response,
    };
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
};

// export const unstake = async (somenet, amount, mapKey, symbol) => {
//     try {
//         const network = {
//             type: CONFIG.WALLET_NETWORK,
//         };
//         const options = {
//             name: 'Plenty Farm',
//         };
//         const connectedNetwork = CONFIG.NETWORK;
//         const wallet = new BeaconWallet(options);
//         const WALLET_RESP = await CheckIfWalletConnected(wallet, network.type);
//         if (WALLET_RESP.success) {
//             const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
//             Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
//             Tezos.setWalletProvider(wallet);

//             const contractInstance = await Tezos.wallet.at(
//                 CONFIG.CONTRACT[connectedNetwork].POOLS[symbol].CONTRACT
//             );
//             let tokenAmount =
//                 amount *
//                 Math.pow(
//                     10,
//                     CONFIG.CONTRACT[connectedNetwork].POOLS[symbol]
//                         .TOKEN_DECIMAL
//                 );
//             const operation = await contractInstance.methods
//                 .unstake(tokenAmount, mapKey)
//                 .send();
//             await operation.confirmation().then(() => operation.opHash);
//             return {
//                 success: true,
//                 operationId: operation.opHash,
//             };
//         } else {
//             return {
//                 success: false,
//                 error: WALLET_RESP.error,
//             };
//         }
//     } catch (error) {
//
//         return {
//             success: false,
//             error,
//         };
//     }
// };

export const unstake = async (somenet, amount, mapKey, symbol, active) => {
  try {
    const network = {
      type: CONFIG.WALLET_NETWORK,
    };
    const options = {
      name: 'Plenty Farm',
    };
    const connectedNetwork = CONFIG.NETWORK;
    const wallet = new BeaconWallet(options);
    const WALLET_RESP = await CheckIfWalletConnected(wallet, network.type);
    if (WALLET_RESP.success) {
      const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setWalletProvider(wallet);
      let contractAddress = null;
      if (active === true) {
        contractAddress =
          CONFIG.CONTRACT[connectedNetwork].POOLS[symbol].CONTRACT;
      } else {
        contractAddress =
          CONFIG.CONTRACT[connectedNetwork].POOLS[symbol].INACTIVE;
      }
      const contractInstance = await Tezos.wallet.at(contractAddress);
      let tokenAmount =
        amount *
        Math.pow(
          10,
          CONFIG.CONTRACT[connectedNetwork].POOLS[symbol].TOKEN_DECIMAL
        );
      const operation = await contractInstance.methods
        .unstake(tokenAmount, mapKey)
        .send();
      await operation.confirmation().then(() => operation.opHash);
      return {
        success: true,
        operationId: operation.opHash,
      };
    } else {
      return {
        success: false,
        error: WALLET_RESP.error,
      };
    }
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

export const stake = async (somenet, amount, poolIdentifier) => {
  try {
    const network = {
      type: CONFIG.WALLET_NETWORK,
    };
    const options = {
      name: 'Plenty Farm',
    };
    const connectedNetwork = CONFIG.NETWORK;

    const wallet = new BeaconWallet(options);
    const WALLET_RESP = await CheckIfWalletConnected(wallet, network.type);
    if (WALLET_RESP.success) {
      const account = await wallet.client.getActiveAccount();
      const userAddress = account.address;
      const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setWalletProvider(wallet);
      const poolContractInstance = await Tezos.wallet.at(
        CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier].CONTRACT
      );
      const tokenContractInstance = await Tezos.wallet.at(
        CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier].TOKEN
      );
      let tokenAmount =
        amount *
        Math.pow(
          10,
          CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier].TOKEN_DECIMAL
        );
      let batch = null;
      if (
        CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier].TYPE === 'FA1.2'
      ) {
        batch = await Tezos.wallet
          .batch()
          .withContractCall(
            tokenContractInstance.methods.approve(
              CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier].CONTRACT,
              tokenAmount
            )
          )
          .withContractCall(poolContractInstance.methods.stake(tokenAmount));
      } else {
        batch = Tezos.wallet
          .batch()
          .withContractCall(
            tokenContractInstance.methods.update_operators([
              {
                add_operator: {
                  owner: userAddress,
                  operator:
                    CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier]
                      .CONTRACT,
                  token_id:
                    CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier]
                      .TOKEN_ID,
                },
              },
            ])
          )
          .withContractCall(poolContractInstance.methods.stake(tokenAmount))
          .withContractCall(
            tokenContractInstance.methods.update_operators([
              {
                remove_operator: {
                  owner: userAddress,
                  operator:
                    CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier]
                      .CONTRACT,
                  token_id:
                    CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier]
                      .TOKEN_ID,
                },
              },
            ])
          );
      }
      const batchOperation = await batch.send();
      await batchOperation.confirmation().then(() => batchOperation.opHash);
      return {
        success: true,
        operationId: batchOperation.opHash,
      };
    } else {
      return {
        success: true,
        error: WALLET_RESP.error,
      };
    }
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

export const harvestPool = async (poolIdentifier, active) => {
  try {
    const connectedNetwork = CONFIG_V1.NETWORK;
    const network = {
      type: CONFIG.WALLET_NETWORK,
    };
    const options = {
      name: 'Plenty Farm',
    };
    let contractAddress = null;
    if (active === true) {
      contractAddress =
        CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier].CONTRACT;
    } else {
      contractAddress =
        CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier].INACTIVE;
    }
    const wallet = new BeaconWallet(options);
    const WALLET_RESP = await CheckIfWalletConnected(wallet, network.type);
    if (WALLET_RESP.success) {
      const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setWalletProvider(wallet);
      const contractInstance = await Tezos.wallet.at(contractAddress);
      const operation = await contractInstance.methods.GetReward(1).send();
      await operation.confirmation().then(() => operation.opHash);
      return {
        success: true,
        operationId: operation.opHash,
      };
    } else {
      return {
        success: false,
        error: WALLET_RESP.error,
      };
    }
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

const getPriceInUSD = async (contractAddress, decimal, somenet) => {
  try {
    let connectedNetwork = CONFIG_V1.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    const dexInstance = await Tezos.contract.at(contractAddress);
    const dexStorage = await dexInstance.storage();
    let tezPool = await dexStorage.storage.tez_pool.toNumber();
    let tokenPool = await dexStorage.storage.token_pool.toNumber();
    let tokenPriceInXtz = Math.pow(10, decimal) * tezPool * 1000;
    tokenPriceInXtz = tokenPriceInXtz / (tokenPool - Math.pow(10, decimal));
    tokenPriceInXtz = tokenPriceInXtz / 997;
    tokenPriceInXtz = tokenPriceInXtz / Math.pow(10, 6);
    let priceFeed = await axios.get(CONFIG.API.url);
    priceFeed = priceFeed.data.market_data.current_price.usd;
    let tokenPriceInUSD = tokenPriceInXtz * priceFeed;
    return {
      success: true,
      tokenPriceInUSD,
    };
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
};
const getRoiTableForPool = async (poolIdentifier) => {
  try {
    const connectedNetwork = CONFIG_V1.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    const plentyContractInstance = await Tezos.contract.at(
      CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier].CONTRACT
    );
    const storage = await plentyContractInstance.storage();
    let totalSupply = await storage.totalSupply;
    totalSupply = (totalSupply.toNumber() / Math.pow(10, 18)).PrecisionMaker(0);
    totalSupply = parseInt(totalSupply);
    let rewardRate = await storage.rewardRate;
    rewardRate = (rewardRate.toNumber() / Math.pow(10, 18)).PrecisionMaker(18);
    rewardRate = parseFloat(rewardRate);
    let priceOfPlentyInUSD = await getPriceInUSD(
      CONFIG.CONTRACT[connectedNetwork].POOLS['PLENTY'].DEX,
      CONFIG.CONTRACT[connectedNetwork].POOLS['PLENTY'].TOKEN_DECIMAL,
      connectedNetwork
    );
    let priceOfStakeTokenInUsd = await getPriceInUSD(
      CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier].DEX,
      CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier].TOKEN_DECIMAL,
      connectedNetwork
    );
    let DPY =
      (rewardRate * 2880 * priceOfPlentyInUSD.tokenPriceInUSD) /
      (totalSupply * priceOfStakeTokenInUsd.tokenPriceInUSD);
    DPY = DPY * 100;
    let intervalList = [1, 7, 30, 365];
    let roiTable = [];
    for (const interval of intervalList) {
      roiTable.push({
        roi: DPY * interval,
        PlentyPer1000dollar:
          (10 * DPY * interval) / priceOfPlentyInUSD.tokenPriceInUSD,
      });
    }
    return {
      success: true,
      roiTable,
      totalSupply,
      rewardRate,
      title: poolIdentifier,
    };
  } catch (error) {
    console.error(error);
    return {
      success: false,
      error,
    };
  }
};
export const getROIOfAllPools = async (somenet) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    let promises = [];
    for (let identifier in CONFIG.CONTRACT[connectedNetwork].POOLS) {
      promises.push(getRoiTableForPool(identifier));
    }
    const responses = await Promise.all(promises);
    return {
      success: true,
      roiOfPools: responses,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

const fetchTokensPrice = async () => {
  try {
    let response = await axios.get('https://api.teztools.io/token/prices');
    response = response.data;
    return {
      success: true,
      response,
    };
  } catch (error) {
    return {
      success: false,
      response: {},
    };
  }
};

const getDataFromPoolStakingContract = async (
  decimal,
  contractAddress,
  status,
  type,
  symbol,
  Tezos
) => {
  try {
    const tokenContractInstance = await Tezos.contract.at(contractAddress);
    const tokenContractStorage = await tokenContractInstance.storage();

    let totalSupply = await tokenContractStorage.totalSupply;
    totalSupply = (
      totalSupply.toNumber() / Math.pow(10, decimal)
    ).PrecisionMaker(3);
    totalSupply = parseFloat(totalSupply);

    let rewardRate = await tokenContractStorage.rewardRate;
    rewardRate = (rewardRate.toNumber() / Math.pow(10, decimal)).PrecisionMaker(
      3
    );
    rewardRate = parseFloat(rewardRate);
    return {
      success: true,
      totalSupply,
      rewardRate,
      type,
      symbol,
      status,
    };
  } catch (error) {
    return {
      success: false,
      totalSupply: 0,
      rewardRate: 0,
      type,
      symbol,
      status,
    };
  }
};

const getXTZPriceInUSD = async () => {
  try {
    const priceResponse = await axios.get(
      'https://api.coingecko.com/api/v3/coins/tezos?localization=false&tickers=false&community_data=false&developer_data=false&sparkline=false'
    );
    const priceOfXtzInUsd = priceResponse.data.market_data.current_price.usd;

    return {
      success: true,
      priceOfXtzInUsd,
    };
  } catch (error) {
    return {
      success: false,
      priceOfXtzInUsd: 0,
      error,
    };
  }
};

export const fetchPoolsData = async () => {
  try {
    let promises = [];
    let intervalList = [1, 7, 30, 365];
    const tokens = [
      'PLENTY',
      'USDtz',
      'ETHtz',
      'hDAO',
      'wMATIC',
      'wLINK',
      'WRAP',
    ];
    const tokenAddress = {
      PLENTY:{
        contractAddress : 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
      },
      USDtz:{
        contractAddress : 'KT1LN4LPSqTMS7Sd2CJw4bbDGRkMv2t68Fy9',
      },
      ETHtz:{
        contractAddress : 'KT19at7rQUvyjxnZ2fBv7D9zc8rkyG7gAoU8',
      },
      hDAO:{
        contractAddress : 'KT1AFA2mwNUMNd4SsujE1YYp29vd8BZejyKW',
      },
      wMATIC:{
        contractAddress : 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
      },
      wLINK:{
        contractAddress : 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
      },
      KALAM:{
        contractAddress : 'KT1A5P4ejnLix13jtadsfV9GCnXLMNnab8UT',
      },
      WRAP:{
        contractAddress : 'KT1LRboPna9yQY9BrjtQYDS1DVxhKESK4VVd',
      },
    };
    let tokenPrice = {};
    let poolsData = {};

    const connectedNetwork = CONFIG.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);

    promises.push(fetchTokensPrice());
    promises.push(getXTZPriceInUSD());
    for (let poolIdentifier in CONFIG.CONTRACT[connectedNetwork].POOLS) {
      promises.push(
        getDataFromPoolStakingContract(
          CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier].DECIMAL,
          CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier].CONTRACT,
          'active',
          'POOL',
          poolIdentifier,
          Tezos
        )
      );
    }

    const response = await Promise.all(promises);

    const tokenPriceResponse = response[0].response;
    const priceOfXtzInUsd = response[1].priceOfXtzInUsd;

    for (let i in tokenPriceResponse.contracts) {
      if (tokens.includes(tokenPriceResponse.contracts[i].symbol)) {
        if(tokenAddress[tokenPriceResponse.contracts[i].symbol].contractAddress === tokenPriceResponse.contracts[i].tokenAddress)
        {
          
        tokenPrice[tokenPriceResponse.contracts[i].symbol] =
          tokenPriceResponse.contracts[i].currentPrice;
        }
      }
    }
    
    for (let i = 2; i < response.length; i++) {
      poolsData[response[i].symbol] = {};
      let APR =
        (response[i].rewardRate * 1051200 * tokenPrice['PLENTY']) /
        (response[i].totalSupply * tokenPrice[response[i].symbol]);
      APR = APR * 100;

      let DPY =
        (response[i].rewardRate * 2880 * tokenPrice['PLENTY']) /
        (response[i].totalSupply * tokenPrice[response[i].symbol]);
      DPY = DPY * 100;

      poolsData[response[i].symbol].APR = APR;

      let roiTable = [];

      for (let interval of intervalList) {
        roiTable.push({
          roi: DPY * interval,
          PlentyPer1000dollar:
            (10 * DPY * interval) / (tokenPrice['PLENTY'] * priceOfXtzInUsd),
        });
      }

      let totalLiquidty =
        response[i].totalSupply *
        tokenPrice[response[i].symbol] *
        priceOfXtzInUsd;

      poolsData[response[i].symbol].APR = APR;
      poolsData[response[i].symbol].roiTable = roiTable;
      poolsData[response[i].symbol].totalLiquidty = totalLiquidty;
    }
    return {
      success: true,
      poolsData,
    };
  } catch (error) {
    return {
      success: false,
      poolsData: {},
      error,
    };
  }
};
