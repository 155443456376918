import { TezosToolkit } from '@taquito/taquito';
import { BeaconWallet } from '@taquito/beacon-wallet';
import axios from 'axios';
const CONFIG = require('./configV2');
const CONFIG_V1 = require('./config');

export const stakeLocker = async (amount, identifier) => {
  try {
    const network = {
      type: CONFIG.WALLET_NETWORK,
    };
    const options = {
      name: 'Plenty Farm',
    };
    const connectedNetwork = CONFIG.NETWORK;
    const wallet = new BeaconWallet(options);
    const WALLET_RESP = await CheckIfWalletConnected(wallet, network.type);
    if (WALLET_RESP.success) {
      const account = await wallet.client.getActiveAccount();
      const userAddress = account.address;
      const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setWalletProvider(wallet);
      const stakingContractInstance = await Tezos.wallet.at(
        CONFIG.CONTRACT[connectedNetwork].LOCKERS[identifier].STAKING_CONTRACT
      );

      const tokenContractInstance = await Tezos.wallet.at(
        CONFIG.CONTRACT[connectedNetwork].LOCKERS[identifier].STAKING_TOKEN
      );
      let tokenAmount =
        amount *
        Math.pow(
          10,
          CONFIG.CONTRACT[connectedNetwork].LOCKERS[identifier]
            .STAKING_TOKEN_DECIMAL
        );
      let batch = null;
      if (
        CONFIG.CONTRACT[connectedNetwork].LOCKERS[identifier].TYPE === 'FA1.2'
      ) {
        batch = await Tezos.wallet
          .batch()
          .withContractCall(
            tokenContractInstance.methods.approve(
              CONFIG.CONTRACT[connectedNetwork].LOCKERS[identifier]
                .STAKING_CONTRACT,
              tokenAmount
            )
          )
          .withContractCall(stakingContractInstance.methods.stake(tokenAmount));
      } else {
        batch = Tezos.wallet
          .batch()
          .withContractCall(
            tokenContractInstance.methods.update_operators([
              {
                add_operator: {
                  owner: userAddress,
                  operator:
                    CONFIG.CONTRACT[connectedNetwork].LOCKERS[identifier]
                      .STAKING_CONTRACT,
                  token_id:
                    CONFIG.CONTRACT[connectedNetwork].POOLS[identifier]
                      .TOKEN_ID,
                },
              },
            ])
          )
          .withContractCall(stakingContractInstance.methods.stake(tokenAmount))
          .withContractCall(
            tokenContractInstance.methods.update_operators([
              {
                remove_operator: {
                  owner: userAddress,
                  operator:
                    CONFIG.CONTRACT[connectedNetwork].LOCKERS[identifier]
                      .STAKING_CONTRACT,
                  token_id:
                    CONFIG.CONTRACT[connectedNetwork].POOLS[identifier]
                      .TOKEN_ID,
                },
              },
            ])
          );
      }
      const batchOperation = await batch.send();
      await batchOperation.confirmation().then(() => batchOperation.opHash);
      return {
        success: true,
        operationId: batchOperation.opHash,
      };
    } else {
      return {
        success: true,
        error: WALLET_RESP.error,
      };
    }
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

export const CheckIfWalletConnected = async (wallet, somenet) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const network = {
      type: connectedNetwork,
    };
    const activeAccount = await wallet.client.getActiveAccount();
    if (!activeAccount) {
      await wallet.client.requestPermissions({
        network,
      });
    }
    return {
      success: true,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

const fetchWalletBalance = async (
  somenet,
  addressOfUser,
  tokenContractAddress,
  icon,
  type,
  token_id
) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setProvider(CONFIG.RPC_NODES[connectedNetwork]);
    const contract = await Tezos.contract.at(tokenContractAddress);
    const storage = await contract.storage();
    let userBalance = 0;
    if (type === 'FA1.2') {
      if (icon === 'ETHtz' || icon === 'USDtz') {
        const userDetails = await storage.ledger.get(addressOfUser);
        let userBalance = userDetails.balance;
        userBalance = (
          userBalance.toNumber() /
          Math.pow(
            10,
            CONFIG.CONTRACT[connectedNetwork].POOLS[icon].TOKEN_DECIMAL
          )
        ).PrecisionMaker(3);
        userBalance = parseFloat(userBalance);
        return {
          success: true,
          balance: userBalance,
          symbol: icon,
        };
      } else {
        const userDetails = await storage.balances.get(addressOfUser);
        let userBalance = userDetails.balance;
        userBalance =
          userBalance.toNumber() /
          Math.pow(
            10,
            CONFIG.CONTRACT[connectedNetwork].LOCKERS[icon]
              .STAKING_TOKEN_DECIMAL
          ).PrecisionMaker(3);
        userBalance = parseFloat(userBalance);
        return {
          success: true,
          balance: userBalance,
          symbol: icon,
        };
      }
    } else {
      if (icon === 'hDAO') {
        const userDetails = await storage.ledger.get({
          0: addressOfUser,
          1: token_id,
        });
        userBalance = (
          userDetails.toNumber() /
          Math.pow(
            10,
            CONFIG.CONTRACT[connectedNetwork].POOLS[icon].TOKEN_DECIMAL
          )
        ).PrecisionMaker(3);
        userBalance = parseFloat(userBalance);
        return {
          success: true,
          balance: userBalance,
          symbol: icon,
        };
      } else {
        const userDetails = await storage.assets.ledger.get({
          0: addressOfUser,
          1: token_id,
        });
        userBalance = (
          userDetails.toNumber() /
          Math.pow(
            10,
            CONFIG.CONTRACT[connectedNetwork].POOLS[icon].TOKEN_DECIMAL
          )
        ).PrecisionMaker(3);
        userBalance = parseFloat(userBalance);
        return {
          success: true,
          balance: userBalance,
          symbol: icon,
        };
      }
    }
  } catch (e) {
    return {
      success: false,
      balance: 0,
      symbol: icon,
      error: e,
    };
  }
};
const fetchLockerBalance = async (
  somenet,
  addressOfUser,
  poolContractAddress,
  icon
) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setProvider(CONFIG.RPC_NODES[connectedNetwork]);
    const contract = await Tezos.contract.at(poolContractAddress);
    const storage = await contract.storage();
    const userDetails = await storage.balances.get(addressOfUser);
    let userBalance = userDetails.balance;
    userBalance = (
      userBalance.toNumber() /
      Math.pow(
        10,
        CONFIG.CONTRACT[connectedNetwork].LOCKERS[icon].REWARD_TOKEN_DECIMAL
      )
    ).PrecisionMaker(3);
    userBalance = parseFloat(userBalance);
    return {
      success: true,
      balance: userBalance,
      symbol: icon,
    };
  } catch (e) {
    return {
      success: false,
      balance: 0,
      symbol: icon,
      error: e,
    };
  }
};
export const fetchBalancesOfLockers = async (addressOfUser) => {
  try {
    const promises = [];
    const balancePromises = [];
    const connectedNetwork = CONFIG.NETWORK;
    for (let symbol in CONFIG.CONTRACT[connectedNetwork].LOCKERS) {
      let contractAddress =
        CONFIG.CONTRACT[connectedNetwork].LOCKERS[symbol].STAKING_CONTRACT;
      promises.push(
        fetchLockerBalance(
          connectedNetwork,
          addressOfUser,
          contractAddress,
          symbol
        )
      );
      balancePromises.push(
        fetchWalletBalance(
          connectedNetwork,
          addressOfUser,
          CONFIG.CONTRACT[connectedNetwork].LOCKERS[symbol].STAKING_TOKEN,
          symbol,
          CONFIG.CONTRACT[connectedNetwork].LOCKERS[symbol].TYPE,
          CONFIG.CONTRACT[connectedNetwork].LOCKERS[symbol].TOKEN_ID
        )
      );
    }
    const response = await Promise.all(promises);
    const balanceResponse = await Promise.all(balancePromises);
    return {
      success: true,
      response,
      balanceResponse,
    };
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
};

export const fetchAllEarnedTokens = async (addressOfUser) => {
  try {
    let connectedNetwork = CONFIG.NETWORK;
    let promises = [];
    for (let symbol in CONFIG.CONTRACT[connectedNetwork].LOCKERS) {
      promises.push(fetchEarnedTokens(addressOfUser, symbol));
    }
    let response = await Promise.all(promises);
    return {
      success: true,
      response,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

const fetchEarnedTokens = async (addressOfUser, identifier) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    let contractAddress =
      CONFIG.CONTRACT[connectedNetwork].LOCKERS[identifier].STAKING_CONTRACT;
    const plentyContractInstance = await Tezos.contract.at(contractAddress);
    const storage = await plentyContractInstance.storage();
    const userDetails = await storage.balances.get(addressOfUser);
    let balance = userDetails ? userDetails.balance.toNumber() : 0;
    let userRewardPerTokenPaid = userDetails
      ? userDetails.userRewardPerTokenPaid.toNumber()
      : 0;
    let rewards = userDetails.rewards.toNumber();
    var calculatedRewards = 0;
    // get block level
    let block = await axios.get(
      `${CONFIG.RPC_NODES[connectedNetwork]}chains/main/blocks/head`
    );
    let rewardTokenDecimal =
      CONFIG.CONTRACT[connectedNetwork].LOCKERS[identifier]
        .REWARD_TOKEN_DECIMAL;

    let currentBlockLevel = block.data.header.level;
    let periodFinish = await storage.periodFinish.toNumber();
    let lastUpdateTime = await storage.lastUpdateTime.toNumber();
    let rewardRate = storage.rewardRate.toNumber();
    let rewardPerTokenStored = storage.rewardPerTokenStored.toNumber();
    let totalSupply = storage.totalSupply.toNumber();

    let rewardPerToken = Math.min(currentBlockLevel, periodFinish);
    rewardPerToken = rewardPerToken - lastUpdateTime;
    rewardPerToken *= rewardRate * Math.pow(10, rewardTokenDecimal);
    rewardPerToken = rewardPerToken / totalSupply + rewardPerTokenStored;

    let totalRewards = balance * (rewardPerToken - userRewardPerTokenPaid);

    totalRewards = totalRewards / Math.pow(10, rewardTokenDecimal) + rewards;
    totalRewards = totalRewards / Math.pow(10, rewardTokenDecimal);
    totalRewards = totalRewards.PrecisionMaker(5);

    calculatedRewards = totalRewards;
    if (calculatedRewards < 0) {
      calculatedRewards = 0;
    }

    // var lastUpdate = 0;
    // if (presentBlocklevel > periodFinish) {
    //   lastUpdate = periodFinish;
    // } else {
    //   lastUpdate = presentBlocklevel;
    // }
    // var rewardPerTokenStored = await storage.rewardPerTokenStored.toNumber();
    // var rewardRate = await storage.rewardRate.toNumber();
    // var totalSupply = await storage.totalSupply.toNumber();
    // if (totalSupply !== 0) {
    //   var res = 0;
    //   var lut = await storage.lastUpdateTime.toNumber();
    //   res += lastUpdate - lut;
    //   res = res * Math.pow(10, 10) * rewardRate;
    //   res = res / totalSupply;
    //   rewardPerTokenStored += res;
    // }
    // var lastUpdateTime = lastUpdate;
    // var rewards = userDetails.rewards.toNumber();
    // var userRewardPerTokenPaid = userDetails.userRewardPerTokenPaid.toNumber();
    // if (
    //   addressOfUser !==
    //   CONFIG.CONTRACT[connectedNetwork].LOCKERS[identifier].STAKING_CONTRACT
    // ) {
    //   rewards +=
    //     (balance * (rewardPerTokenStored - userRewardPerTokenPaid)) /
    //     Math.pow(10, 10);
    //   userRewardPerTokenPaid = rewardPerTokenStored;
    // }
    // var lastTimeReward = 0;
    // if (presentBlocklevel > periodFinish) {
    //   lastTimeReward = periodFinish;
    // } else {
    //   lastTimeReward = presentBlocklevel;
    // }
    // var rewardPerToken = rewardPerTokenStored;
    // let rewardTokenDecimal =
    //   CONFIG.CONTRACT[connectedNetwork].LOCKERS[identifier]
    //     .REWARD_TOKEN_DECIMAL;
    // if (totalSupply !== 0) {
    //   var diff = lastTimeReward - lastUpdateTime;
    //   diff =
    //     (diff * rewardRate * Math.pow(10, rewardTokenDecimal)) /
    //       Math.pow(10, rewardTokenDecimal) +
    //     rewardPerTokenStored;
    //   rewardPerToken = diff;
    //   rewardPerToken = diff;
    // }
    // calculatedRewards = balance;
    // calculatedRewards *= rewardPerToken - userRewardPerTokenPaid;
    // calculatedRewards =
    //   calculatedRewards / Math.pow(10, rewardTokenDecimal) + rewards;
    // calculatedRewards = (
    //   calculatedRewards / Math.pow(10, rewardTokenDecimal)
    // ).PrecisionMaker(5);
    return {
      success: true,
      symbol: identifier,
      reward: calculatedRewards,
    };
  } catch (error) {
    return {
      success: false,
      symbol: identifier,
      reward: 0,
    };
  }
};

export const harvestLocker = async (identifier) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const network = {
      type: CONFIG.WALLET_NETWORK,
    };
    const options = {
      name: 'Plenty Farm',
    };
    let contractAddress =
      CONFIG.CONTRACT[connectedNetwork].LOCKERS[identifier].STAKING_CONTRACT;
    const wallet = new BeaconWallet(options);
    const WALLET_RESP = await CheckIfWalletConnected(wallet, network.type);
    if (WALLET_RESP.success) {
      const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setWalletProvider(wallet);
      const contractInstance = await Tezos.wallet.at(contractAddress);
      const operation = await contractInstance.methods.GetReward(1).send();
      await operation.confirmation().then(() => operation.opHash);
      return {
        success: true,
        operationId: operation.opHash,
      };
    } else {
      return {
        success: false,
        error: WALLET_RESP.error,
      };
    }
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

export const unstakeLocker = async (amount, mapKey, symbol) => {
  try {
    const network = {
      type: CONFIG.WALLET_NETWORK,
    };
    const options = {
      name: 'Plenty Farm',
    };
    const connectedNetwork = CONFIG.NETWORK;
    const wallet = new BeaconWallet(options);
    const WALLET_RESP = await CheckIfWalletConnected(wallet, network.type);
    if (WALLET_RESP.success) {
      const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
      Tezos.setWalletProvider(wallet);
      let contractAddress = null;

      contractAddress =
        CONFIG.CONTRACT[connectedNetwork].LOCKERS[symbol].STAKING_CONTRACT;

      const contractInstance = await Tezos.wallet.at(contractAddress);
      let tokenAmount =
        amount *
        Math.pow(
          10,
          CONFIG.CONTRACT[connectedNetwork].LOCKERS[symbol]
            .STAKING_TOKEN_DECIMAL
        );
      const operation = await contractInstance.methods
        .unstake(tokenAmount, mapKey)
        .send();
      await operation.confirmation().then(() => operation.opHash);
      return {
        success: true,
        operationId: operation.opHash,
      };
    } else {
      return {
        success: false,
        error: WALLET_RESP.error,
      };
    }
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

const calculateWithdrwalFee = (difference, balance, symbol) => {
  let feeStructure = null;
  if (symbol === 'KALAM') {
    feeStructure = [
      {
        block: 0,
        rate: 0,
      },
    ];
  } else {
    feeStructure = [
      {
        block: 12288,
        rate: 4,
      },
      {
        block: 12288,
        rate: 0,
      },
    ];
  }
  let fee = -1;
  for (let i = 0; i < feeStructure.length; i++) {
    if (difference < feeStructure[i].block) {
      fee = ((balance * feeStructure[i].rate) / 100).toFixed(2);
      fee = parseFloat(fee);
      break;
    }
  }
  if (fee === -1) {
    fee = (
      (balance * feeStructure[feeStructure.length - 1].rate) /
      100
    ).toFixed(2);
    fee = parseFloat(fee);
  }
  return fee;
};

export const getLockerStakes = async (soment, addressOfUser, symbol) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setProvider(CONFIG.RPC_NODES[connectedNetwork]);
    const blockData = await axios.get(
      `${CONFIG.TZKT_NODES[connectedNetwork]}/v1/blocks/count`
    );
    let contractAddress = null;

    contractAddress =
      CONFIG.CONTRACT[connectedNetwork].LOCKERS[symbol].STAKING_CONTRACT;

    const contract = await Tezos.contract.at(contractAddress);
    const storage = await contract.storage();
    const userDetails = await storage.balances.get(addressOfUser);
    let blocks = [];
    userDetails.InvestMap.keyMap.forEach((element) => {
      let blockDetails = userDetails.InvestMap.get(element);
      let blockAtStake = blockDetails.level.toNumber();
      blockAtStake = parseInt(blockAtStake);
      let difference = blockData.data - blockAtStake;
      let balance = (
        blockDetails.amount.toNumber() /
        Math.pow(
          10,
          CONFIG.CONTRACT[connectedNetwork].LOCKERS[symbol]
            .STAKING_TOKEN_DECIMAL
        )
      ).PrecisionMaker(3);
      balance = parseFloat(balance);
      let withdrawalFee = calculateWithdrwalFee(difference, balance, symbol);
      blocks.push({
        number: parseInt(element) + 1,
        balance,
        withdrawalFee,
      });
    });
    return {
      success: true,
      stakes: blocks,
    };
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
};

const getPriceInUSD = async (contractAddress, connectedNetwork, decimal) => {
  try {
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    const dexInstance = await Tezos.contract.at(contractAddress);
    const dexStorage = await dexInstance.storage();
    let tezPool = await dexStorage.storage.tez_pool.toNumber();
    let tokenPool = await dexStorage.storage.token_pool.toNumber();
    let tokenPriceInXtz = Math.pow(10, decimal) * tezPool * 1000;
    tokenPriceInXtz = tokenPriceInXtz / (tokenPool - Math.pow(10, decimal));
    tokenPriceInXtz = tokenPriceInXtz / 997;
    tokenPriceInXtz = tokenPriceInXtz / Math.pow(10, 6);
    let priceFeed = await axios.get(CONFIG.API.url);
    priceFeed = priceFeed.data.market_data.current_price.usd;
    let tokenPriceInUSD = tokenPriceInXtz * priceFeed;
    return {
      success: true,
      tokenPriceInUSD,
    };
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
};
const getLockerStats = async (identifier) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    let stakeTokenPrice = await getPriceInUSD(
      CONFIG.CONTRACT[connectedNetwork].LOCKERS[identifier].STAKING_TOKEN_DEX,
      connectedNetwork,
      CONFIG.CONTRACT[connectedNetwork].LOCKERS[identifier]
        .STAKING_TOKEN_DECIMAL
    );
    let rewardTokenPrice = await getPriceInUSD(
      CONFIG.CONTRACT[connectedNetwork].LOCKERS[identifier].REWARD_TOKEN_DEX,
      connectedNetwork,
      CONFIG.CONTRACT[connectedNetwork].LOCKERS[identifier].REWARD_TOKEN_DECIMAL
    );
    const lockerContractInstance = await Tezos.contract.at(
      CONFIG.CONTRACT[connectedNetwork].LOCKERS[identifier].STAKING_CONTRACT
    );
    const lockerStorageInstance = await lockerContractInstance.storage();
    let totalSupply = await lockerStorageInstance.totalSupply;
    totalSupply =
      totalSupply.toNumber() /
      Math.pow(
        10,
        CONFIG.CONTRACT[connectedNetwork].LOCKERS[identifier]
          .REWARD_TOKEN_DECIMAL
      );
    let rewardRate = await lockerStorageInstance.rewardRate;
    rewardRate =
      rewardRate.toNumber() /
      Math.pow(
        10,
        CONFIG.CONTRACT[connectedNetwork].LOCKERS[identifier]
          .REWARD_TOKEN_DECIMAL
      );
    let totalLiquidity = totalSupply * stakeTokenPrice.tokenPriceInUSD;
    let APR =
      (rewardRate * 525600 * rewardTokenPrice.tokenPriceInUSD) /
      (totalSupply * stakeTokenPrice.tokenPriceInUSD);
    APR = APR * 100;
    let DPY =
      (rewardRate * 1440 * rewardTokenPrice.tokenPriceInUSD) /
      (totalSupply * stakeTokenPrice.tokenPriceInUSD);
    DPY = DPY * 100;
    let intervalList = [1, 7, 30, 365];
    let roiTable = [];
    for (let interval of intervalList) {
      roiTable.push({
        roi: DPY * interval,
        rewardTokenPer1000dollar:
          (10 * DPY * interval) / rewardTokenPrice.tokenPriceInUSD,
      });
    }
    return {
      success: true,
      totalSupply,
      totalLiquidity,
      identifier,
      APR,
      roiTable,
    };
  } catch (error) {
    return {
      success: false,
      totalSupply: 0,
      totalLiquidity: 0,
      APR: 0,
      roiTable: [],
      identifier,
    };
  }
};
export const getStatsOfAllLocker = async () => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    let promises = [];
    for (let symbol in CONFIG.CONTRACT[connectedNetwork].LOCKERS) {
      promises.push(getLockerStats(symbol));
    }
    const response = await Promise.all(promises);
    return {
      success: true,
      response,
    };
  } catch (error) {
    return {
      success: false,
      error,
    };
  }
};

const getTotalSupplyOfLocker = async (identifier) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    const stakingContractInstance = await Tezos.contract.at(
      CONFIG.CONTRACT[connectedNetwork].LOCKERS[identifier].STAKING_CONTRACT
    );
    const stakingStorageInstance = await stakingContractInstance.storage();
    let totalSupply = await stakingStorageInstance.totalSupply;
    totalSupply =
      totalSupply.toNumber() /
      Math.pow(
        10,
        CONFIG.CONTRACT[connectedNetwork].LOCKERS[identifier]
          .REWARD_TOKEN_DECIMAL
      );
    totalSupply = parseFloat(totalSupply);
    return {
      success: true,
      totalSupply,
      identifier,
    };
  } catch (error) {
    return {
      success: false,
      error,
      totalSupply: 0,
      identifier,
    };
  }
};
export const getTVLForHomePage = async () => {
  try {
    let connectedNetwork = CONFIG.NETWORK;
    const promises = [];
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    const plentyPriceInUSD = await getPriceInUSD(
      CONFIG.CONTRACT[connectedNetwork].POOLS['PLENTY'].DEX,
      connectedNetwork,
      CONFIG.CONTRACT[connectedNetwork].POOLS['PLENTY'].TOKEN_DECIMAL
    );
    for (let symbol in CONFIG.CONTRACT[connectedNetwork].LOCKERS) {
      promises.push(getTotalSupplyOfLocker(symbol));
    }
    const response = await Promise.all(promises);
    let tvl = 0;
    for (let i in response) {
      tvl = tvl + response[i].totalSupply * plentyPriceInUSD.tokenPriceInUSD;
    }
    return {
      success: true,
      tvl,
    };
  } catch (error) {
    return {
      success: false,
      totalLockerTVL: 0,
    };
  }
};

const getBalanceOfUserInLocker = async (identifier, addressOfUser) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    const stakingContractInstance = await Tezos.contract.at(
      CONFIG.CONTRACT[connectedNetwork].LOCKERS[identifier].STAKING_CONTRACT
    );
    const stakingStorageInstance = await stakingContractInstance.storage();
    const userDetails = await stakingStorageInstance.balances.get(
      addressOfUser
    );
    let userBalance = userDetails.balance;
    userBalance =
      userBalance /
      Math.pow(
        10,
        CONFIG.CONTRACT[connectedNetwork].LOCKERS[identifier]
          .REWARD_TOKEN_DECIMAL
      );
    return {
      success: true,
      userBalance,
      identifier,
    };
  } catch (error) {
    return {
      success: false,
      userBalance: 0,
      identifier,
    };
  }
};
export const getTVLOfUserInAllLockers = async (addressOfUser) => {
  try {
    let connectedNetwork = CONFIG.NETWORK;
    const promises = [];
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    const plentyPriceInUSD = await getPriceInUSD(
      CONFIG.CONTRACT[connectedNetwork].POOLS['PLENTY'].DEX,
      connectedNetwork,
      CONFIG.CONTRACT[connectedNetwork].POOLS['PLENTY'].TOKEN_DECIMAL
    );
    for (let symbol in CONFIG.CONTRACT[connectedNetwork].LOCKERS) {
      promises.push(getBalanceOfUserInLocker(symbol, addressOfUser));
    }
    const response = await Promise.all(promises);
    let tvlOfUser = 0;
    for (let i in response) {
      tvlOfUser =
        tvlOfUser + response[i].userBalance * plentyPriceInUSD.tokenPriceInUSD;
    }
    return {
      success: true,
      tvlOfUser,
    };
  } catch (error) {
    return {
      success: false,
      tvlOfUser: 0,
    };
  }
};
