import React, { Fragment } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Layout = (props) => {
  return (
    <Fragment>
      <ToastContainer />
      <div className="page-container">{props.children}</div>
    </Fragment>
  );
}

export default Layout;
