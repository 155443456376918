/* eslint-disable jsx-a11y/alt-text */
import Layout from '../../components/layout/layout';

import Card from '../../components/FarmCard/Cards';

import tokenimg from '../../assets/image/multi-icon.png';
import PuffLoader from 'react-spinners/PuffLoader';
import whalereverse from '../../assets/image/icon-color.png';
import loader from '../../assets/image/icons/loader2.gif';
import React, { useState, useEffect } from 'react';
import { AiOutlineCalculator, AiOutlineClose } from 'react-icons/ai';
import SwitchButton from '../../components/switchButton/switchButton';
import { BiBadgeCheck } from 'react-icons/bi';
import Modal from 'react-modal';

import * as utils from '../../utils/utils';
import { getStakedPlentyBalanceOfUser } from '../../apis/apis';

function PlentyFarm(props) {
  const [dataBalance, setData] = useState({});
  const [list, setList] = useState([...props.list]);
  // const [loading, setLoading] = useState(props.loading);
  const [showActive, setShowActive] = useState(true);
  let loadingInfo = null;
  const customStyles = {
    /** modal css */
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      padding: '0px',
      maxWidth: '700px',
      transform: 'translate(-50%, -50%)',
    },
  };

  /** token card hidden detail show or hide */
  function onHide(item) {
    let templist = [...props.list];
    templist[item].hide = false;
    return setList(templist);
  }

  function onDisplay(item) {
    let templist = [...props.list];
    templist[item].hide = true;
    return setList(templist);
  }

  /** modal display and hide function */
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  /** token detail data */

  /** modal detail data  */

  const [modaltable, setModaltable] = useState([
    {
      time: '1d',
      roi: '6.37',
      plenty: '0.91',
    },
    {
      time: '7d',
      roi: '53.87',
      plenty: '	7.69',
    },
    {
      time: '30d',
      roi: '	533.69',
      plenty: '79.19',
    },
    {
      time: '365d(APY)',
      roi: '57.98',
      plenty: '81.09',
    },
  ]);

  if (props.loading) {
    const color = '#5C0FAE';
    document.getElementById('body').classList.add('no-scroll');
    loadingInfo = (
      <div className="loading-pools" style={{ zIndex: '999' }}>
        <div className="loader-icon">
          <PuffLoader color={color} size={150} />
        </div>
      </div>
    );
  }

  if (!props.loading) {
    document.getElementById('body').classList.remove('no-scroll');
  }

  const setShow = () => {
    setShowActive(!showActive);
  };

  const fetchPoolDataOnActiveStateChange = async () => {
    // let List = props.list;
    // List = await props.PoolRewardsFetcher(List);
    // List = await props.PoolBalanceFetcher(List);
    // await props.PoolAPRFetcher(List);
    await props.DataFetcher();
  };

  return (
    <Layout>
      {loadingInfo}
      <div className="text-center">
        <h3 className="token-page-title">Stake LP tokens and earn PLENTY</h3>
        <h5 className="token-page-subtitle">
          Withdrawal fee is used to buyback and burn PLENTY
        </h5>
      </div>
      <div className="text-center">
        <SwitchButton
          setShow={props.setShow}
          showActive={props.showActive}
          fetchPoolDataOnActiveStateChange={fetchPoolDataOnActiveStateChange}
        />
      </div>
      <div className="flex farm-page-flex justify-center">
        <Card
          data={props.list}
          cardHeading="PLENTY - XTZ LP"
          dataBalance={dataBalance}
          modalIsOpen={modalIsOpen}
          onHide={onHide}
          onDisplay={onDisplay}
          showActive={showActive}
          {...props}
        />
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="flex justify-between modal-header">
          <h3 className="modal-header-title">ROI</h3>
          <AiOutlineClose onClick={closeModal} className="modal-header-close" />
        </div>
        <hr />
        <div className="modal-body ">
          <table className="table-auto w-full">
            <thead className="text-left modal-table-title ">
              <tr>
                <th>TIMEFRAME</th>
                <th>ROI</th>
                <th>PLENTY PER $1000</th>
              </tr>
            </thead>
            <tbody>
              {modaltable.map((item, index) => (
                <tr key={index}>
                  <td>{item.time}</td>
                  <td>{item.roi}%</td>
                  <td>{item.plenty}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <p className="modal-table-detail">
            Calculated based on current rates. Compounding once daily. Rates are
            estimates provided for your convenience only, and by no means
            represent guaranteed returns.
          </p>
        </div>
      </Modal>
    </Layout>
  );
}

export default PlentyFarm;
