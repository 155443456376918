import React, { Fragment, Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import Home from '../pages/home/home';
import PlentyFarm from '../pages/plentyFarm/plentyFarm';
import Pools from '../pages/pools/pools';
import Lockers from '../pages/lockers/lockers';
import NewUser from '../pages/newUser/newUser';
import PageNotFound from '../pages/404/404';
import CustomModal from '../components/customModal/customModal';
import * as utils from '../utils/utils';
import {
  fetchBalances,
  poolsRewards,
  getROIOfAllPools,
  fetchPoolsData,
} from '../apis/pools';
import { poolAPRFetcher, LpFarmAPRFetcher } from '../apis/AprCalc';
import {
  BalancesFarmFetcher,
  getFarmRewardsArray,
  getROIOfAllFarms,
} from '../apis/farm';
import {
  getTVLAndTVLOfUser,
  plentyInWallet,
  plentyToHarvest,
  getTotalBurned,
} from '../apis/home';
import {
  fetchBalancesOfLockers,
  fetchAllEarnedTokens,
  getStatsOfAllLocker,
} from '../apis/lockers';
import tokenimg from '../assets/image/multi-icon.png';
import kalamImg from '../assets/image/kalam-xtz.png';
import kalamPoolImg from '../assets/image/kalam-pool.png';
import logoIcon from '../assets/image/logo-icon.png';
import ethtz from '../assets/image/ethtz.png';
import hdao from '../assets/image/hdao.png';
import usdtz from '../assets/image/usdtz.png';
import wlink from '../assets/image/wlink.png';
import wmatic from '../assets/image/wmatic.png';
import wrap from '../assets/image/wrap.png';
import {
  getCirculatingSupply,
  getTokensPerBlock,
  getTotalMinted,
  getMarketCap,
  getPlentyPriceInUSD,
} from '../apis/Offline';
import { throttle } from 'lodash';
const { ENCRYPTION_STRING, PLENTY_CRED, NETWORK } = require('../apis/config');

var CryptoJS = require('crypto-js');

const initState = {
  loading: true,
  liquidity: 0,
  tvl: 0,
  tvlUser: 0,
  homePageData: {
    totalMinted: 0,
    circulatingSupply: 0,
  },
  homePageTokenBalance: 0,
  homePageTokenPrice: 0,
  list: [
    {
      img: logoIcon,
      multi: '100',
      title: 'PLENTY',
      apr: '0',
      earn: 'PLENTY',
      fee: '0%',
      earned: 0,
      hide: false,
      deposit: 'Plenty - USDC',
      liquidity: 0,
      withdrawalFee: '0%',
      balance: 0,
      userBalance: 0,
      active: true,
    },
    {
      img: wrap,
      multi: '100',
      title: 'WRAP',
      apr: '0',
      earn: 'PLENTY',
      fee: '0%',
      earned: 0,
      hide: false,
      deposit: 'Plenty - USDC',
      liquidity: 0,
      withdrawalFee: '0%',
      balance: 0,
      userBalance: 0,
      active: false,
    },
    {
      img: ethtz,
      multi: '100',
      title: 'ETHtz',
      apr: '0',
      earn: 'PLENTY',
      fee: '0%',
      earned: 0,
      hide: false,
      deposit: 'Plenty - USDC',
      liquidity: 0,
      withdrawalFee: '0%',
      balance: 0,
      userBalance: 0,
      active: true,
    },
    {
      img: hdao,
      multi: '100',
      title: 'hDAO',
      apr: '0',
      earn: 'PLENTY',
      fee: '0%',
      earned: 0,
      hide: false,
      deposit: 'Plenty - USDC',
      liquidity: 0,
      withdrawalFee: '0%',
      balance: 0,
      userBalance: 0,
      active: true,
    },
    {
      img: usdtz,
      multi: '100',
      title: 'USDtz',
      apr: '0',
      earn: 'PLENTY',
      fee: '0%',
      earned: 0,
      hide: false,
      deposit: 'Plenty - USDC',
      liquidity: 0,
      withdrawalFee: '0%',
      balance: 0,
      userBalance: 0,
      active: true,
    },
    {
      img: wlink,
      multi: '100',
      title: 'wLINK',
      apr: '0',
      earn: 'PLENTY',
      fee: '0%',
      earned: 0,
      hide: false,
      deposit: 'Plenty - USDC',
      liquidity: 0,
      withdrawalFee: '0%',
      balance: 0,
      userBalance: 0,
      active: true,
    },
    {
      img: wmatic,
      multi: '100',
      title: 'wMATIC',
      apr: '0',
      earn: 'PLENTY',
      fee: '0%',
      earned: 0,
      hide: false,
      deposit: 'Plenty - USDC',
      liquidity: 0,
      withdrawalFee: '0%',
      balance: 0,
      userBalance: 0,
      active: true,
    },
    {
      img: logoIcon,
      multi: '100',
      title: 'PLENTY',
      apr: '0',
      earn: 'PLENTY',
      fee: '0%',
      earned: 0,
      hide: false,
      deposit: 'Plenty - USDC',
      liquidity: 0,
      withdrawalFee: '0%',
      balance: 0,
      userBalance: 0,
      active: false,
    },
    {
      img: ethtz,
      multi: '100',
      title: 'ETHtz',
      apr: '0',
      earn: 'PLENTY',
      fee: '0%',
      earned: 0,
      hide: false,
      deposit: 'Plenty - USDC',
      liquidity: 0,
      withdrawalFee: '0%',
      balance: 0,
      userBalance: 0,
      active: false,
    },
    {
      img: hdao,
      multi: '100',
      title: 'hDAO',
      apr: '0',
      earn: 'PLENTY',
      fee: '0%',
      earned: 0,
      hide: false,
      deposit: 'Plenty - USDC',
      liquidity: 0,
      withdrawalFee: '0%',
      balance: 0,
      userBalance: 0,
      active: false,
    },
    {
      img: usdtz,
      multi: '100',
      title: 'USDtz',
      apr: '0',
      earn: 'PLENTY',
      fee: '0%',
      earned: 0,
      hide: false,
      deposit: 'Plenty - USDC',
      liquidity: 0,
      withdrawalFee: '0%',
      balance: 0,
      userBalance: 0,
      active: false,
    },
    {
      img: wmatic,
      multi: '100',
      title: 'wMATIC',
      apr: '0',
      earn: 'PLENTY',
      fee: '0%',
      earned: 0,
      hide: false,
      deposit: 'Plenty - USDC',
      liquidity: 0,
      withdrawalFee: '0%',
      balance: 0,
      userBalance: 0,
      active: false,
    },
    {
      img: wlink,
      multi: '100',
      title: 'wLINK',
      apr: '0',
      earn: 'PLENTY',
      fee: '0%',
      earned: 0,
      hide: false,
      deposit: 'Plenty - USDC',
      liquidity: 0,
      withdrawalFee: '0%',
      balance: 0,
      userBalance: 0,
      active: false,
    },
  ],
  farmList: [
    {
      img: tokenimg,
      multi: '100',
      title: 'PLENTY - XTZ',
      apr: 0,
      earn: 'PLENTY',
      fee: '0%',
      earned: 0,
      hide: false,
      deposit: 'PLENTY - XTZ LP',
      liquidity: '0',
      withdrawalFee: '0%',
      balance: 0,
      userBalance: 0,
      URL: '',
      active: true,
    },
    {
      img: tokenimg,
      multi: '100',
      title: 'PLENTY - XTZ',
      apr: 0,
      earn: 'PLENTY',
      fee: '0%',
      earned: 0,
      hide: false,
      deposit: 'PLENTY - XTZ LP',
      liquidity: '0',
      withdrawalFee: '0%',
      balance: 0,
      userBalance: 0,
      URL: '',
      active: false,
    },
    {
      img: kalamImg,
      multi: '100',
      title: 'KALAM - XTZ',
      apr: 0,
      earn: 'PLENTY',
      fee: '0%',
      earned: 0,
      hide: false,
      deposit: 'KALAM - XTZ LP',
      liquidity: '0',
      withdrawalFee: '0%',
      balance: 0,
      userBalance: 0,
      URL: '',
      active: false,
    },
  ],
  lockersList: [
    {
      img: wrap,
      multi: '100',
      title: 'WRAP',
      apr: 0,
      earn: 'WRAP',
      fee: '0%',
      earned: 0,
      hide: false,
      deposit: 'PLENTY',
      liquidity: '0',
      withdrawalFee: '0%',
      balance: 0,
      userBalance: 0,
      URL: '',
      active: false,
    },
    {
      img: kalamPoolImg,
      multi: '100',
      title: 'KALAM',
      apr: 0,
      earn: 'KALAM',
      fee: '0%',
      earned: 0,
      hide: false,
      deposit: 'PLENTY',
      liquidity: '0',
      withdrawalFee: '0%',
      balance: 0,
      userBalance: 0,
      URL: '',
      active: false,
    },
  ],
  OpenModal: false,
  modalType: '',
  operationId: '',
  roiOfFarms: [],
  roiOfPools: [],
  lockerRoi: [],
  lockerApr: '',
  lockerLiquidity: '',
  cardTitle: '',
  plentyToHarvest: 0,
  tokensPerBlock: 0,
  totalSupply: 0,
  totalBurned: 0,
  showActive: true,
};

export default class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...initState,
      newUser: true,
    };
    this.handlePoolEvent = this.handlePoolEvent.bind(this);
    this.handleThrottle = throttle(this.handlePoolEvent, 60000);
  }
  FilterBal = (filteredBal) => {
    if (filteredBal.toString().split('e').length > 1)
      return `${
        filteredBal.PrecisionMaker(8).toString().split('.')[0]
      }.${filteredBal.PrecisionMaker(8).toString().split('.')[1].slice(0, 5)}`;
    else return filteredBal;
  };

  setoperationId = (operationId) => {
    this.setState({ operationId });
  };

  setmodalType = (modalType) => {
    this.setState({ modalType });
  };

  setOpenModal = () => {
    const OpenModal = !this.state.OpenModal;
    this.setState({ OpenModal: OpenModal });
  };

  PoolRewardsFetcher = async (LIST) => {
    const newList = [];
    let userAddress = utils.getAddressFromLocalStorage();
    if (!userAddress) {
      return;
    }
    const POOL_REWARDS = await poolsRewards(
      NETWORK,
      userAddress,
      this.state.showActive
    );
    if (POOL_REWARDS.success) {
      LIST.forEach((elem) => {
        const filteredDATA = POOL_REWARDS.response.filter(
          (val) => val.symbol === elem.title
        );
        if (filteredDATA.length > 0) {
          const filteredBal = filteredDATA[0].reward;
          const earnedBal = this.FilterBal(filteredBal);
          newList.push({
            ...elem,
            earned: earnedBal,
          });
        } else {
          newList.push({
            ...elem,
          });
        }
      });
      if (newList.length > 0) {
        this.setState({ list: newList });
        return newList;
      }
      return LIST;
    }
    return LIST;
  };

  LockerRewardFetcher = async (LIST) => {
    const newList = [];
    let userAddress = utils.getAddressFromLocalStorage();
    if (!userAddress) {
      return;
    }
    const LOCKER_REWARDS = await fetchAllEarnedTokens(userAddress);
    if (LOCKER_REWARDS.success) {
      LIST.forEach((elem) => {
        const filteredDATA = LOCKER_REWARDS.response.filter(
          (val) => val.symbol === elem.title
        );
        if (filteredDATA.length > 0) {
          const filteredBal = filteredDATA[0].reward;
          const earnedBal = this.FilterBal(filteredBal);
          newList.push({
            ...elem,
            earned: earnedBal,
          });
        } else {
          newList.push({
            ...elem,
          });
        }
      });
      if (newList.length > 0) {
        this.setState({ lockersList: newList });
        return newList;
      }
      return LIST;
    }
    return LIST;
  };
  PoolBalanceFetcher = async (LIST) => {
    const newList = [];
    let userAddress = utils.getAddressFromLocalStorage();
    if (!userAddress) {
      return;
    }
    const POOL_BAL_RESP = await fetchBalances(
      NETWORK,
      userAddress,
      this.state.showActive
    );
    if (POOL_BAL_RESP.success) {
      LIST.forEach((elem) => {
        const filteredDATA = POOL_BAL_RESP.response.filter(
          (val) => val.symbol === elem.title
        );
        const filteredBalDATA = POOL_BAL_RESP.balanceResponse.filter(
          (val) => val.symbol === elem.title
        );
        let balance = elem.balance;
        let userBalance = elem.userBalance;
        if (filteredDATA.length > 0) {
          balance = filteredDATA[0].balance;
        }
        if (filteredBalDATA.length > 0) {
          userBalance = filteredBalDATA[0].balance;
        }
        newList.push({
          ...elem,
          balance,
          userBalance,
        });
      });
      if (newList.length > 0) {
        this.setState({ list: newList });
        return newList;
      }
      return LIST;
    }
    return LIST;
  };
  PoolAPRFetcher = async (LIST) => {
    // const newList = [];
    // const POOL_APR = await poolAPRFetcher(NETWORK);
    // if (POOL_APR.success) {
    //   LIST.forEach((elem) => {
    //     const filteredDATA = POOL_APR.data.filter(
    //       (val) => val.title === elem.title
    //     );
    //     if (filteredDATA.length > 0) {
    //       newList.push({
    //         ...elem,
    //         apr: filteredDATA[0].APR,
    //         liquidity: filteredDATA[0].totalSupply,
    //       });
    //     } else {
    //       newList.push({
    //         ...elem,
    //       });
    //     }
    //   });
    //   if (newList.length > 0) {
    //     this.setState({ list: newList });
    //     return newList;
    //   }
    //   return LIST;
    // }
    // return LIST;
  };

  displayData = () => {
    fetchPoolsData().then((fetchPoolData) => {
      let newList = this.state.list;
      newList.map((pool) => {
        if (pool.active) {
          return (
            (pool.apr = fetchPoolData.poolsData[pool.title].APR),
            (pool.liquidity =
              fetchPoolData.poolsData[pool.title].totalLiquidty),
            (pool.roiTable = fetchPoolData.poolsData[pool.title].roiTable)
          );
        }
      });
      this.setState({
        list: newList,
        loading: false,
      });
    });
  };

  PoolsDataFetcher = async (BACK_GROUND) => {
    try {
      this.setState({ loading: true });
      let userAddress = utils.getAddressFromLocalStorage();
      if (BACK_GROUND) {
        this.setState({ loading: false });
      }

      if (userAddress) {
        let LIST = JSON.parse(JSON.stringify(this.state.list));
        LIST = await this.PoolBalanceFetcher(LIST);
        await this.PoolRewardsFetcher(LIST);
        this.displayData();
      } else {
        this.displayData();
        if (!BACK_GROUND) {
          this.setState({ loading: false });
        }
      }
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  LockerDataFetcher = async (BACK_GROUND) => {
    try {
      // this.setState({ loading: true });
      let userAddress = utils.getAddressFromLocalStorage();
      const lockerStats = await getStatsOfAllLocker();
      if (!userAddress) {
        let LIST = JSON.parse(JSON.stringify(this.state.lockersList));
        LIST = await this.LockerBalanceFetcher(LIST);
        await this.LockerRewardFetcher(LIST);
        const lockerApr = lockerStats.response[1].APR;
        const lockerLiquidity = lockerStats.response[1].totalLiquidity;
        const lockerRoi = lockerStats.response[1].roiTable;
        this.setState({
          lockerRoi,
          lockerApr,
          lockerLiquidity,
          loading: false,
        });
        return;
      } else {
        // if (!BACK_GROUND) this.setState({ loading: true });
        let LIST = JSON.parse(JSON.stringify(this.state.lockersList));
        LIST = await this.LockerBalanceFetcher(LIST);
        await this.LockerRewardFetcher(LIST);
        const lockerApr = lockerStats.response[1].APR;
        const lockerLiquidity = lockerStats.response[1].totalLiquidity;
        const lockerRoi = lockerStats.response[1].roiTable;
        this.setState({
          lockerRoi,
          lockerApr,
          lockerLiquidity,
          loading: false,
        });
      }
    } catch (error) {
      this.setState({ loading: false });
    }
  };

  LockerBalanceFetcher = async (LIST) => {
    const newList = [];
    let userAddress = utils.getAddressFromLocalStorage();
    if (!userAddress) {
      return;
    }
    const LOCKER_BAL_RESP = await fetchBalancesOfLockers(userAddress);
    if (LOCKER_BAL_RESP) {
      LIST.forEach((elem) => {
        const filteredDATA = LOCKER_BAL_RESP.response.filter(
          (val) => val.symbol === elem.title
        );
        const filteredBalDATA = LOCKER_BAL_RESP.balanceResponse.filter(
          (val) => val.symbol === elem.title
        );
        let balance = elem.balance;
        let userBalance = elem.userBalance;
        if (filteredDATA.length > 0) {
          balance = filteredDATA[0].balance;
        }
        if (filteredBalDATA.length > 0) {
          userBalance = filteredBalDATA[0].balance;
        }
        newList.push({
          ...elem,
          balance,
          userBalance,
        });
      });
      if (newList.length > 0) {
        this.setState({ lockersList: newList, loading: false });
        return newList;
      }
      return LIST;
    }
    return LIST;
  };

  NewPoolsBalanceFetcher = async (LIST) => {
    try {
      const newList = [];
      let userAddress = utils.getAddressFromLocalStorage();
      if (!userAddress) {
        return;
      }
      const POOL_BAL_RESP = await fetchBalances(NETWORK, userAddress);
      if (POOL_BAL_RESP.success) {
        LIST.forEach((elem) => {
          const filteredDATA = POOL_BAL_RESP.response.filter(
            (val) => val.symbol === elem.title
          );
          const filteredBalDATA = POOL_BAL_RESP.balanceResponse.filter(
            (val) => val.symbol === elem.title
          );
          let balance = elem.balance;
          let userBalance = elem.userBalance;
          if (filteredDATA.length > 0) {
            balance = filteredDATA[0].balance;
          }
          if (filteredBalDATA.length > 0) {
            userBalance = filteredBalDATA[0].balance;
          }
          newList.push({
            ...elem,
            balance,
            userBalance,
          });
        });
        if (newList.length > 0) {
          this.setState({ list: newList });
          return newList;
        }
        return LIST;
      }
      return LIST;
    } catch (error) {
      console.error(error);
    }
  };

  //   NewPoolsAprFetcher = async () => {
  //     try {
  //       const newList = [];
  //       const LIST = this.state.list;
  //       const POOL_APR = await poolAPRFetcher(NETWORK);
  //       if (POOL_APR.success) {
  //         LIST.forEach((elem) => {
  //           const filteredDATA = POOL_APR.data.filter(
  //             (val) => val.title === elem.title
  //           );
  //           if (filteredDATA.length > 0) {
  //             newList.push({
  //               ...elem,
  //               apr: filteredDATA[0].APR,
  //               liquidity: filteredDATA[0].totalSupply,
  //             });
  //           } else {
  //             newList.push({
  //               ...elem,
  //             });
  //           }
  //         });
  //         if (newList.length > 0) {
  //           return newList;
  //         }
  //         return LIST;
  //       }
  //       return LIST;
  //     } catch (error) {
  //
  //     }
  //   };

  setTitle = (cardTitle) => {
    this.setState({ cardTitle });
  };

  FarmBalanceFetcher = async () => {
    // * farm balance
    let newList = [...this.state.farmList];
    let userAddress = utils.getAddressFromLocalStorage();
    if (!userAddress) {
      return newList;
    }
    const FARM_BALANCE = await BalancesFarmFetcher(
      userAddress,
      this.state.showActive
    );
    if (FARM_BALANCE.success) {
      newList.forEach((elem, index) => {
        const filteredDATA = FARM_BALANCE.response.filter(
          (val) => val.symbol === elem.title
        );
        const filteredBalDATA = FARM_BALANCE.balanceResponse.filter(
          (val) => val.symbol === elem.title
        );
        let balance = elem.balance;
        let userBalance = elem.userBalance;
        if (filteredDATA.length > 0) {
          balance = filteredDATA[0].balance;
        }
        if (filteredBalDATA.length > 0) {
          userBalance = filteredBalDATA[0].balance;
        }
        newList[index].userBalance = userBalance;
        newList[index].balance = balance;
      });
      this.setState({ farmList: newList });
      return newList;
    }
    return newList;
  };

  // FarmAprFetcher = async () => {
  //   let newList = [...this.state.farmList];
  //   const FARM_APR = await LpFarmAPRFetcher();
  //   if (FARM_APR.success) {
  //     await this.FarmBalanceFetcher();
  //     await this.FarmRewardsFetcher();
  //     newList.forEach((elem, index) => {
  //       newList[index].apr = FARM_APR.data[index].APR.PrecisionMaker(0);
  //       newList[index].liquidity = FARM_APR.data[index].totalSupply;
  //     });
  //     this.setState({ farmList: newList });
  //   }
  //   return newList;
  // };

  FarmAprFetcher = async () => {
    let newList = [...this.state.farmList];
    const FARM_APR = await LpFarmAPRFetcher();
    if (FARM_APR.success) {
      newList.forEach((elem, index) => {
        const filteredDATA = FARM_APR.data.filter(
          (val) => val.farmIdentifier === elem.title
        );
        if (filteredDATA.length > 0) {
          newList[index].apr = filteredDATA[0].APR.PrecisionMaker(0);
          newList[index].liquidity = filteredDATA[0].totalSupply;
        }
      });
      if (newList.length > 0) {
        this.setState({ farmList: newList });
        return newList;
      }
    }
  };

  FarmRewardsFetcher = async () => {
    let newList = [...this.state.farmList];
    let userAddress = utils.getAddressFromLocalStorage();
    if (!userAddress) {
      this.setState({ loading: false });
      return newList;
    }
    const FARM_REWARDS = await getFarmRewardsArray(
      userAddress,
      this.state.showActive
    );
    if (FARM_REWARDS.success) {
      newList.forEach((elem, index) => {
        const filteredDATA = FARM_REWARDS.response.filter(
          (val) => val.symbol === elem.title
        );
        if (filteredDATA.length > 0) {
          const filteredBal = filteredDATA[0].rewards;
          newList[index].earned = this.FilterBal(filteredBal);
        }
      });
      this.setState({ farmList: newList });
      return newList;
    }
    return newList;
  };

  FarmDataFetcher = async (BACK_GROUND) => {
    try {
      this.setState({ loading: true });
      let userAddress = utils.getAddressFromLocalStorage();
      if (BACK_GROUND) {
        this.setState({ loading: false });
      }
      if (!userAddress) {
        await this.FarmAprFetcher();
        const ROI_OF_FARMS = await getROIOfAllFarms(NETWORK);
        const roiOfFarms = ROI_OF_FARMS.roiOfFarms;
        this.setState({ roiOfFarms, loading: false });
        return;
      }
      // if (!BACK_GROUND) this.setState({ loading: true });
      await this.FarmAprFetcher();
      await this.FarmBalanceFetcher();
      await this.FarmRewardsFetcher();
      this.setState({ loading: false });
      const ROI_OF_FARMS = await getROIOfAllFarms(NETWORK);
      const roiOfFarms = ROI_OF_FARMS.roiOfFarms;
      this.setState({ roiOfFarms, loading: false });
    } catch (error) {
      this.setState({ loading: false });
      return {
        success: false,
        error,
      };
    }
  };

  HomePageDataFetcher = async () => {
    try {
      /** for tweeter feeds  */
      this.setState({ loading: true });
      let userAddress = utils.getAddressFromLocalStorage();
      const homePageState = { ...this.state };
      if (!userAddress) {
        await this.OfflineDataLoader();
        this.setState({ loading: false });
        return;
      }
      const MASTER_RESP = await Promise.all([
        // getTVLAndTVLOfUser(),
        getTVLAndTVLOfUser(userAddress),
        plentyInWallet(userAddress),
        plentyToHarvest(userAddress),
      ]);
      if (MASTER_RESP[0].success) {
        homePageState.tvlUser = MASTER_RESP[0].tvlOfUser.PrecisionMaker(2);
      }
      if (MASTER_RESP[1].success) {
        homePageState.homePageTokenBalance = MASTER_RESP[1].plentyInWallet;
      }
      if (MASTER_RESP[2].success) {
        homePageState.plentyToHarvest = MASTER_RESP[2].plentyToHarvest;
      }
      homePageState.loading = false;
      this.setState(homePageState);
    } catch (error) {}
  };

  OfflineDataLoader = async () => {
    try {
      const homePageState = { ...this.state };
      const MASTER_RESP = await Promise.all([
        getCirculatingSupply(),
        getTokensPerBlock(),
        getTotalMinted(),
        getMarketCap(),
        getPlentyPriceInUSD(),
        getTVLAndTVLOfUser(null),
        getTotalBurned(),
      ]);
      if (MASTER_RESP[0].success) {
        homePageState.homePageData.circulatingSupply =
          MASTER_RESP[0].circulatingSupply;
      }
      if (MASTER_RESP[1].success) {
        homePageState.tokensPerBlock = MASTER_RESP[1].tokensPerBlock;
      }
      if (MASTER_RESP[2].success) {
        homePageState.totalSupply = MASTER_RESP[2].totalSupply;
      }
      if (MASTER_RESP[3].success) {
        homePageState.homePageData.totalMinted = MASTER_RESP[3].marketCap;
      }
      if (MASTER_RESP[4].success) {
        homePageState.homePageTokenPrice = MASTER_RESP[4].plentyPriceInUSD;
      }
      const TVL_RESP = MASTER_RESP[5];
      if (TVL_RESP.success) {
        homePageState.tvl = TVL_RESP.tvl;
      }
      if (MASTER_RESP[6].success) {
        homePageState.totalBurned = MASTER_RESP[6].totalBurned;
      }
      homePageState.loading = false;
      this.setState(homePageState);
    } catch (error) {}
  };

  DataFetcher = async (BACK_GROUND) => {
    if (!!!BACK_GROUND) {
      this.setState({
        loading: true,
      });
    }
    if (this.props.ctx.userWalletAddress) {
      if (window.location.pathname === '/') {
        await this.OfflineDataLoader();
        this.HomePageDataFetcher();
      } else if (window.location.pathname === '/pools') {
        this.PoolsDataFetcher(BACK_GROUND);
      } else if (window.location.pathname === '/farms') {
        this.FarmDataFetcher(BACK_GROUND);
      } else if (window.location.pathname === '/ponds') {
        await this.LockerDataFetcher(BACK_GROUND);
        const lockerStats = await getStatsOfAllLocker();
        const lockerApr = lockerStats.response[1].APR;
        const lockerLiquidity = lockerStats.response[1].totalLiquidity;
        const lockerRoi = lockerStats.response[1].roiTable;

        this.setState({
          lockerApr,
          lockerLiquidity,
          lockerRoi,
          loading: false,
        });
      }
      //      this.setState({ loading: false });
    } else {
      if (window.location.pathname === '/') {
        await this.OfflineDataLoader();
      } else if (window.location.pathname === '/pools') {
        let LIST = JSON.parse(JSON.stringify(this.state.list));
        this.PoolsDataFetcher();
        //await this.PoolAPRFetcher(LIST);
        //const ROI_OF_POOLS = await getROIOfAllPools(NETWORK);
        //const roiOfPools = ROI_OF_POOLS.roiOfPools;
        //this.setState({ roiOfPools });
      } else if (window.location.pathname === '/farms') {
        await this.FarmAprFetcher();
        const ROI_OF_FARMS = await getROIOfAllFarms(NETWORK);
        const roiOfFarms = ROI_OF_FARMS.roiOfFarms;
        this.setState({ roiOfFarms });
      } else if (window.location.pathname === '/ponds') {
        await this.LockerDataFetcher(BACK_GROUND);

        const lockerStats = await getStatsOfAllLocker();
        const lockerApr = lockerStats.response[1].APR;
        const lockerLiquidity = lockerStats.response[1].totalLiquidity;
        const lockerRoi = lockerStats.response[1].roiTable;

        this.setState({
          lockerApr,
          lockerLiquidity,
          lockerRoi,
          loading: false,
        });
      }
      //this.setState({ loading: false });
    }
  };

  async handlePoolEvent() {
    let userAddress = utils.getAddressFromLocalStorage();
    if (userAddress) {
      if (!this.state.loading) {
        if (window.location.pathname === '/pools') {
          let LIST = [...this.state.list];
          LIST = await this.PoolRewardsFetcher(LIST);
          LIST = await this.PoolBalanceFetcher(LIST);
          //await this.PoolAPRFetcher(LIST);
        } else if (window.location.pathname === '/farms') {
          const BACK_GROUND = true;
          this.FarmDataFetcher(BACK_GROUND);
        } else if (window.location.pathname === '/ponds') {
          let LIST = [...this.state.lockersList];
          LIST = await this.LockerRewardFetcher(LIST);
          LIST = await this.LockerBalanceFetcher(LIST);
        }
      }
    } else return;
  }

  checkStorage() {
    const plentyCred = localStorage.getItem('plenty-pass');
    if (plentyCred) {
      var bytes = CryptoJS.AES.decrypt(plentyCred, ENCRYPTION_STRING);
      var plaintext = bytes.toString(CryptoJS.enc.Utf8);
      if (plaintext === PLENTY_CRED) {
        return true;
      }
      return false;
    }
  }

  componentDidMount() {
    this.DataFetcher();
    this.setState({
      newUser: localStorage.getItem('newUser'),
    });
  }

  setShow = () => {
    const { showActive } = this.state;
    this.setState({ showActive: !showActive });
  };
  render() {
    let customModal = null;
    if (this.props.ctx.setModal.show) {
      customModal = (
        <CustomModal
          closeModal={() => {
            const BACK_GROUND = true;
            this.props.ctx.showCustomModal('hide', 'dismiss');
            this.DataFetcher(BACK_GROUND);
          }}
          modalType={this.props.ctx.setModal.type}
          list={this.state.list}
          farmList={this.state.farmList}
          PoolsDataFetcher={this.PoolsDataFetcher}
          PoolRewardsFetcher={this.PoolRewardsFetcher}
          PoolBalanceFetcher={this.PoolBalanceFetcher}
          PoolAPRFetcher={this.PoolAPRFetcher}
          FarmBalanceFetcher={this.FarmBalanceFetcher}
          FarmAprFetcher={this.FarmAprFetcher}
          FarmRewardsFetcher={this.FarmRewardsFetcher}
          LockerBalanceFetcher={this.LockerBalanceFetcher}
          LockerRewardFetcher={this.LockerRewardFetcher}
          roiOfPools={this.state.roiOfPools}
          roiOfFarms={this.state.roiOfFarms}
          cardTitle={this.state.cardTitle}
          showActive={this.state.showActive}
          lockerList={this.state.lockersList}
          lockerRoi={this.state.lockerRoi}
          lockerApr={this.state.lockerApr}
          lockerLiquidity={this.state.lockerLiquidity}
        />
      );
    }
    let routes = (
      <Router>
        <Header
          HomePageDataFetcher={this.HomePageDataFetcher}
          PoolsDataFetcher={this.PoolsDataFetcher}
          FarmDataFetcher={this.FarmDataFetcher}
          LockerDataFetcher={this.LockerDataFetcher}
          DataFetcher={this.DataFetcher}
          OfflineDataLoader={this.OfflineDataLoader}
          utils={utils}
        />
        {customModal}
        <Switch>
          <Route
            exact
            path="/"
            render={() => <Home logoIcon={logoIcon} {...this.state} />}
          />
          <Route
            exact
            path="/farms"
            render={() => (
              <PlentyFarm
                list={this.state.farmList}
                loading={this.state.loading}
                DataFetcher={this.DataFetcher}
                FarmDataFetcher={this.FarmDataFetcher}
                FarmBalanceFetcher={this.FarmBalanceFetcher}
                FarmAprFetcher={this.FarmAprFetcher}
                FarmRewardsFetcher={this.FarmRewardsFetcher}
                handleThrottle={this.handleThrottle}
                setOpenModal={this.setOpenModal}
                setmodalType={this.setmodalType}
                setoperationId={this.setoperationId}
                roiOfFarms={this.state.roiOfFarms}
                setTitle={this.setTitle}
                showActive={this.state.showActive}
                setShow={this.setShow}
              />
            )}
          />
          <Route
            exact
            path="/pools"
            component={() => (
              <Pools
                list={this.state.list}
                loading={this.state.loading}
                DataFetcher={this.DataFetcher}
                PoolsDataFetcher={this.PoolsDataFetcher}
                PoolRewardsFetcher={this.PoolRewardsFetcher}
                PoolBalanceFetcher={this.PoolBalanceFetcher}
                PoolAPRFetcher={this.PoolAPRFetcher}
                handleThrottle={this.handleThrottle}
                setOpenModal={this.setOpenModal}
                setmodalType={this.setmodalType}
                setoperationId={this.setoperationId}
                roiOfPools={this.state.roiOfPools}
                setTitle={this.setTitle}
                setShow={this.setShow}
                {...this.state}
              />
            )}
          />
          <Route
            exact
            path="/ponds"
            component={() => (
              <Lockers
                lockerList={this.state.lockersList}
                loading={this.state.loading}
                DataFetcher={this.DataFetcher}
                handleThrottle={this.handleThrottle}
                setOpenModal={this.setOpenModal}
                setmodalType={this.setmodalType}
                LockerBalanceFetcher={this.LockerBalanceFetcher}
                LockerRewardFetcher={this.LockerRewardFetcher}
                setoperationId={this.setoperationId}
                setTitle={this.setTitle}
                setShow={this.setShow}
                LockerDataFetcher={this.LockerDataFetcher}
                {...this.state}
              />
            )}
          />
          <Route component={PageNotFound} />
        </Switch>
        <Footer />
      </Router>
    );
    if (!this.state.newUser) {
      routes = <NewUser />;
    }
    return <Fragment>{routes}</Fragment>;
  }
}
