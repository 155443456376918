import React from 'react';

import Layout from '../../components/layout/layout';

import notFoundImg from '../../assets/image/404.png';

const PageNotFound = () => {
  return (
    <Layout>
      <div className="page-not-found-page home-section flex justify-center">
        <div className="page-not-found-img-wrapper">
          <img src={notFoundImg} alt="Not Found" />
        </div>
        <h1 className="page-not-found-heading">404|Page not found</h1>
        <div className="back-home-btn-wrapper">
          <a href="/" className="back-home-btn">
            <span className="material-icons-round back-btn-icon">
              keyboard_backspace
            </span>
            Back to Home
          </a>
        </div>
      </div>
    </Layout>
  );
};

export default PageNotFound;
