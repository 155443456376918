import { TezosToolkit } from '@taquito/taquito';
import axios from 'axios';
import { BURNER } from './configV2';
import { getFarmReward, getFarmRewardsArray } from './farm';
import { poolsRewards } from './pools';
import { getTVLForHomePage, getTVLOfUserInAllLockers } from './lockers';
const CONFIG = require('./configV2');

export const getTotalBurned = async () => {
  try {
    //const connectedNetwork = CONFIG.NETWORK;
    const burner = CONFIG.BURNER;
    let response = await axios.get(
      `https://api.tzkt.io/v1/accounts/${burner}/operations?entrypoint=burn`
    );

    let totalBurned = 0;
    for (let i in response.data) {
      if (!(response.data[i].parameter === undefined)) {
        let burnValue = parseInt(response.data[i].parameter.value.value);
        burnValue = burnValue / Math.pow(10, 18);
        totalBurned += burnValue;
      }
    }
    totalBurned = parseInt(totalBurned.PrecisionMaker(0));

    return {
      success: true,
      totalBurned,
    };
  } catch (error) {
    return {
      success: false,
      error,
      totalBurned: 0,
    };
  }
};

export const plentyToHarvest = async (addressOfUser) => {
  try {
    //const plentyToHarvest = await poolsRewards(CONFIG.WALLET_NETWORK, addressOfUser);
    const harvestResponseActive = await poolsRewards(
      CONFIG.WALLET_NETWORK,
      addressOfUser,
      true
    );
    const harvestResponseInactive = await poolsRewards(
      CONFIG.WALLET_NETWORK,
      addressOfUser,
      false
    );
    const plentyToHarvestInFarmActive = await getFarmRewardsArray(
      addressOfUser,
      true
    );
    const plentyToHarvestInFarmInactive = await getFarmRewardsArray(
      addressOfUser,
      false
    );
    let value = 0;
    // value += plentyToHarvestInFarmActive.rewards;
    // value += plentyToHarvestInFarmInactive.rewards;

    for (let i in plentyToHarvestInFarmActive.response) {
      value += plentyToHarvestInFarmActive.response[i].rewards;
    }
    for (let i in plentyToHarvestInFarmInactive.response) {
      value += plentyToHarvestInFarmInactive.response[i].rewards;
    }
    for (let i in harvestResponseActive.response) {
      value += parseFloat(harvestResponseActive.response[i].reward);
    }
    for (let i in harvestResponseInactive.response) {
      value += parseFloat(harvestResponseInactive.response[i].reward);
    }
    return {
      success: true,
      plentyToHarvest: value,
    };
  } catch (e) {
    return {
      success: false,
      error: e,
      plentyToHarvest: 0,
    };
  }
};

export const plentyInWallet = async (addressOfUser) => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    const plentyContractAddress =
      CONFIG.CONTRACT[connectedNetwork].POOLS['PLENTY'].TOKEN;
    const contract = await Tezos.contract.at(plentyContractAddress);
    const storage = await contract.storage();
    const userDetails = await storage.balances.get(addressOfUser);
    let userBalance = userDetails.balance;
    userBalance =
      userBalance.toNumber() /
      Math.pow(
        10,
        CONFIG.CONTRACT[connectedNetwork].POOLS['PLENTY'].TOKEN_DECIMAL
      ).PrecisionMaker(3);
    userBalance = parseFloat(userBalance);
    return {
      success: true,
      plentyInWallet: userBalance,
    };
  } catch (error) {
    return {
      success: false,
      error,
      plentyInWallet: 0,
    };
  }
};

const getTokenPrices = async () => {
  try {
    const tokenPriceResponse = await axios.get(
      'https://api.teztools.io/token/prices'
    );
    return {
      success: true,
      tokenPriceResponse,
    };
  } catch (error) {
    return {
      success: false,
      tokenPriceResponse: {},
    };
  }
};
const getTotalSupplyFromStakingContract = async (
  decimal,
  contractAddress,
  status,
  type,
  symbol,
  Tezos
) => {
  try {
    const tokenContractInstance = await Tezos.contract.at(contractAddress);
    const tokenContractStorage = await tokenContractInstance.storage();

    let totalSupply = await tokenContractStorage.totalSupply;
    totalSupply = (
      totalSupply.toNumber() / Math.pow(10, decimal)
    ).PrecisionMaker(3);
    totalSupply = parseFloat(totalSupply);
    return {
      success: true,
      totalSupply,
      type,
      symbol,
      status,
    };
  } catch (error) {
    return {
      success: false,
      totalSupply: 0,
      type,
      symbol,
      status,
    };
  }
};
const getValuesLocked = async () => {
  try {
    const connectedNetwork = CONFIG.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);

    let promises = [];
    for (let poolIdentifier in CONFIG.CONTRACT[connectedNetwork].POOLS) {
      promises.push(
        getTotalSupplyFromStakingContract(
          CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier].DECIMAL,
          CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier].CONTRACT,
          'active',
          'POOL',
          poolIdentifier,
          Tezos
        )
      );

      promises.push(
        getTotalSupplyFromStakingContract(
          CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier].DECIMAL,
          CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier].INACTIVE,
          'inactive',
          'POOL',
          poolIdentifier,
          Tezos
        )
      );
    }
    for (let farmIdentifier in CONFIG.CONTRACT[connectedNetwork].FARMS) {
      promises.push(
        getTotalSupplyFromStakingContract(
          CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].DECIMAL,
          CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].CONTRACT,
          'active',
          'FARM',
          farmIdentifier,
          Tezos
        )
      );
      promises.push(
        getTotalSupplyFromStakingContract(
          CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].DECIMAL,
          CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].INACTIVE,
          'inactive',
          'FARM',
          farmIdentifier,
          Tezos
        )
      );
    }
    for (let lockerIdentifier in CONFIG.CONTRACT[connectedNetwork].LOCKERS) {
      promises.push(
        getTotalSupplyFromStakingContract(
          CONFIG.CONTRACT[connectedNetwork].LOCKERS[lockerIdentifier]
            .REWARD_TOKEN_DECIMAL,
          CONFIG.CONTRACT[connectedNetwork].LOCKERS[lockerIdentifier]
            .STAKING_CONTRACT,
          'active-locker',
          'LOCKER',
          CONFIG.CONTRACT[connectedNetwork].LOCKERS[lockerIdentifier]
            .STAKE_TOKEN,
          Tezos
        )
      );
    }
    const totalSupplyResponses = await Promise.all(promises);

    return {
      success: true,
      totalSupplyResponses,
    };
  } catch (error) {
    return {
      success: false,
      totalSupplyResponses: {},
    };
  }
};

const getXTZPriceInUSD = async () => {
  try {
    const priceResponse = await axios.get(
      'https://api.coingecko.com/api/v3/coins/tezos?localization=false&tickers=false&community_data=false&developer_data=false&sparkline=false'
    );
    const priceOfXtzInUsd = priceResponse.data.market_data.current_price.usd;

    return {
      success: true,
      priceOfXtzInUsd,
    };
  } catch (error) {
    return {
      success: false,
      priceOfXtzInUsd: 0,
      error,
    };
  }
};

const fetchLPPriceInXtz = async (identifier, dexAddress, Tezos) => {
  try {
    const dexContract = await Tezos.contract.at(dexAddress);
    const dexStorage = await dexContract.storage();

    let totalSupply = await dexStorage.storage.total_supply;
    totalSupply = totalSupply.toNumber().PrecisionMaker(6);
    totalSupply = parseFloat(totalSupply);

    let tezPool = await dexStorage.storage.tez_pool;
    tezPool = parseFloat(tezPool.toNumber());
    let tokenPool = await dexStorage.storage.token_pool;
    tokenPool = tokenPool.toNumber();

    let LPTokenPrice = (tezPool * 2) / totalSupply;
    LPTokenPrice = LPTokenPrice.PrecisionMaker(6);
    LPTokenPrice = parseFloat(LPTokenPrice);

    return {
      success: true,
      identifier,
      LPTokenPrice,
    };
  } catch (e) {
    return {
      success: false,
      identifier,
      LPTokenPrice: 0,
      error: e,
    };
  }
};

const fetchLPTokenPrices = async () => {
  try {
    let promises = [];

    const connectedNetwork = CONFIG.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setProvider(CONFIG.RPC_NODES[connectedNetwork]);

    for (let farmIdentifier in CONFIG.CONTRACT[connectedNetwork].FARMS) {
      promises.push(
        fetchLPPriceInXtz(
          farmIdentifier,
          CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].DEX,
          Tezos
        )
      );
    }
    let response = await Promise.all(promises);
    return {
      success: true,
      response,
    };
  } catch (error) {
    return {
      success: false,
      response: {},
      error,
    };
  }
};

const fetchPoolBalanceOfUser = async (
  addressOfUser,
  poolContractAddress,
  identifier,
  decimal,
  Tezos
) => {
  try {
    const contract = await Tezos.contract.at(poolContractAddress);
    const storage = await contract.storage();
    const userDetails = await storage.balances.get(addressOfUser);
    let userBalance = userDetails.balance;
    userBalance = (
      userBalance.toNumber() / Math.pow(10, decimal)
    ).PrecisionMaker(3);
    userBalance = parseFloat(userBalance);
    return {
      success: true,
      balance: userBalance,
      identifier,
    };
  } catch (e) {
    return {
      success: false,
      balance: 0,
      identifier,
    };
  }
};

const fetchFarmBalanceOfUser = async (
  addressOfUser,
  tokenContractAddress,
  identifier,
  decimal,
  Tezos
) => {
  try {
    const contract = await Tezos.contract.at(tokenContractAddress);
    const storage = await contract.storage();
    const userDetails = await storage.balances.get(addressOfUser);
    let userBalance = userDetails.balance;
    userBalance = (
      userBalance.toNumber() / Math.pow(10, decimal)
    ).PrecisionMaker(5);
    userBalance = parseFloat(userBalance);
    return {
      success: true,
      balance: userBalance,
      identifier,
    };
  } catch (error) {
    return {
      success: false,
      balance: 0,
      identifier,
    };
  }
};

const fetchLockerBalanceOfUser = async (
  addressOfUser,
  tokenContractAddress,
  identifier,
  decimal,
  Tezos
) => {
  try {
    const contract = await Tezos.contract.at(tokenContractAddress);
    const storage = await contract.storage();
    const userDetails = await storage.balances.get(addressOfUser);
    let userBalance = userDetails.balance;
    userBalance = (
      userBalance.toNumber() / Math.pow(10, decimal)
    ).PrecisionMaker(3);
    userBalance = parseFloat(userBalance);
    return {
      success: true,
      balance: userBalance,
      identifier,
    };
  } catch (e) {
    return {
      success: false,
      balance: 0,
      identifier,
    };
  }
};

const fetchBalancesOfUser = async (addressOfUser) => {
  try {
    let promises = [];

    const connectedNetwork = CONFIG.NETWORK;
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setProvider(CONFIG.RPC_NODES[connectedNetwork]);

    for (let poolIdentifier in CONFIG.CONTRACT[connectedNetwork].POOLS) {
      promises.push(
        fetchPoolBalanceOfUser(
          addressOfUser,
          CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier].CONTRACT,
          poolIdentifier,
          CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier].DECIMAL,
          Tezos
        )
      );

      promises.push(
        fetchPoolBalanceOfUser(
          addressOfUser,
          CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier].INACTIVE,
          poolIdentifier,
          CONFIG.CONTRACT[connectedNetwork].POOLS[poolIdentifier].DECIMAL,

          Tezos
        )
      );
    }

    for (let farmIdentifier in CONFIG.CONTRACT[connectedNetwork].FARMS) {
      promises.push(
        fetchFarmBalanceOfUser(
          addressOfUser,
          CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].CONTRACT,
          farmIdentifier,
          CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].DECIMAL,
          Tezos
        )
      );

      promises.push(
        fetchFarmBalanceOfUser(
          addressOfUser,
          CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].INACTIVE,
          farmIdentifier,
          CONFIG.CONTRACT[connectedNetwork].FARMS[farmIdentifier].DECIMAL,
          Tezos
        )
      );
    }

    for (let lockerIndentifier in CONFIG.CONTRACT[connectedNetwork].LOCKERS) {
      promises.push(
        fetchLockerBalanceOfUser(
          addressOfUser,
          CONFIG.CONTRACT[connectedNetwork].LOCKERS[lockerIndentifier]
            .STAKING_CONTRACT,
          CONFIG.CONTRACT[connectedNetwork].LOCKERS[lockerIndentifier]
            .STAKE_TOKEN,
          CONFIG.CONTRACT[connectedNetwork].LOCKERS[lockerIndentifier]
            .REWARD_TOKEN_DECIMAL,
          Tezos
        )
      );
    }

    const response = await Promise.all(promises);
    return {
      success: true,
      response,
    };
  } catch (error) {
    return {
      success: false,
      error,
      response: {},
    };
  }
};

export const getTVLAndTVLOfUser = async (addressOfUser) => {
  try {
    let promises = [];
    const tokens = [
      'PLENTY',
      'USDtz',
      'ETHtz',
      'hDAO',
      'wMATIC',
      'wLINK',
      'KALAM',
      'WRAP',
      'PLENTY - XTZ',
      'KALAM - XTZ',
    ];
    const tokenAddress = {
      PLENTY:{
        contractAddress : 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b',
      },
      USDtz:{
        contractAddress : 'KT1LN4LPSqTMS7Sd2CJw4bbDGRkMv2t68Fy9',
      },
      ETHtz:{
        contractAddress : 'KT19at7rQUvyjxnZ2fBv7D9zc8rkyG7gAoU8',
      },
      hDAO:{
        contractAddress : 'KT1AFA2mwNUMNd4SsujE1YYp29vd8BZejyKW',
      },
      wMATIC:{
        contractAddress : 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
      },
      wLINK:{
        contractAddress : 'KT18fp5rcTW7mbWDmzFwjLDUhs5MeJmagDSZ',
      },
      KALAM:{
        contractAddress : 'KT1A5P4ejnLix13jtadsfV9GCnXLMNnab8UT',
      },
      WRAP:{
        contractAddress : 'KT1LRboPna9yQY9BrjtQYDS1DVxhKESK4VVd',
      },
    };
    let tokenPrice = {};
    let tvl = 0;
    let tvlOfContract = 0;
    let tvlOfUser = 0;

    const tvlResponses = await getValuesLocked();
    const tvls = tvlResponses.totalSupplyResponses;
    
    promises.push(getTokenPrices());
    promises.push(getXTZPriceInUSD());
    promises.push(fetchLPTokenPrices());

    const response = await Promise.all(promises);
    const tokenPriceResponse = response[0].tokenPriceResponse.data;
    const price = response[1].priceOfXtzInUsd;
    const lpPrice = response[2].response;

    for (let i in tokenPriceResponse.contracts) {
      if (tokens.includes(tokenPriceResponse.contracts[i].symbol)) {
        if(tokenAddress[tokenPriceResponse.contracts[i].symbol].contractAddress === tokenPriceResponse.contracts[i].tokenAddress)
        {
          tokenPrice[tokenPriceResponse.contracts[i].symbol] =
            tokenPriceResponse.contracts[i].currentPrice;
        }

      }
    }
    for (let i in lpPrice) {
      if (tokens.includes(lpPrice[i].identifier)) {
        tokenPrice[lpPrice[i].identifier] = lpPrice[i].LPTokenPrice;
      }
    }
    for (let i in tvls) {
      if (tokens.includes(tvls[i].symbol)) {
        tvlOfContract =
          tokenPrice[tvls[i].symbol] * tvls[i].totalSupply * price;
        tvl = tvlOfContract + tvl;
      }
    }

    if (addressOfUser !== null) {
      let userStakes = await fetchBalancesOfUser(addressOfUser);
      userStakes = userStakes.response;

      for (let i in userStakes) {
        tvlOfUser +=
          tokenPrice[userStakes[i].identifier] * price * userStakes[i].balance;
      }
    }

    return {
      success: true,
      tvl,
      tvlOfUser,
    };
  } catch (error) {
    return {
      success: false,
      tvl: 0,
      tvlOfUser: 0,
      error,
    };
  }
};
