import { TezosToolkit } from '@taquito/taquito';
import axios from 'axios';
const CONFIG = require('./configV2');

const contractAddress = 'KT1X1LgNkQShpF9nRLYw3Dgdy4qp38MX617z';
const connectedNetwork = CONFIG.NETWORK;
const tokenAddress = 'KT1GRSvLoikDsXujKgZPsGLX8k8VvR2Tq95b';

const getPriceInUSD = async () => {
  try {
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);
    const dexInstance = await Tezos.contract.at(contractAddress);
    const dexStorage = await dexInstance.storage();
    let tezPool = await dexStorage.storage.tez_pool.toNumber();
    let tokenPool = await dexStorage.storage.token_pool.toNumber();
    let tokenPriceInXtz = Math.pow(10, 18) * tezPool * 1000;
    tokenPriceInXtz = tokenPriceInXtz / (tokenPool - Math.pow(10, 18));
    tokenPriceInXtz = tokenPriceInXtz / 997;
    tokenPriceInXtz = tokenPriceInXtz / Math.pow(10, 6);
    let priceFeed = await axios.get(CONFIG.API.url);
    priceFeed = priceFeed.data.market_data.current_price.usd;
    let tokenPriceInUSD = tokenPriceInXtz * priceFeed;
    return {
      success: true,
      tokenPriceInUSD,
    };
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
};

const _getCirculatingSupply = async () => {
  try {
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);

    const tokenInstance = await Tezos.contract.at(tokenAddress);
    const tokenStorage = await tokenInstance.storage();

    let totalSupplyOfToken = await tokenStorage.totalSupply.toNumber();
    totalSupplyOfToken = (totalSupplyOfToken / Math.pow(10, 18)).PrecisionMaker(
      5
    );
    totalSupplyOfToken = parseFloat(totalSupplyOfToken);

    let adminDetails = await tokenStorage.balances.get(CONFIG.ADMIN_ADDRESS);

    let adminBalance = adminDetails.balance.toNumber();
    adminBalance = (adminBalance / Math.pow(10, 18)).PrecisionMaker(5);
    adminBalance = parseFloat(adminBalance);

    let currentSupplyOfToken = totalSupplyOfToken - adminBalance;

    let circulatingSupply = currentSupplyOfToken;
    circulatingSupply = circulatingSupply.PrecisionMaker(0);

    return {
      success: true,
      circulatingSupply,
    };
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
};

export const getPlentyPriceInUSD = async () => {
  try {
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);

    const plentyPriceInUSD = await getPriceInUSD();

    return {
      success: true,
      plentyPriceInUSD: plentyPriceInUSD.tokenPriceInUSD,
    };
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
};

export const getMarketCap = async () => {
  try {
    let plentyPriceInUSD = await getPriceInUSD();
    plentyPriceInUSD = plentyPriceInUSD.tokenPriceInUSD;

    let currentSupplyOfToken = await _getCirculatingSupply();

    currentSupplyOfToken = currentSupplyOfToken.circulatingSupply;

    let marketCap = currentSupplyOfToken * plentyPriceInUSD;
    marketCap = marketCap.PrecisionMaker(0);

    return {
      success: true,
      marketCap,
    };
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
};

export const getCirculatingSupply = async () => {
  try {
    let circulatingSupply = await _getCirculatingSupply();

    circulatingSupply = circulatingSupply.circulatingSupply;

    return {
      success: true,
      circulatingSupply,
    };
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
};

export const getTokensPerBlock = async () => {
  try {
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);

    const tokenInstance = await Tezos.contract.at(tokenAddress);
    const tokenStorage = await tokenInstance.storage();

    let tokensPerBlock = await tokenStorage.tokensPerBlock.toNumber();
    tokensPerBlock = tokensPerBlock / Math.pow(10, 18);

    return {
      success: true,
      tokensPerBlock,
    };
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
};

export const getTotalMinted = async () => {
  try {
    const Tezos = new TezosToolkit(CONFIG.RPC_NODES[connectedNetwork]);
    Tezos.setRpcProvider(CONFIG.RPC_NODES[connectedNetwork]);

    const tokenInstance = await Tezos.contract.at(tokenAddress);
    const tokenStorage = await tokenInstance.storage();

    let totalSupply = await tokenStorage.totalSupply.toNumber();
    totalSupply = totalSupply / Math.pow(10, 18);
    return {
      success: true,
      totalSupply,
    };
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
};
